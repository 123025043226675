@import './core';

html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100vh;

  font-family: $baseFont;
  font-size: $baseSize;

  scroll-behavior: smooth;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow-x: hidden;
}

.big-title {
  position: relative;
  color: colors(4);
  @extend .txt-f08;
  text-transform: capitalize;
  margin-top: 0;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;

  &::before {
    content: '';
    position: absolute;
    width: 4rem;
    height: 4px;
    background: colors(1);
    bottom: 0;
    left: 0;
  }

  &--center {
    text-align: center;

    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--right {
    text-align: right;

    &::before {
      right: 0;
      left: unset;
    }
  }

  span {
    display: block;
    @extend .txt-f16;
  }

  @include media-breakpoint-down(xs) {
    font-size: rem(25);
  }
}

.link-btn {
  display: inline-block;
  width: fit-content;
  padding: 0.3rem 2rem;
  border: 1px solid colors(1);
  color: colors(1);
  @extend .txt-f03;
  background: transparent;
  text-transform: capitalize;
  text-align: center;
  border-radius: 30px;
  text-decoration: none;
  @include transition(0.5s background);

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: colors(6);
    border-color: colors(1);
    background: colors(1);
  }

  &--white {
    color: colors(6);
    border-color: colors(6);
  }

  &--radius {
    border-radius: 30px !important;
  }
}

.cms-btn {
  display: inline-block;
  position: relative;
  width: fit-content;
  padding: 0.5rem 1.5rem;
  padding-left: 3rem;
  color: colors(1);
  @extend .txt-f01;
  background: transparent;
  text-transform: uppercase;
  letter-spacing: $l01;
  border-radius: 30px;
  text-decoration: none;
  @include transition(0.5s background);
  vertical-align: text-bottom;

  &::before {
    content: $fa03;
    @extend %far;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 30px;
    height: 30px;
    color: colors(6);
    background: colors(1);
    text-align: center;
    border-radius: 30px;
    line-height: 30px;
    @include transition(0.5s transform);
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: colors(6);
    border-color: colors(1);
    background: colors(1);

    &::before {
      transform: translate(0.5rem, -50%);
    }
  }

  &--back {
    &::before {
      content: $fa02;
    }
  }
}

#header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: $zindex-fixed;
  background: colors(6);
  box-shadow: 1px 1px 15px 0px rgba(colors(4), 0.2);

  .header {
    &-bar {
      border-top: $sectionBorderTop solid colors(1);
    }

    &-search {
      &-btn {
        padding: 0.5rem 0.6rem;
        color: colors(3);
      }
    }

    &-mobile {
      &-btn {
        display: block;
        color: colors(3);
      }
    }
  }
}

#logo {
  a {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 0;
    min-height: $headerHeight;

    img {
      display: block;
      margin: 0 auto;
      max-height: 50px;
    }
  }
}

#mobile-menu {
  z-index: $zindex-tooltip + 50;

  &:not(.mm) {
    display: none;
  }

  a {
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: colors(4);
    }
  }
}

#cms-menu {
  text-align: center;
  @include transition(0.25s all);

  .cms-menu-sub {
    position: relative;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
      position: relative;
      margin: 0 0.5rem;
      line-height: 2;
      padding: 0.5rem 0;
      @include transition(0.25s all);

      &.active {
        > a {
          color: colors(4);
        }
      }

      &:hover {
        > a {
          color: colors(4);
        }

        > .cms-menu-sub {
          opacity: 1;
          visibility: visible;
          transform: translate(-50%, 0);
          @include transition(0.25s all);
        }
      }

      a {
        color: colors(3);
        @extend .txt-f01;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .cms-menu-sub {
        position: absolute;
        top: 100%;
        left: 50%;
        z-index: $zindex-popover;
        visibility: hidden;
        opacity: 0;
        transform: translate(-50%, 0.5rem);
        @include transition(0.25s all);

        &.sub {
          top: 0;
          left: 150%;
          margin-left: 2px;
        }
      }
    }

    ul {
      display: block;
      min-width: 200px;
      background: colors(1);
      padding: 1rem 0;
      // max-height: #{$cmsMenuEachSubMenuHeight * 10};
      // overflow-x: hidden;
      // overflow-y: auto;
      text-align: left;

      li {
        width: 100%;
        margin: 0;
        padding: 0;
        line-height: 1.5;
        display: block;
        position: unset;

        &:hover {
          > a {
            color: colors(4);
            text-decoration: none;
            transform: translateX(0.5rem);
          }
        }

        a {
          display: block;
          position: relative;
          color: colors(6);
          padding: 0.3rem 1.5rem;
          text-transform: capitalize;
          @include transition(0.25s transform);
        }
      }
    }
  }
}

#search-modal {
  .modal {
    &-dialog {
      margin: 0px;
      width: 100%;
      max-width: unset;
    }

    &-content {
      border: 0px;
      border-radius: 0px;
    }
  }
}

#search {
  height: $headerHeight + $sectionBorderTop;

  .form-group {
    input[type='text'] {
      padding-left: 0;
      border: 0;
      border-radius: 0;
      // @extend .text-f08;
      color: colors(4);
      text-transform: unset;
      background: transparent;

      &::placeholder {
        color: colors(3);
        font-family: inherit;
        font-size: inherit;
        text-transform: uppercase;
      }
    }

    button[type='submit'],
    .search-btn {
      color: colors(3);
      border-radius: 0;
    }
  }
}

#content {
  width: 100%;
  min-height: calc(100vh - #{$headerHeight + $sectionBorderTop + $footerHeight} + 1px);
  padding-bottom: 1px;
  margin-top: $headerHeight + $sectionBorderTop;
}

#banner {
  position: relative;
  background: colors(6);

  .banner {
    &-slide {
      display: flex;
      align-items: center;

      height: calc(50vh + #{$headerHeight + $sectionBorderTop});
      min-height: 500px;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include media-breakpoint-down(md) {
        min-height: 400px;
      }

      @include media-breakpoint-down(xs) {
        min-height: 300px;
      }
    }

    &-content {
      padding-right: 3rem;
      max-width: 40%;

      p {
        color: colors(6);
        @extend .txt-f01;
        text-transform: uppercase;
        letter-spacing: $l01;
        margin-bottom: 1rem;
        text-shadow: -1px 1px 5px rgba(colors(4), $o04);
      }

      h3 {
        color: colors(6);
        @extend .txt-f02;
        margin-top: 0px;
        margin-bottom: 0px;
        line-height: 1.2;
        text-shadow: -1px 1px 5px rgba(colors(4), $o04);
      }

      a,
      .link-btn {
        margin-top: 2rem;
        text-shadow: -1px 1px 5px rgba(colors(4), $o04);
        box-shadow: -1px 1px 5px rgba(colors(4), $o04);
      }

      @include media-breakpoint-down(lg) {
        max-width: 60%;
      }

      @include media-breakpoint-down(md) {
        max-width: 80%;
      }

      @include media-breakpoint-down(sm) {
        max-width: 90%;

        h3 {
          font-size: rem(25);
        }
      }

      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
    }

    &-nav {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;

      .swiper-button-prev,
      .swiper-button-next {
        right: 1rem;
        left: auto;
        background-image: none;
        color: colors(6);
        height: 35px;
        width: 35px;
        border-radius: 100%;
        border: 1px solid colors(6);
        text-align: center;
        margin-top: 0px;
        transform: translateY(-50%);
        line-height: 33px;

        &:hover {
          background: colors(1);
          border-color: colors(1);
        }
      }

      .swiper-button-next {
        top: calc(50% - 25px);
      }

      .swiper-button-prev {
        top: calc(50% + 25px);
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }
}

#inner-banner {
  position: relative;

  .inner-banner {
    &-wrapper {
      display: flex;
      align-items: center;

      height: calc(20vh + #{$headerHeight + $sectionBorderTop});
      min-height: 300px;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      box-shadow: inset 0px 0px 0px 1000px rgba(colors(4), $o04);

      @include media-breakpoint-down(md) {
        min-height: 200px;
      }
    }

    &-content {
      max-width: 50%;
      margin: 0 auto;
      text-align: center;

      p {
        color: colors(6);
        @extend .txt-f02;
        text-transform: capitalize;
        margin-bottom: 0px;
      }
    }
  }
}

#breadcrumb {
  text-align: center;

  .breadcrumb {
    margin: 0;
    background: none;
    padding: 1.5rem 0;
    color: colors(3);
    @extend .txt-f12;
    font-size: rem(14);
    text-transform: capitalize;
    letter-spacing: $l01;
    border-radius: 0;
    align-items: center;
    justify-content: center;

    > li {
      position: relative;
      padding: 0.5rem 0;
      line-height: 1;

      + li {
        padding-left: 2.1rem;

        &:before {
          position: absolute;
          left: 0.6rem;
          top: 50%;
          transform: translateY(-50%);
          @extend %fas;
          content: $fa40;
          color: inherit;
          font-size: rem(10);
          margin: 0;
          padding: 0;
          margin-top: -3px;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      padding: 1rem 0;
    }
  }

  span {
    display: none;
  }

  a {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
      color: colors(4);
      text-decoration: none;
    }
  }
}

#home-cms {
  background: colors(6);
  margin-bottom: $mapContainerHeight;

  .home-cms {
    &-1 {
      > .container {
        border-right: 1px solid colors(5);
      }

      &-left {
        padding: 4rem 0;

        h5 {
          @extend .txt-f03;
          color: colors(3);
          letter-spacing: $l01;
          margin-top: 0px;
          margin-bottom: 0px;
          text-transform: uppercase;
        }

        @include media-breakpoint-down(xs) {
          padding: 2rem 0;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;

        h5 {
          display: inline-block;
          @extend .txt-f03;
          color: colors(3);
          width: 42%;
          padding-right: 1rem;
          margin-left: auto;
          margin-bottom: 0px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 30px;
          height: 30px;
          color: colors(6);
          background: colors(1);
          text-align: center;
          border-radius: 30px;
          margin-right: -2rem;
          text-decoration: none;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &-title {
        position: relative;
        @extend .txt-f05;
        color: colors(3);

        h1 {
          @extend .txt-f05;
          color: colors(3);
          margin-bottom: 0;
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          min-height: 90px;

          li {
            @extend .txt-f04;
            color: colors(1);
            padding: 0.5rem 0;
            line-height: 1;

            position: absolute;
            opacity: 0;
            overflow: hidden;
            animation: topToBottom 12.5s linear infinite 0s;

            &:nth-child(2) {
              animation-delay: 2.5s;
            }

            &:nth-child(3) {
              animation-delay: 5s;
            }

            &:nth-child(4) {
              animation-delay: 7.5s;
            }

            &:nth-child(5) {
              animation-delay: 10s;
            }

            &:nth-child(n + 6) {
              display: none;
            }
          }
        }

        @include media-breakpoint-down(xs) {
          h1 {
            font-size: rem(50);
          }

          ul {
            min-height: 60px;

            li {
              font-size: rem(50);
            }
          }
        }

        @media (max-width: 480px) {
          ul {
            width: calc(100vh - 2rem);
          }
        }
      }

      &-content {
        // direction: rtl;
        text-align: right;
        text-align-last: right;

        width: 60%;
        margin-top: 1rem;
        margin-left: auto;

        p {
          @extend .txt-f06;
          color: colors(3);
          text-align: justify;
          line-height: 1.5;
          margin-bottom: 1rem;
        }

        @include media-breakpoint-down(sm) {
          width: 80%;
        }

        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
    }

    &-2,
    &-4 {
      position: relative;
      min-height: 500px;

      background-position: center right;
      background-size: cover;
      background-repeat: no-repeat;

      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, $o06) 0%, rgba(0, 0, 0, 0) 100%);
      }

      &-content {
        @extend .txt-f07;
        color: colors(6);
        position: relative;
        z-index: 1;
        padding: 2rem 0;

        h2 {
          font-size: inherit;
          margin-bottom: 0.5rem;
        }
      }

      @include media-breakpoint-down(lg) {
        min-height: 400px;
      }

      @include media-breakpoint-down(sm) {
        min-height: 300px;

        &-content {
          font-size: rem(35);
        }
      }

      @include media-breakpoint-down(xs) {
        &-content {
          font-size: rem(30);
        }
      }
    }

    &-3,
    &-5 {
      overflow: hidden;

      &-main {
        min-height: 500px;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @include media-breakpoint-down(sm) {
          min-height: 300px;
        }

        @include media-breakpoint-down(xs) {
          min-height: 250px;
        }
      }

      &-content {
        height: 100%;

        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &-item {
          position: relative;
          z-index: 3;

          width: 50%;
          height: 50%;

          padding: 3rem;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          background-color: colors(6);

          &:nth-child(2),
          &:nth-child(3) {
            z-index: 1;

            &::before {
              content: '';
              position: absolute;
              z-index: 2;

              height: 50px;
              width: 50px;

              background: colors(6);
              border-radius: 100%;
            }

            &::after {
              content: '';
              position: absolute;
              z-index: 2;

              height: 100px;
              width: 100px;
              overflow: hidden;

              background: transparent;
              border-radius: 100%;

              box-shadow: 0px -5px 0px 0px transparent;

              transition: transform 1s, box-shadow 1s ease;
            }
          }

          &:nth-child(2) {
            // left
            &::before {
              left: -25px;
            }

            &::after {
              left: -50px;
              // transform: scale(0.2) rotate(-90deg);
              transform: rotate(-90deg);
            }
          }

          &:nth-child(3) {
            // right
            &::before {
              right: -25px;
            }

            &::after {
              right: -50px;
              // transform: scale(0.2) rotate(90deg);
              transform: rotate(90deg);
            }
          }

          &:hover {
            &::after {
              // transform: scale(1);
              transform: scale(1) rotate(0deg);
              box-shadow: 0px 0px 0px 3px colors(6);
            }
          }

          h4 {
            display: block;
            @extend .txt-f08;
            color: colors(4);
            width: 100%;
            margin-bottom: 1rem;
          }

          img {
            margin: 0 auto;
            height: auto;
            max-width: 100%;
          }
        }

        @include media-breakpoint-down(md) {
          &-item {
            padding: 1.5rem;

            h4 {
              font-size: rem(20);
            }
          }
        }

        @include media-breakpoint-down(sm) {
          &-item {
            min-height: 300px;
          }
        }

        @include media-breakpoint-down(xs) {
          &-item {
            min-height: 250px;
          }
        }
      }
    }

    &-6 {
      padding-top: 5rem;

      &-content {
        padding-bottom: 4rem;

        h1 {
          @extend .txt-f04;
          color: colors(1);
          width: 100%;
          margin-bottom: 1rem;
        }

        p {
          @extend .txt-f09;
          color: colors(3);
          margin-bottom: 1rem;
        }

        a,
        .cms-btn {
          display: block;
          margin-top: 1rem;
        }
      }

      &-map {
        display: block;
        width: 100%;
        height: $mapContainerHeight;
        margin-bottom: -#{$mapContainerHeight};
      }

      @include media-breakpoint-down(md) {
        padding-top: 3rem;

        &-content {
          padding-bottom: 2rem;
        }
      }

      @include media-breakpoint-down(xs) {
        padding-top: 2rem;

        &-content {
          h1 {
            font-size: rem(60);
          }
        }
      }
    }

    &-7 {
      position: relative;
      min-height: 500px;

      background-position: top right;

      display: flex;
      align-items: center;

      &-content {
        @extend .txt-f07;
        color: colors(6);
        position: relative;
        z-index: 1;
        padding: 2rem 0;

        h2 {
          font-size: inherit;
          margin-bottom: 0.5rem;
        }
      }

      @include media-breakpoint-down(lg) {
        min-height: 400px;
      }

      @include media-breakpoint-down(sm) {
        min-height: 300px;

        &-content {
          font-size: rem(35);
        }
      }

      @include media-breakpoint-down(xs) {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to right, rgba(0, 0, 0, $o06) 0%, rgba(0, 0, 0, 0) 100%);
        }

        &-content {
          font-size: rem(30);
        }
      }
    }
  }
}

#home-enquiry {
  position: relative;
  z-index: 1;
  background: colors(6);
  padding: 2rem;
  border-radius: 5px;
  margin-bottom: -#{$mapContainerHeight};
  box-shadow: 0px 0px 15px 0px rgba(colors(4), $o01);

  h4 {
    @extend .txt-f10;
    color: colors(4);
    margin-bottom: 2rem;

    i,
    svg,
    span {
      display: inline-block;
      vertical-align: bottom;
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: -3rem;
  }

  @include media-breakpoint-down(xs) {
    padding: 1rem;
  }

  @media (max-width: 375px) {
    .g-recaptcha {
      transform: scale(0.77);
      transform-origin: 0 0;
    }
  }
}

#map {
  position: fixed;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: $mapContainerHeight + $footerHeight;
  overflow: hidden;
  background: #f2f2f2;

  .map {
    &-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      z-index: 1;
    }

    &-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: -60vw;
    }
  }

  iframe {
    width: 200vw;
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    .map {
      &-wrapper {
        left: -50vw;
      }
    }
  }
}

#inner-page {
  padding-bottom: 4rem;
}

.inner-cms {
}

.pagination {
  &:empty {
    display: none;
  }

  margin-top: 2rem;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  .page {
    &-item {
      .page-link {
        @extend .txt-f03;
        color: colors(3);
        background: colors(6);
        border-color: colors(3);

        &:hover {
          color: colors(6);
          background: colors(1);
          border-color: colors(1);
        }
      }

      &.active {
        .page-link {
          color: colors(6);
          background: colors(4);
          border-color: colors(4);
        }
      }
    }
  }
}

.news-list {
  @include blog-list(news);
}

.news-view {
  @include blog-view(news);
}

.event-list {
  @include blog-list(event);
}

.event-view {
  @include blog-view(event);
}

.photo-list {
  @include blog-list(photo);
}

.photo-view {
  @include gallery-view(photo);
}

.video-list {
  @include blog-list(video);
}

.video-view {
  @include gallery-view(video);
}

#contactform,
#newsletterform {
  margin-top: 2rem;

  i,
  svg {
    display: block;
    position: absolute;
    left: 1.5rem;
    top: 0.75rem;
    color: colors(1);
  }

  .form-control {
    padding: 0.5rem;
    height: auto;
    padding-left: 3rem;
    border-radius: 6px;
    border-color: colors(5);

    @extend .txt-f11;
    color: colors(3);

    &::placeholder {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }

  .custom-control-input:checked {
    ~ .custom-control-label::before {
      border-color: colors(1);
      background-color: colors(1);
    }
  }

  @include media-breakpoint-down(xs) {
    .g-recaptcha {
      > div {
        margin: 0 auto;
      }
    }
  }
}

#g-recaptcha-response {
  > div {
    margin: 0 auto;
  }
}

#search-page {
  color: colors(3);
  @extend .txt-f15;
  line-height: 1.5;

  h3 {
    color: colors(1);
    @extend .txt-f02;
    text-transform: uppercase;
  }

  h4 {
    color: colors(4);
    @extend .txt-f10;

    a {
      display: block;
      text-decoration: none;
      color: inherit;

      &:hover {
        color: colors(1);
      }
    }
  }

  h5 {
    color: colors(4);
    @extend .txt-f10;
    text-transform: uppercase;
  }

  .cms-btn {
    display: block;
    margin-left: auto;
  }
}

#footer {
  margin-top: -1px;
  min-height: $footerHeight;
  border-top: $sectionBorderTop solid colors(1);
  background: colors(4);
  position: relative;
  z-index: 1;

  .footer {
    &-wrapper {
      margin: 0 auto;
      padding: 0.5rem 0;
      max-width: 80%;
      color: colors(6);
      font-size: rem(10);
      letter-spacing: 1px;
      text-align: center;

      a {
        color: inherit;
      }

      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
    }
  }
}

#sitemap {
  overflow: hidden;

  .big-title {
    margin-top: 2rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0.4rem;

      a {
        display: block;
        color: colors(6);
        @extend .txt-f01;
        background: colors(1);
        text-transform: uppercase;
        padding: 0.8rem 2.5rem;
        text-align: center;
        border: 1px solid colors(1);
        text-decoration: none;
        @include transition(0.5s background);

        &:hover {
          text-decoration: none;
          color: colors(1);
          background: transparent;
        }
      }
    }

    ul {
      text-align: left;
      display: block;

      li {
        display: block;
        margin-left: 0;
        margin-right: 0;

        a {
          padding: 0.3rem 0.5rem;
          display: inline-block;
          background: transparent;
          @extend .txt-f03;
          border-color: colors(2);
          color: colors(3);
        }
      }

      ul {
        margin-left: 1rem;
      }
    }
  }
}
