@function rem($pixel-size, $base-size: 16px) {
  @if (unitless($pixel-size)) {
    $pixel-size: $pixel-size * 1px;
  }
  @return $pixel-size / $base-size * 1rem;
}

@function colors($key) {
  @return nth($list: $uno-colors, $n: $key);
}

@mixin uno-font-faces($font-name, $file-path, $font-weight: normal, $font-style: normal) {
  @font-face {
    font-family: $font-name;
    src: url('#{$font-name}.eot');
    src: local('#{$font-name}'), url('#{$file-path}#{$font-name}.eot?#iefix') format('embedded-opentype'),
      url('#{$file-path}#{$font-name}.woff2') format('woff2'), url('#{$file-path}#{$font-name}.woff') format('woff'),
      url('#{$file-path}#{$font-name}.ttf') format('truetype'), url('#{$file-path}#{$font-name}.svg') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@mixin uno-font-styles($font-styles: $uno-fonts) {
  $index: 1;
  #{'.txt'} {
    @each $font-style in $font-styles {
      $font-face: nth($font-style, 1);
      $font-size: rem(nth($font-style, 2));
      &#{if($index < 10, -f0#{$index}, -f#{$index})} {
        font-family: $font-face;
        font-size: $font-size;
        &-lg {
          @include media-breakpoint-down(lg) {
            font-family: $font-face;
            font-size: $font-size * 0.875;
          }
        }
        &-md {
          @include media-breakpoint-down(md) {
            font-family: $font-face;
            font-size: $font-size * 0.75;
          }
        }
        &-sm {
          @include media-breakpoint-down(sm) {
            font-family: $font-face;
            font-size: $font-size * 0.625;
          }
        }
      }
      $index: $index + 1;
    }
  }
}

@mixin uno-font-colors($colors: $uno-colors) {
  $index: 1;
  #{'.txt'} {
    @each $color in $colors {
      &#{if($index < 10, -c0#{$index}, -c#{$index})} {
        color: $color;
      }
      &-hover#{if($index < 10, -c0#{$index}, -c#{$index})} {
        &:hover {
          color: $color;
        }
      }
      $index: $index + 1;
    }
  }
}

@mixin uno-font-sizes($min: 1, $max: 100) {
  #{'.txt'} {
    @for $i from $min through $max {
      &-size-#{$i} {
        font-size: rem($i);
      }
    }
    @for $i from $min through $max {
      &-size-#{$i} {
        &-lg {
          @include media-breakpoint-down(lg) {
            font-size: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-size-#{$i} {
        &-md {
          @include media-breakpoint-down(md) {
            font-size: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-size-#{$i} {
        &-sm {
          @include media-breakpoint-down(sm) {
            font-size: rem($i);
          }
        }
      }
    }
  }
}

@mixin uno-background-colors($colors: $uno-colors) {
  $index: 1;
  #{'.bg'} {
    @each $color in $colors {
      &#{if($index < 10, -c0#{$index}, -c#{$index})} {
        background: $color;
      }
      &-hover#{if($index < 10, -c0#{$index}, -c#{$index})} {
        &:hover {
          background: $color;
        }
      }
      $index: $index + 1;
    }
  }
}

@mixin uno-border-colors($colors: $uno-colors) {
  $index: 1;
  #{'.bc'} {
    @each $color in $colors {
      &#{if($index < 10, -c0#{$index}, -c#{$index})} {
        border-color: $color;
      }
      &-hover#{if($index < 10, -c0#{$index}, -c#{$index})} {
        &:hover {
          border-color: $color;
        }
      }
      $index: $index + 1;
    }
  }
}

@mixin uno-line-height($min: 1, $max: 100) {
  $temp: 0;
  #{'.lh'} {
    @for $i from $min through $max {
      $temp: $temp + 0.1;
      &-#{$i} {
        line-height: $temp;
      }
    }
  }
}

@mixin uno-letter-spacing($min: 1, $max: 100) {
  #{'.ls'} {
    @for $i from $min through $max {
      &-#{$i} {
        letter-spacing: rem($i);
      }
    }
  }
}

@mixin uno-margin-padding($min: 1, $max: 100) {
  #{'.margin'} {
    @for $i from $min through $max {
      &-top-#{$i} {
        margin-top: rem($i);
      }
    }
    @for $i from $min through $max {
      &-top-#{$i} {
        &-lg {
          @include media-breakpoint-down(lg) {
            margin-top: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-top-#{$i} {
        &-md {
          @include media-breakpoint-down(md) {
            margin-top: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-top-#{$i} {
        &-sm {
          @include media-breakpoint-down(sm) {
            margin-top: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-right-#{$i} {
        margin-right: rem($i);
      }
    }
    @for $i from $min through $max {
      &-right-#{$i} {
        &-lg {
          @include media-breakpoint-down(lg) {
            margin-right: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-right-#{$i} {
        &-md {
          @include media-breakpoint-down(md) {
            margin-right: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-right-#{$i} {
        &-sm {
          @include media-breakpoint-down(sm) {
            margin-right: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-bottom-#{$i} {
        margin-bottom: rem($i);
      }
    }
    @for $i from $min through $max {
      &-bottom-#{$i} {
        &-lg {
          @include media-breakpoint-down(lg) {
            margin-bottom: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-bottom-#{$i} {
        &-md {
          @include media-breakpoint-down(md) {
            margin-bottom: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-bottom-#{$i} {
        &-sm {
          @include media-breakpoint-down(sm) {
            margin-bottom: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-left-#{$i} {
        margin-left: rem($i);
      }
    }
    @for $i from $min through $max {
      &-left-#{$i} {
        &-lg {
          @include media-breakpoint-down(lg) {
            margin-left: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-left-#{$i} {
        &-md {
          @include media-breakpoint-down(md) {
            margin-left: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-left-#{$i} {
        &-sm {
          @include media-breakpoint-down(sm) {
            margin-left: rem($i);
          }
        }
      }
    }
  }

  #{'.padding'} {
    @for $i from $min through $max {
      &-top-#{$i} {
        padding-top: rem($i);
      }
    }
    @for $i from $min through $max {
      &-top-#{$i} {
        &-lg {
          @include media-breakpoint-down(lg) {
            padding-top: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-top-#{$i} {
        &-md {
          @include media-breakpoint-down(md) {
            padding-top: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-top-#{$i} {
        &-sm {
          @include media-breakpoint-down(sm) {
            padding-top: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-right-#{$i} {
        padding-right: rem($i);
      }
    }
    @for $i from $min through $max {
      &-right-#{$i} {
        &-lg {
          @include media-breakpoint-down(lg) {
            padding-right: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-right-#{$i} {
        &-md {
          @include media-breakpoint-down(md) {
            padding-right: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-right-#{$i} {
        &-sm {
          @include media-breakpoint-down(sm) {
            padding-right: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-bottom-#{$i} {
        padding-bottom: rem($i);
      }
    }
    @for $i from $min through $max {
      &-bottom-#{$i} {
        &-lg {
          @include media-breakpoint-down(lg) {
            padding-bottom: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-bottom-#{$i} {
        &-md {
          @include media-breakpoint-down(md) {
            padding-bottom: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-bottom-#{$i} {
        &-sm {
          @include media-breakpoint-down(sm) {
            padding-bottom: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-left-#{$i} {
        padding-left: rem($i);
      }
    }
    @for $i from $min through $max {
      &-left-#{$i} {
        &-lg {
          @include media-breakpoint-down(lg) {
            padding-left: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-left-#{$i} {
        &-md {
          @include media-breakpoint-down(md) {
            padding-left: rem($i);
          }
        }
      }
    }
    @for $i from $min through $max {
      &-left-#{$i} {
        &-sm {
          @include media-breakpoint-down(sm) {
            padding-left: rem($i);
          }
        }
      }
    }
  }
}

@mixin uno-width-height($min: 1, $max: 1000) {
  #{'.width'} {
    @for $i from $min through $max {
      &-#{$i} {
        width: rem($i);
      }
    }
  }
  #{'.height'} {
    @for $i from $min through $max {
      &-#{$i} {
        height: rem($i);
      }
    }
  }
}

@mixin uno-position($min: 1, $max: 100) {
  $percentage: '%';
  #{'.position'} {
    @for $i from $min through $max {
      &-top-#{$i} {
        top: #{$i}#{$percentage};
      }
      &-right-#{$i} {
        right: #{$i}#{$percentage};
      }
      &-bottom-#{$i} {
        bottom: #{$i}#{$percentage};
      }
      &-left-#{$i} {
        left: #{$i}#{$percentage};
      }
    }
  }
}

@mixin blog-list($class) {
  .#{$class}-masonry {
    .grid-sizer,
    .grid-item {
      width: calc((100% / 3) - 2rem);
    }

    .gutter-sizer {
      width: 2rem;
    }

    .grid-item {
      margin-bottom: 2rem;
    }

    a {
      text-decoration: none;
      display: block;
      background: colors(7);
      @include transition(0.5s background);

      .img {
        position: relative;

        img {
          display: block;
          margin: 0 auto;
        }
      }

      &:hover {
        background: colors(5);

        .#{$class}-content {
          color: colors(4);
        }
      }
    }

    .#{$class}-content {
      padding: 1.5rem;
      color: colors(3);

      .#{$class}-title {
        @extend .txt-f10;
        color: colors(1);
        text-transform: uppercase;
      }

      .#{$class}-date {
        @extend .txt-f11;
        padding-top: 0.5rem;
        margin-bottom: 0.5rem;
        color: inherit;
        text-transform: uppercase;
      }

      .#{$class}-desc {
        @extend .txt-f03;
        color: inherit;
        border-top: 1px solid colors(4);
        padding-top: 1rem;
        padding-bottom: 0;
        margin-top: 1rem;
      }
    }

    @include media-breakpoint-down(md) {
      .grid-sizer,
      .grid-item {
        width: calc((100% / 2) - 2rem);
      }
    }

    @include media-breakpoint-down(sm) {
      .grid-sizer,
      .grid-item {
        width: calc((100% / 2) - 1rem);
      }

      .gutter-sizer {
        width: 1rem;
      }

      .grid-item {
        margin-bottom: 1rem;
      }
    }
  }
}

@mixin blog-view($class) {
  .#{$class}-banner {
    margin-bottom: 2rem;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .#{$class}-date {
    color: colors(4);
    @extend .txt-f10;
    text-transform: uppercase;
  }

  .#{$class}-desc {
    color: colors(3);
    @extend .txt-f15;
    line-height: 1.5;
    margin-top: 2rem;

    a {
      color: colors(4);
      text-decoration: none;

      &:hover {
        color: colors(1);
        text-decoration: underline;
      }
    }
  }
}

@mixin gallery-view($class) {
  color: colors(3);
  @extend .txt-f15;
  line-height: 1.5;
  margin-top: 2rem;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    li {
      margin-bottom: 2rem;

      .#{$class}-view-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        text-transform: uppercase;
        @extend .txt-f13;
        color: colors(3);
        border: 1px solid colors(3);

        img {
          padding: 0.2rem;
        }

        &-overlay {
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          height: 100%;
          background: rgba(colors(4), $o03);
          @include transition(opacity 0.5s ease);

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: colors(6);
            background: colors(1);
            border-radius: 100%;
            height: 50px;
            width: 50px;
            line-height: 50px;
            text-align: center;
          }
        }

        &:hover {
          border-color: colors(4);

          .#{$class}-view-box-overlay {
            opacity: 1;
          }
        }
      }
    }
  }
}

@include uno-font-faces('Calibri', '../fonts/', 'Calibri');
@include uno-font-faces('Calibri-Light', '../fonts/', 'Calibri-Light', 300);
@include uno-font-faces('Calibri-Bold', '../fonts/', 'Calibri-Bold', bold);

@include uno-font-styles();
@include uno-font-colors();
@include uno-font-sizes();
@include uno-background-colors();
@include uno-border-colors();
@include uno-line-height();
@include uno-letter-spacing();
@include uno-margin-padding();
@include uno-width-height();
@include uno-position();
