// font awesome type
%fa {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

%fal {
  @extend %fa;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

%far {
  @extend %fa;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

%fas {
  @extend %fa;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

%faf {
  font-family: 'Font Awesome 5 Free' !important;
}

%fab {
  @extend %fa;
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

// opacity
$o01: 0.1;
$o02: 0.25;
$o03: 0.3;
$o04: 0.5;
$o05: 0.8;
$o06: 0.95;

// letter-spacing
$l01: 1px;
$l02: 2px;

// borders
$b01: 1px;
$b02: 2px;
$b03: 3px;

// font-awesome icons
$fa01: '\f002';
$fa02: '\f104';
$fa03: '\f105';
$fa04: '\f3c5';
$fa05: '\f0e0';
$fa06: '\f095';
$fa07: '\f1ac';
$fa08: '\f39e';
$fa09: '\f0e1';
$fa10: '\f0da';
$fa11: '\f106';
$fa12: '\f30b';
$fa13: '\f111';
$fa14: '\f00d';
$fa15: '\f015';
$fa16: '\f019';
$fa17: '\f1b0';
$fa18: '\f068';
$fa19: '\f0d7';
$fa20: '\f0d8';
$fa21: '\f0c9';
$fa22: '\f30a';
$fa23: '\f1ad';
$fa24: '\f067';
$fa25: '\f03a';
$fa26: '\f00c';
$fa27: '\f071';
$fa28: '\f06a';
$fa29: '\f406';
$fa30: '\f290';
$fa31: '\f30c';
$fa32: '\f0d2';
$fa33: '\f1de';
$fa34: '\f0dc';
$fa35: '\f004';
$fa36: '\f007';
$fa37: '\f4be';
$fa38: '\f187';
$fa39: '\f304';
$fa40: '\f6aa';

// social colors
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #bc2a8d;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5b9a68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #eb4823;
$yahoo: #7b0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;

// alert colors
$success-bg: #d4edda;
$success: #155724;
$error-bg: #f8d7da;
$error: #721c24;
$warning-bg: #fff3cd;
$warning: #856404;

// other colors
$primary-color: #19a4dd;
$secondary-color: #0f5e93;
$link-color: #19a4dd;
$link-hover-color: #0f5e93;

$uno-fonts: (
  ('Calibri-Bold', 15),
  ('Calibri', 35),
  ('Calibri', 15),
  ('Calibri-Bold', 80),
  ('Calibri-Light', 80),
  ('Calibri', 18),
  ('Calibri-Light', 45),
  ('Calibri-Bold', 30),
  ('Calibri-Light', 20),
  ('Calibri-Bold', 20),
  ('Calibri-Light', 15),
  ('Calibri', 10),
  ('Calibri-Light', 12),
  ('Calibri', 55),
  ('Calibri-Light', 17),
  ('Calibri', 17),
  ('Calibri-Bold', 17)
);

$uno-colors: (#f3662b, #666867, #838383, #000000, #e2e2e2, #ffffff, #f6f6f6);

$baseFont: 'Calibri';
$baseSize: 15px;

$sectionBorderTop: 5px;
$headerHeight: 80px;
$headerHeightMobile: 80px;

$footerHeight: 108px + $sectionBorderTop;
$footerHeightMobile: 50px;

$cmsMenuEachSubMenuHeight: 49px;

$mapContainerHeight: 250px;

@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}