@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
#breadcrumb .breadcrumb > li + li:before, .cms-btn::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.cms-btn::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

#breadcrumb .breadcrumb > li + li:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@font-face {
  font-family: "Calibri";
  src: url("Calibri.eot");
  src: local("Calibri"), url("../fonts/Calibri.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri.woff2") format("woff2"), url("../fonts/Calibri.woff") format("woff"), url("../fonts/Calibri.ttf") format("truetype"), url("../fonts/Calibri.svg") format("svg");
  font-weight: "Calibri";
  font-style: normal;
}
@font-face {
  font-family: "Calibri-Light";
  src: url("Calibri-Light.eot");
  src: local("Calibri-Light"), url("../fonts/Calibri-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri-Light.woff2") format("woff2"), url("../fonts/Calibri-Light.woff") format("woff"), url("../fonts/Calibri-Light.ttf") format("truetype"), url("../fonts/Calibri-Light.svg") format("svg");
  font-weight: "Calibri-Light";
  font-style: 300;
}
@font-face {
  font-family: "Calibri-Bold";
  src: url("Calibri-Bold.eot");
  src: local("Calibri-Bold"), url("../fonts/Calibri-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Calibri-Bold.woff2") format("woff2"), url("../fonts/Calibri-Bold.woff") format("woff"), url("../fonts/Calibri-Bold.ttf") format("truetype"), url("../fonts/Calibri-Bold.svg") format("svg");
  font-weight: "Calibri-Bold";
  font-style: bold;
}
.txt-f01, #sitemap ul li a, #banner .banner-content p, #cms-menu ul li a, .cms-btn {
  font-family: "Calibri-Bold";
  font-size: 0.9375rem;
}
@media (max-width: 1199.98px) {
  .txt-f01-lg {
    font-family: "Calibri-Bold";
    font-size: 0.8203125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f01-md {
    font-family: "Calibri-Bold";
    font-size: 0.703125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f01-sm {
    font-family: "Calibri-Bold";
    font-size: 0.5859375rem;
  }
}
.txt-f02, #search-page h3, #inner-banner .inner-banner-content p, #banner .banner-content h3 {
  font-family: "Calibri";
  font-size: 2.1875rem;
}
@media (max-width: 1199.98px) {
  .txt-f02-lg {
    font-family: "Calibri";
    font-size: 1.9140625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f02-md {
    font-family: "Calibri";
    font-size: 1.640625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f02-sm {
    font-family: "Calibri";
    font-size: 1.3671875rem;
  }
}
.txt-f03, #sitemap ul ul li a, .video-list .video-masonry .video-content .video-desc, .photo-list .photo-masonry .photo-content .photo-desc, .event-list .event-masonry .event-content .event-desc, .news-list .news-masonry .news-content .news-desc, .pagination .page-item .page-link, #home-cms .home-cms-1-right h5, #home-cms .home-cms-1-left h5, .link-btn {
  font-family: "Calibri";
  font-size: 0.9375rem;
}
@media (max-width: 1199.98px) {
  .txt-f03-lg {
    font-family: "Calibri";
    font-size: 0.8203125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f03-md {
    font-family: "Calibri";
    font-size: 0.703125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f03-sm {
    font-family: "Calibri";
    font-size: 0.5859375rem;
  }
}
.txt-f04, #home-cms .home-cms-6-content h1, #home-cms .home-cms-1-title ul li {
  font-family: "Calibri-Bold";
  font-size: 5rem;
}
@media (max-width: 1199.98px) {
  .txt-f04-lg {
    font-family: "Calibri-Bold";
    font-size: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f04-md {
    font-family: "Calibri-Bold";
    font-size: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f04-sm {
    font-family: "Calibri-Bold";
    font-size: 3.125rem;
  }
}
.txt-f05, #home-cms .home-cms-1-title h1, #home-cms .home-cms-1-title {
  font-family: "Calibri-Light";
  font-size: 5rem;
}
@media (max-width: 1199.98px) {
  .txt-f05-lg {
    font-family: "Calibri-Light";
    font-size: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f05-md {
    font-family: "Calibri-Light";
    font-size: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f05-sm {
    font-family: "Calibri-Light";
    font-size: 3.125rem;
  }
}
.txt-f06, #home-cms .home-cms-1-content p {
  font-family: "Calibri";
  font-size: 1.125rem;
}
@media (max-width: 1199.98px) {
  .txt-f06-lg {
    font-family: "Calibri";
    font-size: 0.984375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f06-md {
    font-family: "Calibri";
    font-size: 0.84375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f06-sm {
    font-family: "Calibri";
    font-size: 0.703125rem;
  }
}
.txt-f07, #home-cms .home-cms-7-content, #home-cms .home-cms-2-content, #home-cms .home-cms-4-content {
  font-family: "Calibri-Light";
  font-size: 2.8125rem;
}
@media (max-width: 1199.98px) {
  .txt-f07-lg {
    font-family: "Calibri-Light";
    font-size: 2.4609375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f07-md {
    font-family: "Calibri-Light";
    font-size: 2.109375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f07-sm {
    font-family: "Calibri-Light";
    font-size: 1.7578125rem;
  }
}
.txt-f08, #home-cms .home-cms-3-content-item h4, #home-cms .home-cms-5-content-item h4, .big-title {
  font-family: "Calibri-Bold";
  font-size: 1.875rem;
}
@media (max-width: 1199.98px) {
  .txt-f08-lg {
    font-family: "Calibri-Bold";
    font-size: 1.640625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f08-md {
    font-family: "Calibri-Bold";
    font-size: 1.40625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f08-sm {
    font-family: "Calibri-Bold";
    font-size: 1.171875rem;
  }
}
.txt-f09, #home-cms .home-cms-6-content p {
  font-family: "Calibri-Light";
  font-size: 1.25rem;
}
@media (max-width: 1199.98px) {
  .txt-f09-lg {
    font-family: "Calibri-Light";
    font-size: 1.09375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f09-md {
    font-family: "Calibri-Light";
    font-size: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f09-sm {
    font-family: "Calibri-Light";
    font-size: 0.78125rem;
  }
}
.txt-f10, #search-page h5, #search-page h4, .video-list .video-masonry .video-content .video-title, .photo-list .photo-masonry .photo-content .photo-title, .event-view .event-date, .event-list .event-masonry .event-content .event-title, .news-view .news-date, .news-list .news-masonry .news-content .news-title, #home-enquiry h4 {
  font-family: "Calibri-Bold";
  font-size: 1.25rem;
}
@media (max-width: 1199.98px) {
  .txt-f10-lg {
    font-family: "Calibri-Bold";
    font-size: 1.09375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f10-md {
    font-family: "Calibri-Bold";
    font-size: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f10-sm {
    font-family: "Calibri-Bold";
    font-size: 0.78125rem;
  }
}
.txt-f11, #contactform .form-control,
#newsletterform .form-control, .video-list .video-masonry .video-content .video-date, .photo-list .photo-masonry .photo-content .photo-date, .event-list .event-masonry .event-content .event-date, .news-list .news-masonry .news-content .news-date {
  font-family: "Calibri-Light";
  font-size: 0.9375rem;
}
@media (max-width: 1199.98px) {
  .txt-f11-lg {
    font-family: "Calibri-Light";
    font-size: 0.8203125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f11-md {
    font-family: "Calibri-Light";
    font-size: 0.703125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f11-sm {
    font-family: "Calibri-Light";
    font-size: 0.5859375rem;
  }
}
.txt-f12, #breadcrumb .breadcrumb {
  font-family: "Calibri";
  font-size: 0.625rem;
}
@media (max-width: 1199.98px) {
  .txt-f12-lg {
    font-family: "Calibri";
    font-size: 0.546875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f12-md {
    font-family: "Calibri";
    font-size: 0.46875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f12-sm {
    font-family: "Calibri";
    font-size: 0.390625rem;
  }
}
.txt-f13, .video-view ul li .video-view-box, .photo-view ul li .photo-view-box {
  font-family: "Calibri-Light";
  font-size: 0.75rem;
}
@media (max-width: 1199.98px) {
  .txt-f13-lg {
    font-family: "Calibri-Light";
    font-size: 0.65625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f13-md {
    font-family: "Calibri-Light";
    font-size: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f13-sm {
    font-family: "Calibri-Light";
    font-size: 0.46875rem;
  }
}
.txt-f14 {
  font-family: "Calibri";
  font-size: 3.4375rem;
}
@media (max-width: 1199.98px) {
  .txt-f14-lg {
    font-family: "Calibri";
    font-size: 3.0078125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f14-md {
    font-family: "Calibri";
    font-size: 2.578125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f14-sm {
    font-family: "Calibri";
    font-size: 2.1484375rem;
  }
}
.txt-f15, #search-page, .video-view, .photo-view, .event-view .event-desc, .news-view .news-desc {
  font-family: "Calibri-Light";
  font-size: 1.0625rem;
}
@media (max-width: 1199.98px) {
  .txt-f15-lg {
    font-family: "Calibri-Light";
    font-size: 0.9296875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f15-md {
    font-family: "Calibri-Light";
    font-size: 0.796875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f15-sm {
    font-family: "Calibri-Light";
    font-size: 0.6640625rem;
  }
}
.txt-f16, .big-title span {
  font-family: "Calibri";
  font-size: 1.0625rem;
}
@media (max-width: 1199.98px) {
  .txt-f16-lg {
    font-family: "Calibri";
    font-size: 0.9296875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f16-md {
    font-family: "Calibri";
    font-size: 0.796875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f16-sm {
    font-family: "Calibri";
    font-size: 0.6640625rem;
  }
}
.txt-f17 {
  font-family: "Calibri-Bold";
  font-size: 1.0625rem;
}
@media (max-width: 1199.98px) {
  .txt-f17-lg {
    font-family: "Calibri-Bold";
    font-size: 0.9296875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-f17-md {
    font-family: "Calibri-Bold";
    font-size: 0.796875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-f17-sm {
    font-family: "Calibri-Bold";
    font-size: 0.6640625rem;
  }
}

.txt-c01 {
  color: #f3662b;
}
.txt-hover-c01:hover {
  color: #f3662b;
}
.txt-c02 {
  color: #666867;
}
.txt-hover-c02:hover {
  color: #666867;
}
.txt-c03 {
  color: #838383;
}
.txt-hover-c03:hover {
  color: #838383;
}
.txt-c04 {
  color: #000000;
}
.txt-hover-c04:hover {
  color: #000000;
}
.txt-c05 {
  color: #e2e2e2;
}
.txt-hover-c05:hover {
  color: #e2e2e2;
}
.txt-c06 {
  color: #ffffff;
}
.txt-hover-c06:hover {
  color: #ffffff;
}
.txt-c07 {
  color: #f6f6f6;
}
.txt-hover-c07:hover {
  color: #f6f6f6;
}

.txt-size-1 {
  font-size: 0.0625rem;
}
.txt-size-2 {
  font-size: 0.125rem;
}
.txt-size-3 {
  font-size: 0.1875rem;
}
.txt-size-4 {
  font-size: 0.25rem;
}
.txt-size-5 {
  font-size: 0.3125rem;
}
.txt-size-6 {
  font-size: 0.375rem;
}
.txt-size-7 {
  font-size: 0.4375rem;
}
.txt-size-8 {
  font-size: 0.5rem;
}
.txt-size-9 {
  font-size: 0.5625rem;
}
.txt-size-10 {
  font-size: 0.625rem;
}
.txt-size-11 {
  font-size: 0.6875rem;
}
.txt-size-12 {
  font-size: 0.75rem;
}
.txt-size-13 {
  font-size: 0.8125rem;
}
.txt-size-14 {
  font-size: 0.875rem;
}
.txt-size-15 {
  font-size: 0.9375rem;
}
.txt-size-16 {
  font-size: 1rem;
}
.txt-size-17 {
  font-size: 1.0625rem;
}
.txt-size-18 {
  font-size: 1.125rem;
}
.txt-size-19 {
  font-size: 1.1875rem;
}
.txt-size-20 {
  font-size: 1.25rem;
}
.txt-size-21 {
  font-size: 1.3125rem;
}
.txt-size-22 {
  font-size: 1.375rem;
}
.txt-size-23 {
  font-size: 1.4375rem;
}
.txt-size-24 {
  font-size: 1.5rem;
}
.txt-size-25 {
  font-size: 1.5625rem;
}
.txt-size-26 {
  font-size: 1.625rem;
}
.txt-size-27 {
  font-size: 1.6875rem;
}
.txt-size-28 {
  font-size: 1.75rem;
}
.txt-size-29 {
  font-size: 1.8125rem;
}
.txt-size-30 {
  font-size: 1.875rem;
}
.txt-size-31 {
  font-size: 1.9375rem;
}
.txt-size-32 {
  font-size: 2rem;
}
.txt-size-33 {
  font-size: 2.0625rem;
}
.txt-size-34 {
  font-size: 2.125rem;
}
.txt-size-35 {
  font-size: 2.1875rem;
}
.txt-size-36 {
  font-size: 2.25rem;
}
.txt-size-37 {
  font-size: 2.3125rem;
}
.txt-size-38 {
  font-size: 2.375rem;
}
.txt-size-39 {
  font-size: 2.4375rem;
}
.txt-size-40 {
  font-size: 2.5rem;
}
.txt-size-41 {
  font-size: 2.5625rem;
}
.txt-size-42 {
  font-size: 2.625rem;
}
.txt-size-43 {
  font-size: 2.6875rem;
}
.txt-size-44 {
  font-size: 2.75rem;
}
.txt-size-45 {
  font-size: 2.8125rem;
}
.txt-size-46 {
  font-size: 2.875rem;
}
.txt-size-47 {
  font-size: 2.9375rem;
}
.txt-size-48 {
  font-size: 3rem;
}
.txt-size-49 {
  font-size: 3.0625rem;
}
.txt-size-50 {
  font-size: 3.125rem;
}
.txt-size-51 {
  font-size: 3.1875rem;
}
.txt-size-52 {
  font-size: 3.25rem;
}
.txt-size-53 {
  font-size: 3.3125rem;
}
.txt-size-54 {
  font-size: 3.375rem;
}
.txt-size-55 {
  font-size: 3.4375rem;
}
.txt-size-56 {
  font-size: 3.5rem;
}
.txt-size-57 {
  font-size: 3.5625rem;
}
.txt-size-58 {
  font-size: 3.625rem;
}
.txt-size-59 {
  font-size: 3.6875rem;
}
.txt-size-60 {
  font-size: 3.75rem;
}
.txt-size-61 {
  font-size: 3.8125rem;
}
.txt-size-62 {
  font-size: 3.875rem;
}
.txt-size-63 {
  font-size: 3.9375rem;
}
.txt-size-64 {
  font-size: 4rem;
}
.txt-size-65 {
  font-size: 4.0625rem;
}
.txt-size-66 {
  font-size: 4.125rem;
}
.txt-size-67 {
  font-size: 4.1875rem;
}
.txt-size-68 {
  font-size: 4.25rem;
}
.txt-size-69 {
  font-size: 4.3125rem;
}
.txt-size-70 {
  font-size: 4.375rem;
}
.txt-size-71 {
  font-size: 4.4375rem;
}
.txt-size-72 {
  font-size: 4.5rem;
}
.txt-size-73 {
  font-size: 4.5625rem;
}
.txt-size-74 {
  font-size: 4.625rem;
}
.txt-size-75 {
  font-size: 4.6875rem;
}
.txt-size-76 {
  font-size: 4.75rem;
}
.txt-size-77 {
  font-size: 4.8125rem;
}
.txt-size-78 {
  font-size: 4.875rem;
}
.txt-size-79 {
  font-size: 4.9375rem;
}
.txt-size-80 {
  font-size: 5rem;
}
.txt-size-81 {
  font-size: 5.0625rem;
}
.txt-size-82 {
  font-size: 5.125rem;
}
.txt-size-83 {
  font-size: 5.1875rem;
}
.txt-size-84 {
  font-size: 5.25rem;
}
.txt-size-85 {
  font-size: 5.3125rem;
}
.txt-size-86 {
  font-size: 5.375rem;
}
.txt-size-87 {
  font-size: 5.4375rem;
}
.txt-size-88 {
  font-size: 5.5rem;
}
.txt-size-89 {
  font-size: 5.5625rem;
}
.txt-size-90 {
  font-size: 5.625rem;
}
.txt-size-91 {
  font-size: 5.6875rem;
}
.txt-size-92 {
  font-size: 5.75rem;
}
.txt-size-93 {
  font-size: 5.8125rem;
}
.txt-size-94 {
  font-size: 5.875rem;
}
.txt-size-95 {
  font-size: 5.9375rem;
}
.txt-size-96 {
  font-size: 6rem;
}
.txt-size-97 {
  font-size: 6.0625rem;
}
.txt-size-98 {
  font-size: 6.125rem;
}
.txt-size-99 {
  font-size: 6.1875rem;
}
.txt-size-100 {
  font-size: 6.25rem;
}
@media (max-width: 1199.98px) {
  .txt-size-1-lg {
    font-size: 0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-2-lg {
    font-size: 0.125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-3-lg {
    font-size: 0.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-4-lg {
    font-size: 0.25rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-5-lg {
    font-size: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-6-lg {
    font-size: 0.375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-7-lg {
    font-size: 0.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-8-lg {
    font-size: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-9-lg {
    font-size: 0.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-10-lg {
    font-size: 0.625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-11-lg {
    font-size: 0.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-12-lg {
    font-size: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-13-lg {
    font-size: 0.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-14-lg {
    font-size: 0.875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-15-lg {
    font-size: 0.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-16-lg {
    font-size: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-17-lg {
    font-size: 1.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-18-lg {
    font-size: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-19-lg {
    font-size: 1.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-20-lg {
    font-size: 1.25rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-21-lg {
    font-size: 1.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-22-lg {
    font-size: 1.375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-23-lg {
    font-size: 1.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-24-lg {
    font-size: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-25-lg {
    font-size: 1.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-26-lg {
    font-size: 1.625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-27-lg {
    font-size: 1.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-28-lg {
    font-size: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-29-lg {
    font-size: 1.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-30-lg {
    font-size: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-31-lg {
    font-size: 1.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-32-lg {
    font-size: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-33-lg {
    font-size: 2.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-34-lg {
    font-size: 2.125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-35-lg {
    font-size: 2.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-36-lg {
    font-size: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-37-lg {
    font-size: 2.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-38-lg {
    font-size: 2.375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-39-lg {
    font-size: 2.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-40-lg {
    font-size: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-41-lg {
    font-size: 2.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-42-lg {
    font-size: 2.625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-43-lg {
    font-size: 2.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-44-lg {
    font-size: 2.75rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-45-lg {
    font-size: 2.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-46-lg {
    font-size: 2.875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-47-lg {
    font-size: 2.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-48-lg {
    font-size: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-49-lg {
    font-size: 3.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-50-lg {
    font-size: 3.125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-51-lg {
    font-size: 3.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-52-lg {
    font-size: 3.25rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-53-lg {
    font-size: 3.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-54-lg {
    font-size: 3.375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-55-lg {
    font-size: 3.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-56-lg {
    font-size: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-57-lg {
    font-size: 3.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-58-lg {
    font-size: 3.625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-59-lg {
    font-size: 3.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-60-lg {
    font-size: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-61-lg {
    font-size: 3.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-62-lg {
    font-size: 3.875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-63-lg {
    font-size: 3.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-64-lg {
    font-size: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-65-lg {
    font-size: 4.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-66-lg {
    font-size: 4.125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-67-lg {
    font-size: 4.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-68-lg {
    font-size: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-69-lg {
    font-size: 4.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-70-lg {
    font-size: 4.375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-71-lg {
    font-size: 4.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-72-lg {
    font-size: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-73-lg {
    font-size: 4.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-74-lg {
    font-size: 4.625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-75-lg {
    font-size: 4.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-76-lg {
    font-size: 4.75rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-77-lg {
    font-size: 4.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-78-lg {
    font-size: 4.875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-79-lg {
    font-size: 4.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-80-lg {
    font-size: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-81-lg {
    font-size: 5.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-82-lg {
    font-size: 5.125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-83-lg {
    font-size: 5.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-84-lg {
    font-size: 5.25rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-85-lg {
    font-size: 5.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-86-lg {
    font-size: 5.375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-87-lg {
    font-size: 5.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-88-lg {
    font-size: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-89-lg {
    font-size: 5.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-90-lg {
    font-size: 5.625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-91-lg {
    font-size: 5.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-92-lg {
    font-size: 5.75rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-93-lg {
    font-size: 5.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-94-lg {
    font-size: 5.875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-95-lg {
    font-size: 5.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-96-lg {
    font-size: 6rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-97-lg {
    font-size: 6.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-98-lg {
    font-size: 6.125rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-99-lg {
    font-size: 6.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .txt-size-100-lg {
    font-size: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-1-md {
    font-size: 0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-2-md {
    font-size: 0.125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-3-md {
    font-size: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-4-md {
    font-size: 0.25rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-5-md {
    font-size: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-6-md {
    font-size: 0.375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-7-md {
    font-size: 0.4375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-8-md {
    font-size: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-9-md {
    font-size: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-10-md {
    font-size: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-11-md {
    font-size: 0.6875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-12-md {
    font-size: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-13-md {
    font-size: 0.8125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-14-md {
    font-size: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-15-md {
    font-size: 0.9375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-16-md {
    font-size: 1rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-17-md {
    font-size: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-18-md {
    font-size: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-19-md {
    font-size: 1.1875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-20-md {
    font-size: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-21-md {
    font-size: 1.3125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-22-md {
    font-size: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-23-md {
    font-size: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-24-md {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-25-md {
    font-size: 1.5625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-26-md {
    font-size: 1.625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-27-md {
    font-size: 1.6875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-28-md {
    font-size: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-29-md {
    font-size: 1.8125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-30-md {
    font-size: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-31-md {
    font-size: 1.9375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-32-md {
    font-size: 2rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-33-md {
    font-size: 2.0625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-34-md {
    font-size: 2.125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-35-md {
    font-size: 2.1875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-36-md {
    font-size: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-37-md {
    font-size: 2.3125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-38-md {
    font-size: 2.375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-39-md {
    font-size: 2.4375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-40-md {
    font-size: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-41-md {
    font-size: 2.5625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-42-md {
    font-size: 2.625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-43-md {
    font-size: 2.6875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-44-md {
    font-size: 2.75rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-45-md {
    font-size: 2.8125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-46-md {
    font-size: 2.875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-47-md {
    font-size: 2.9375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-48-md {
    font-size: 3rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-49-md {
    font-size: 3.0625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-50-md {
    font-size: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-51-md {
    font-size: 3.1875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-52-md {
    font-size: 3.25rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-53-md {
    font-size: 3.3125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-54-md {
    font-size: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-55-md {
    font-size: 3.4375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-56-md {
    font-size: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-57-md {
    font-size: 3.5625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-58-md {
    font-size: 3.625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-59-md {
    font-size: 3.6875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-60-md {
    font-size: 3.75rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-61-md {
    font-size: 3.8125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-62-md {
    font-size: 3.875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-63-md {
    font-size: 3.9375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-64-md {
    font-size: 4rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-65-md {
    font-size: 4.0625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-66-md {
    font-size: 4.125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-67-md {
    font-size: 4.1875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-68-md {
    font-size: 4.25rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-69-md {
    font-size: 4.3125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-70-md {
    font-size: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-71-md {
    font-size: 4.4375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-72-md {
    font-size: 4.5rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-73-md {
    font-size: 4.5625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-74-md {
    font-size: 4.625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-75-md {
    font-size: 4.6875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-76-md {
    font-size: 4.75rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-77-md {
    font-size: 4.8125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-78-md {
    font-size: 4.875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-79-md {
    font-size: 4.9375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-80-md {
    font-size: 5rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-81-md {
    font-size: 5.0625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-82-md {
    font-size: 5.125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-83-md {
    font-size: 5.1875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-84-md {
    font-size: 5.25rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-85-md {
    font-size: 5.3125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-86-md {
    font-size: 5.375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-87-md {
    font-size: 5.4375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-88-md {
    font-size: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-89-md {
    font-size: 5.5625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-90-md {
    font-size: 5.625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-91-md {
    font-size: 5.6875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-92-md {
    font-size: 5.75rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-93-md {
    font-size: 5.8125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-94-md {
    font-size: 5.875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-95-md {
    font-size: 5.9375rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-96-md {
    font-size: 6rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-97-md {
    font-size: 6.0625rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-98-md {
    font-size: 6.125rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-99-md {
    font-size: 6.1875rem;
  }
}
@media (max-width: 991.98px) {
  .txt-size-100-md {
    font-size: 6.25rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-1-sm {
    font-size: 0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-2-sm {
    font-size: 0.125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-3-sm {
    font-size: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-4-sm {
    font-size: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-5-sm {
    font-size: 0.3125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-6-sm {
    font-size: 0.375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-7-sm {
    font-size: 0.4375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-8-sm {
    font-size: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-9-sm {
    font-size: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-10-sm {
    font-size: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-11-sm {
    font-size: 0.6875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-12-sm {
    font-size: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-13-sm {
    font-size: 0.8125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-14-sm {
    font-size: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-15-sm {
    font-size: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-16-sm {
    font-size: 1rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-17-sm {
    font-size: 1.0625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-18-sm {
    font-size: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-19-sm {
    font-size: 1.1875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-20-sm {
    font-size: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-21-sm {
    font-size: 1.3125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-22-sm {
    font-size: 1.375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-23-sm {
    font-size: 1.4375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-24-sm {
    font-size: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-25-sm {
    font-size: 1.5625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-26-sm {
    font-size: 1.625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-27-sm {
    font-size: 1.6875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-28-sm {
    font-size: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-29-sm {
    font-size: 1.8125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-30-sm {
    font-size: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-31-sm {
    font-size: 1.9375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-32-sm {
    font-size: 2rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-33-sm {
    font-size: 2.0625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-34-sm {
    font-size: 2.125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-35-sm {
    font-size: 2.1875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-36-sm {
    font-size: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-37-sm {
    font-size: 2.3125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-38-sm {
    font-size: 2.375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-39-sm {
    font-size: 2.4375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-40-sm {
    font-size: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-41-sm {
    font-size: 2.5625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-42-sm {
    font-size: 2.625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-43-sm {
    font-size: 2.6875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-44-sm {
    font-size: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-45-sm {
    font-size: 2.8125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-46-sm {
    font-size: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-47-sm {
    font-size: 2.9375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-48-sm {
    font-size: 3rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-49-sm {
    font-size: 3.0625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-50-sm {
    font-size: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-51-sm {
    font-size: 3.1875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-52-sm {
    font-size: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-53-sm {
    font-size: 3.3125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-54-sm {
    font-size: 3.375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-55-sm {
    font-size: 3.4375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-56-sm {
    font-size: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-57-sm {
    font-size: 3.5625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-58-sm {
    font-size: 3.625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-59-sm {
    font-size: 3.6875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-60-sm {
    font-size: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-61-sm {
    font-size: 3.8125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-62-sm {
    font-size: 3.875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-63-sm {
    font-size: 3.9375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-64-sm {
    font-size: 4rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-65-sm {
    font-size: 4.0625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-66-sm {
    font-size: 4.125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-67-sm {
    font-size: 4.1875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-68-sm {
    font-size: 4.25rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-69-sm {
    font-size: 4.3125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-70-sm {
    font-size: 4.375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-71-sm {
    font-size: 4.4375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-72-sm {
    font-size: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-73-sm {
    font-size: 4.5625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-74-sm {
    font-size: 4.625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-75-sm {
    font-size: 4.6875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-76-sm {
    font-size: 4.75rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-77-sm {
    font-size: 4.8125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-78-sm {
    font-size: 4.875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-79-sm {
    font-size: 4.9375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-80-sm {
    font-size: 5rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-81-sm {
    font-size: 5.0625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-82-sm {
    font-size: 5.125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-83-sm {
    font-size: 5.1875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-84-sm {
    font-size: 5.25rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-85-sm {
    font-size: 5.3125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-86-sm {
    font-size: 5.375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-87-sm {
    font-size: 5.4375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-88-sm {
    font-size: 5.5rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-89-sm {
    font-size: 5.5625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-90-sm {
    font-size: 5.625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-91-sm {
    font-size: 5.6875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-92-sm {
    font-size: 5.75rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-93-sm {
    font-size: 5.8125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-94-sm {
    font-size: 5.875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-95-sm {
    font-size: 5.9375rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-96-sm {
    font-size: 6rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-97-sm {
    font-size: 6.0625rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-98-sm {
    font-size: 6.125rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-99-sm {
    font-size: 6.1875rem;
  }
}
@media (max-width: 767.98px) {
  .txt-size-100-sm {
    font-size: 6.25rem;
  }
}

.bg-c01 {
  background: #f3662b;
}
.bg-hover-c01:hover {
  background: #f3662b;
}
.bg-c02 {
  background: #666867;
}
.bg-hover-c02:hover {
  background: #666867;
}
.bg-c03 {
  background: #838383;
}
.bg-hover-c03:hover {
  background: #838383;
}
.bg-c04 {
  background: #000000;
}
.bg-hover-c04:hover {
  background: #000000;
}
.bg-c05 {
  background: #e2e2e2;
}
.bg-hover-c05:hover {
  background: #e2e2e2;
}
.bg-c06 {
  background: #ffffff;
}
.bg-hover-c06:hover {
  background: #ffffff;
}
.bg-c07 {
  background: #f6f6f6;
}
.bg-hover-c07:hover {
  background: #f6f6f6;
}

.bc-c01 {
  border-color: #f3662b;
}
.bc-hover-c01:hover {
  border-color: #f3662b;
}
.bc-c02 {
  border-color: #666867;
}
.bc-hover-c02:hover {
  border-color: #666867;
}
.bc-c03 {
  border-color: #838383;
}
.bc-hover-c03:hover {
  border-color: #838383;
}
.bc-c04 {
  border-color: #000000;
}
.bc-hover-c04:hover {
  border-color: #000000;
}
.bc-c05 {
  border-color: #e2e2e2;
}
.bc-hover-c05:hover {
  border-color: #e2e2e2;
}
.bc-c06 {
  border-color: #ffffff;
}
.bc-hover-c06:hover {
  border-color: #ffffff;
}
.bc-c07 {
  border-color: #f6f6f6;
}
.bc-hover-c07:hover {
  border-color: #f6f6f6;
}

.lh-1 {
  line-height: 0.1;
}
.lh-2 {
  line-height: 0.2;
}
.lh-3 {
  line-height: 0.3;
}
.lh-4 {
  line-height: 0.4;
}
.lh-5 {
  line-height: 0.5;
}
.lh-6 {
  line-height: 0.6;
}
.lh-7 {
  line-height: 0.7;
}
.lh-8 {
  line-height: 0.8;
}
.lh-9 {
  line-height: 0.9;
}
.lh-10 {
  line-height: 1;
}
.lh-11 {
  line-height: 1.1;
}
.lh-12 {
  line-height: 1.2;
}
.lh-13 {
  line-height: 1.3;
}
.lh-14 {
  line-height: 1.4;
}
.lh-15 {
  line-height: 1.5;
}
.lh-16 {
  line-height: 1.6;
}
.lh-17 {
  line-height: 1.7;
}
.lh-18 {
  line-height: 1.8;
}
.lh-19 {
  line-height: 1.9;
}
.lh-20 {
  line-height: 2;
}
.lh-21 {
  line-height: 2.1;
}
.lh-22 {
  line-height: 2.2;
}
.lh-23 {
  line-height: 2.3;
}
.lh-24 {
  line-height: 2.4;
}
.lh-25 {
  line-height: 2.5;
}
.lh-26 {
  line-height: 2.6;
}
.lh-27 {
  line-height: 2.7;
}
.lh-28 {
  line-height: 2.8;
}
.lh-29 {
  line-height: 2.9;
}
.lh-30 {
  line-height: 3;
}
.lh-31 {
  line-height: 3.1;
}
.lh-32 {
  line-height: 3.2;
}
.lh-33 {
  line-height: 3.3;
}
.lh-34 {
  line-height: 3.4;
}
.lh-35 {
  line-height: 3.5;
}
.lh-36 {
  line-height: 3.6;
}
.lh-37 {
  line-height: 3.7;
}
.lh-38 {
  line-height: 3.8;
}
.lh-39 {
  line-height: 3.9;
}
.lh-40 {
  line-height: 4;
}
.lh-41 {
  line-height: 4.1;
}
.lh-42 {
  line-height: 4.2;
}
.lh-43 {
  line-height: 4.3;
}
.lh-44 {
  line-height: 4.4;
}
.lh-45 {
  line-height: 4.5;
}
.lh-46 {
  line-height: 4.6;
}
.lh-47 {
  line-height: 4.7;
}
.lh-48 {
  line-height: 4.8;
}
.lh-49 {
  line-height: 4.9;
}
.lh-50 {
  line-height: 5;
}
.lh-51 {
  line-height: 5.1;
}
.lh-52 {
  line-height: 5.2;
}
.lh-53 {
  line-height: 5.3;
}
.lh-54 {
  line-height: 5.4;
}
.lh-55 {
  line-height: 5.5;
}
.lh-56 {
  line-height: 5.6;
}
.lh-57 {
  line-height: 5.7;
}
.lh-58 {
  line-height: 5.8;
}
.lh-59 {
  line-height: 5.9;
}
.lh-60 {
  line-height: 6;
}
.lh-61 {
  line-height: 6.1;
}
.lh-62 {
  line-height: 6.2;
}
.lh-63 {
  line-height: 6.3;
}
.lh-64 {
  line-height: 6.4;
}
.lh-65 {
  line-height: 6.5;
}
.lh-66 {
  line-height: 6.6;
}
.lh-67 {
  line-height: 6.7;
}
.lh-68 {
  line-height: 6.8;
}
.lh-69 {
  line-height: 6.9;
}
.lh-70 {
  line-height: 7;
}
.lh-71 {
  line-height: 7.1;
}
.lh-72 {
  line-height: 7.2;
}
.lh-73 {
  line-height: 7.3;
}
.lh-74 {
  line-height: 7.4;
}
.lh-75 {
  line-height: 7.5;
}
.lh-76 {
  line-height: 7.6;
}
.lh-77 {
  line-height: 7.7;
}
.lh-78 {
  line-height: 7.8;
}
.lh-79 {
  line-height: 7.9;
}
.lh-80 {
  line-height: 8;
}
.lh-81 {
  line-height: 8.1;
}
.lh-82 {
  line-height: 8.2;
}
.lh-83 {
  line-height: 8.3;
}
.lh-84 {
  line-height: 8.4;
}
.lh-85 {
  line-height: 8.5;
}
.lh-86 {
  line-height: 8.6;
}
.lh-87 {
  line-height: 8.7;
}
.lh-88 {
  line-height: 8.8;
}
.lh-89 {
  line-height: 8.9;
}
.lh-90 {
  line-height: 9;
}
.lh-91 {
  line-height: 9.1;
}
.lh-92 {
  line-height: 9.2;
}
.lh-93 {
  line-height: 9.3;
}
.lh-94 {
  line-height: 9.4;
}
.lh-95 {
  line-height: 9.5;
}
.lh-96 {
  line-height: 9.6;
}
.lh-97 {
  line-height: 9.7;
}
.lh-98 {
  line-height: 9.8;
}
.lh-99 {
  line-height: 9.9;
}
.lh-100 {
  line-height: 10;
}

.ls-1 {
  letter-spacing: 0.0625rem;
}
.ls-2 {
  letter-spacing: 0.125rem;
}
.ls-3 {
  letter-spacing: 0.1875rem;
}
.ls-4 {
  letter-spacing: 0.25rem;
}
.ls-5 {
  letter-spacing: 0.3125rem;
}
.ls-6 {
  letter-spacing: 0.375rem;
}
.ls-7 {
  letter-spacing: 0.4375rem;
}
.ls-8 {
  letter-spacing: 0.5rem;
}
.ls-9 {
  letter-spacing: 0.5625rem;
}
.ls-10 {
  letter-spacing: 0.625rem;
}
.ls-11 {
  letter-spacing: 0.6875rem;
}
.ls-12 {
  letter-spacing: 0.75rem;
}
.ls-13 {
  letter-spacing: 0.8125rem;
}
.ls-14 {
  letter-spacing: 0.875rem;
}
.ls-15 {
  letter-spacing: 0.9375rem;
}
.ls-16 {
  letter-spacing: 1rem;
}
.ls-17 {
  letter-spacing: 1.0625rem;
}
.ls-18 {
  letter-spacing: 1.125rem;
}
.ls-19 {
  letter-spacing: 1.1875rem;
}
.ls-20 {
  letter-spacing: 1.25rem;
}
.ls-21 {
  letter-spacing: 1.3125rem;
}
.ls-22 {
  letter-spacing: 1.375rem;
}
.ls-23 {
  letter-spacing: 1.4375rem;
}
.ls-24 {
  letter-spacing: 1.5rem;
}
.ls-25 {
  letter-spacing: 1.5625rem;
}
.ls-26 {
  letter-spacing: 1.625rem;
}
.ls-27 {
  letter-spacing: 1.6875rem;
}
.ls-28 {
  letter-spacing: 1.75rem;
}
.ls-29 {
  letter-spacing: 1.8125rem;
}
.ls-30 {
  letter-spacing: 1.875rem;
}
.ls-31 {
  letter-spacing: 1.9375rem;
}
.ls-32 {
  letter-spacing: 2rem;
}
.ls-33 {
  letter-spacing: 2.0625rem;
}
.ls-34 {
  letter-spacing: 2.125rem;
}
.ls-35 {
  letter-spacing: 2.1875rem;
}
.ls-36 {
  letter-spacing: 2.25rem;
}
.ls-37 {
  letter-spacing: 2.3125rem;
}
.ls-38 {
  letter-spacing: 2.375rem;
}
.ls-39 {
  letter-spacing: 2.4375rem;
}
.ls-40 {
  letter-spacing: 2.5rem;
}
.ls-41 {
  letter-spacing: 2.5625rem;
}
.ls-42 {
  letter-spacing: 2.625rem;
}
.ls-43 {
  letter-spacing: 2.6875rem;
}
.ls-44 {
  letter-spacing: 2.75rem;
}
.ls-45 {
  letter-spacing: 2.8125rem;
}
.ls-46 {
  letter-spacing: 2.875rem;
}
.ls-47 {
  letter-spacing: 2.9375rem;
}
.ls-48 {
  letter-spacing: 3rem;
}
.ls-49 {
  letter-spacing: 3.0625rem;
}
.ls-50 {
  letter-spacing: 3.125rem;
}
.ls-51 {
  letter-spacing: 3.1875rem;
}
.ls-52 {
  letter-spacing: 3.25rem;
}
.ls-53 {
  letter-spacing: 3.3125rem;
}
.ls-54 {
  letter-spacing: 3.375rem;
}
.ls-55 {
  letter-spacing: 3.4375rem;
}
.ls-56 {
  letter-spacing: 3.5rem;
}
.ls-57 {
  letter-spacing: 3.5625rem;
}
.ls-58 {
  letter-spacing: 3.625rem;
}
.ls-59 {
  letter-spacing: 3.6875rem;
}
.ls-60 {
  letter-spacing: 3.75rem;
}
.ls-61 {
  letter-spacing: 3.8125rem;
}
.ls-62 {
  letter-spacing: 3.875rem;
}
.ls-63 {
  letter-spacing: 3.9375rem;
}
.ls-64 {
  letter-spacing: 4rem;
}
.ls-65 {
  letter-spacing: 4.0625rem;
}
.ls-66 {
  letter-spacing: 4.125rem;
}
.ls-67 {
  letter-spacing: 4.1875rem;
}
.ls-68 {
  letter-spacing: 4.25rem;
}
.ls-69 {
  letter-spacing: 4.3125rem;
}
.ls-70 {
  letter-spacing: 4.375rem;
}
.ls-71 {
  letter-spacing: 4.4375rem;
}
.ls-72 {
  letter-spacing: 4.5rem;
}
.ls-73 {
  letter-spacing: 4.5625rem;
}
.ls-74 {
  letter-spacing: 4.625rem;
}
.ls-75 {
  letter-spacing: 4.6875rem;
}
.ls-76 {
  letter-spacing: 4.75rem;
}
.ls-77 {
  letter-spacing: 4.8125rem;
}
.ls-78 {
  letter-spacing: 4.875rem;
}
.ls-79 {
  letter-spacing: 4.9375rem;
}
.ls-80 {
  letter-spacing: 5rem;
}
.ls-81 {
  letter-spacing: 5.0625rem;
}
.ls-82 {
  letter-spacing: 5.125rem;
}
.ls-83 {
  letter-spacing: 5.1875rem;
}
.ls-84 {
  letter-spacing: 5.25rem;
}
.ls-85 {
  letter-spacing: 5.3125rem;
}
.ls-86 {
  letter-spacing: 5.375rem;
}
.ls-87 {
  letter-spacing: 5.4375rem;
}
.ls-88 {
  letter-spacing: 5.5rem;
}
.ls-89 {
  letter-spacing: 5.5625rem;
}
.ls-90 {
  letter-spacing: 5.625rem;
}
.ls-91 {
  letter-spacing: 5.6875rem;
}
.ls-92 {
  letter-spacing: 5.75rem;
}
.ls-93 {
  letter-spacing: 5.8125rem;
}
.ls-94 {
  letter-spacing: 5.875rem;
}
.ls-95 {
  letter-spacing: 5.9375rem;
}
.ls-96 {
  letter-spacing: 6rem;
}
.ls-97 {
  letter-spacing: 6.0625rem;
}
.ls-98 {
  letter-spacing: 6.125rem;
}
.ls-99 {
  letter-spacing: 6.1875rem;
}
.ls-100 {
  letter-spacing: 6.25rem;
}

.margin-top-1 {
  margin-top: 0.0625rem;
}
.margin-top-2 {
  margin-top: 0.125rem;
}
.margin-top-3 {
  margin-top: 0.1875rem;
}
.margin-top-4 {
  margin-top: 0.25rem;
}
.margin-top-5 {
  margin-top: 0.3125rem;
}
.margin-top-6 {
  margin-top: 0.375rem;
}
.margin-top-7 {
  margin-top: 0.4375rem;
}
.margin-top-8 {
  margin-top: 0.5rem;
}
.margin-top-9 {
  margin-top: 0.5625rem;
}
.margin-top-10 {
  margin-top: 0.625rem;
}
.margin-top-11 {
  margin-top: 0.6875rem;
}
.margin-top-12 {
  margin-top: 0.75rem;
}
.margin-top-13 {
  margin-top: 0.8125rem;
}
.margin-top-14 {
  margin-top: 0.875rem;
}
.margin-top-15 {
  margin-top: 0.9375rem;
}
.margin-top-16 {
  margin-top: 1rem;
}
.margin-top-17 {
  margin-top: 1.0625rem;
}
.margin-top-18 {
  margin-top: 1.125rem;
}
.margin-top-19 {
  margin-top: 1.1875rem;
}
.margin-top-20 {
  margin-top: 1.25rem;
}
.margin-top-21 {
  margin-top: 1.3125rem;
}
.margin-top-22 {
  margin-top: 1.375rem;
}
.margin-top-23 {
  margin-top: 1.4375rem;
}
.margin-top-24 {
  margin-top: 1.5rem;
}
.margin-top-25 {
  margin-top: 1.5625rem;
}
.margin-top-26 {
  margin-top: 1.625rem;
}
.margin-top-27 {
  margin-top: 1.6875rem;
}
.margin-top-28 {
  margin-top: 1.75rem;
}
.margin-top-29 {
  margin-top: 1.8125rem;
}
.margin-top-30 {
  margin-top: 1.875rem;
}
.margin-top-31 {
  margin-top: 1.9375rem;
}
.margin-top-32 {
  margin-top: 2rem;
}
.margin-top-33 {
  margin-top: 2.0625rem;
}
.margin-top-34 {
  margin-top: 2.125rem;
}
.margin-top-35 {
  margin-top: 2.1875rem;
}
.margin-top-36 {
  margin-top: 2.25rem;
}
.margin-top-37 {
  margin-top: 2.3125rem;
}
.margin-top-38 {
  margin-top: 2.375rem;
}
.margin-top-39 {
  margin-top: 2.4375rem;
}
.margin-top-40 {
  margin-top: 2.5rem;
}
.margin-top-41 {
  margin-top: 2.5625rem;
}
.margin-top-42 {
  margin-top: 2.625rem;
}
.margin-top-43 {
  margin-top: 2.6875rem;
}
.margin-top-44 {
  margin-top: 2.75rem;
}
.margin-top-45 {
  margin-top: 2.8125rem;
}
.margin-top-46 {
  margin-top: 2.875rem;
}
.margin-top-47 {
  margin-top: 2.9375rem;
}
.margin-top-48 {
  margin-top: 3rem;
}
.margin-top-49 {
  margin-top: 3.0625rem;
}
.margin-top-50 {
  margin-top: 3.125rem;
}
.margin-top-51 {
  margin-top: 3.1875rem;
}
.margin-top-52 {
  margin-top: 3.25rem;
}
.margin-top-53 {
  margin-top: 3.3125rem;
}
.margin-top-54 {
  margin-top: 3.375rem;
}
.margin-top-55 {
  margin-top: 3.4375rem;
}
.margin-top-56 {
  margin-top: 3.5rem;
}
.margin-top-57 {
  margin-top: 3.5625rem;
}
.margin-top-58 {
  margin-top: 3.625rem;
}
.margin-top-59 {
  margin-top: 3.6875rem;
}
.margin-top-60 {
  margin-top: 3.75rem;
}
.margin-top-61 {
  margin-top: 3.8125rem;
}
.margin-top-62 {
  margin-top: 3.875rem;
}
.margin-top-63 {
  margin-top: 3.9375rem;
}
.margin-top-64 {
  margin-top: 4rem;
}
.margin-top-65 {
  margin-top: 4.0625rem;
}
.margin-top-66 {
  margin-top: 4.125rem;
}
.margin-top-67 {
  margin-top: 4.1875rem;
}
.margin-top-68 {
  margin-top: 4.25rem;
}
.margin-top-69 {
  margin-top: 4.3125rem;
}
.margin-top-70 {
  margin-top: 4.375rem;
}
.margin-top-71 {
  margin-top: 4.4375rem;
}
.margin-top-72 {
  margin-top: 4.5rem;
}
.margin-top-73 {
  margin-top: 4.5625rem;
}
.margin-top-74 {
  margin-top: 4.625rem;
}
.margin-top-75 {
  margin-top: 4.6875rem;
}
.margin-top-76 {
  margin-top: 4.75rem;
}
.margin-top-77 {
  margin-top: 4.8125rem;
}
.margin-top-78 {
  margin-top: 4.875rem;
}
.margin-top-79 {
  margin-top: 4.9375rem;
}
.margin-top-80 {
  margin-top: 5rem;
}
.margin-top-81 {
  margin-top: 5.0625rem;
}
.margin-top-82 {
  margin-top: 5.125rem;
}
.margin-top-83 {
  margin-top: 5.1875rem;
}
.margin-top-84 {
  margin-top: 5.25rem;
}
.margin-top-85 {
  margin-top: 5.3125rem;
}
.margin-top-86 {
  margin-top: 5.375rem;
}
.margin-top-87 {
  margin-top: 5.4375rem;
}
.margin-top-88 {
  margin-top: 5.5rem;
}
.margin-top-89 {
  margin-top: 5.5625rem;
}
.margin-top-90 {
  margin-top: 5.625rem;
}
.margin-top-91 {
  margin-top: 5.6875rem;
}
.margin-top-92 {
  margin-top: 5.75rem;
}
.margin-top-93 {
  margin-top: 5.8125rem;
}
.margin-top-94 {
  margin-top: 5.875rem;
}
.margin-top-95 {
  margin-top: 5.9375rem;
}
.margin-top-96 {
  margin-top: 6rem;
}
.margin-top-97 {
  margin-top: 6.0625rem;
}
.margin-top-98 {
  margin-top: 6.125rem;
}
.margin-top-99 {
  margin-top: 6.1875rem;
}
.margin-top-100 {
  margin-top: 6.25rem;
}
@media (max-width: 1199.98px) {
  .margin-top-1-lg {
    margin-top: 0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-2-lg {
    margin-top: 0.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-3-lg {
    margin-top: 0.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-4-lg {
    margin-top: 0.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-5-lg {
    margin-top: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-6-lg {
    margin-top: 0.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-7-lg {
    margin-top: 0.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-8-lg {
    margin-top: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-9-lg {
    margin-top: 0.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-10-lg {
    margin-top: 0.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-11-lg {
    margin-top: 0.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-12-lg {
    margin-top: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-13-lg {
    margin-top: 0.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-14-lg {
    margin-top: 0.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-15-lg {
    margin-top: 0.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-16-lg {
    margin-top: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-17-lg {
    margin-top: 1.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-18-lg {
    margin-top: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-19-lg {
    margin-top: 1.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-20-lg {
    margin-top: 1.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-21-lg {
    margin-top: 1.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-22-lg {
    margin-top: 1.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-23-lg {
    margin-top: 1.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-24-lg {
    margin-top: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-25-lg {
    margin-top: 1.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-26-lg {
    margin-top: 1.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-27-lg {
    margin-top: 1.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-28-lg {
    margin-top: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-29-lg {
    margin-top: 1.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-30-lg {
    margin-top: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-31-lg {
    margin-top: 1.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-32-lg {
    margin-top: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-33-lg {
    margin-top: 2.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-34-lg {
    margin-top: 2.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-35-lg {
    margin-top: 2.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-36-lg {
    margin-top: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-37-lg {
    margin-top: 2.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-38-lg {
    margin-top: 2.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-39-lg {
    margin-top: 2.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-40-lg {
    margin-top: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-41-lg {
    margin-top: 2.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-42-lg {
    margin-top: 2.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-43-lg {
    margin-top: 2.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-44-lg {
    margin-top: 2.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-45-lg {
    margin-top: 2.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-46-lg {
    margin-top: 2.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-47-lg {
    margin-top: 2.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-48-lg {
    margin-top: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-49-lg {
    margin-top: 3.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-50-lg {
    margin-top: 3.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-51-lg {
    margin-top: 3.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-52-lg {
    margin-top: 3.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-53-lg {
    margin-top: 3.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-54-lg {
    margin-top: 3.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-55-lg {
    margin-top: 3.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-56-lg {
    margin-top: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-57-lg {
    margin-top: 3.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-58-lg {
    margin-top: 3.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-59-lg {
    margin-top: 3.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-60-lg {
    margin-top: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-61-lg {
    margin-top: 3.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-62-lg {
    margin-top: 3.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-63-lg {
    margin-top: 3.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-64-lg {
    margin-top: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-65-lg {
    margin-top: 4.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-66-lg {
    margin-top: 4.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-67-lg {
    margin-top: 4.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-68-lg {
    margin-top: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-69-lg {
    margin-top: 4.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-70-lg {
    margin-top: 4.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-71-lg {
    margin-top: 4.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-72-lg {
    margin-top: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-73-lg {
    margin-top: 4.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-74-lg {
    margin-top: 4.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-75-lg {
    margin-top: 4.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-76-lg {
    margin-top: 4.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-77-lg {
    margin-top: 4.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-78-lg {
    margin-top: 4.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-79-lg {
    margin-top: 4.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-80-lg {
    margin-top: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-81-lg {
    margin-top: 5.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-82-lg {
    margin-top: 5.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-83-lg {
    margin-top: 5.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-84-lg {
    margin-top: 5.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-85-lg {
    margin-top: 5.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-86-lg {
    margin-top: 5.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-87-lg {
    margin-top: 5.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-88-lg {
    margin-top: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-89-lg {
    margin-top: 5.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-90-lg {
    margin-top: 5.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-91-lg {
    margin-top: 5.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-92-lg {
    margin-top: 5.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-93-lg {
    margin-top: 5.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-94-lg {
    margin-top: 5.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-95-lg {
    margin-top: 5.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-96-lg {
    margin-top: 6rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-97-lg {
    margin-top: 6.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-98-lg {
    margin-top: 6.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-99-lg {
    margin-top: 6.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-top-100-lg {
    margin-top: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-1-md {
    margin-top: 0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-2-md {
    margin-top: 0.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-3-md {
    margin-top: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-4-md {
    margin-top: 0.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-5-md {
    margin-top: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-6-md {
    margin-top: 0.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-7-md {
    margin-top: 0.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-8-md {
    margin-top: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-9-md {
    margin-top: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-10-md {
    margin-top: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-11-md {
    margin-top: 0.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-12-md {
    margin-top: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-13-md {
    margin-top: 0.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-14-md {
    margin-top: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-15-md {
    margin-top: 0.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-16-md {
    margin-top: 1rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-17-md {
    margin-top: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-18-md {
    margin-top: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-19-md {
    margin-top: 1.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-20-md {
    margin-top: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-21-md {
    margin-top: 1.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-22-md {
    margin-top: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-23-md {
    margin-top: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-24-md {
    margin-top: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-25-md {
    margin-top: 1.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-26-md {
    margin-top: 1.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-27-md {
    margin-top: 1.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-28-md {
    margin-top: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-29-md {
    margin-top: 1.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-30-md {
    margin-top: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-31-md {
    margin-top: 1.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-32-md {
    margin-top: 2rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-33-md {
    margin-top: 2.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-34-md {
    margin-top: 2.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-35-md {
    margin-top: 2.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-36-md {
    margin-top: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-37-md {
    margin-top: 2.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-38-md {
    margin-top: 2.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-39-md {
    margin-top: 2.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-40-md {
    margin-top: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-41-md {
    margin-top: 2.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-42-md {
    margin-top: 2.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-43-md {
    margin-top: 2.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-44-md {
    margin-top: 2.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-45-md {
    margin-top: 2.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-46-md {
    margin-top: 2.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-47-md {
    margin-top: 2.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-48-md {
    margin-top: 3rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-49-md {
    margin-top: 3.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-50-md {
    margin-top: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-51-md {
    margin-top: 3.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-52-md {
    margin-top: 3.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-53-md {
    margin-top: 3.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-54-md {
    margin-top: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-55-md {
    margin-top: 3.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-56-md {
    margin-top: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-57-md {
    margin-top: 3.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-58-md {
    margin-top: 3.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-59-md {
    margin-top: 3.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-60-md {
    margin-top: 3.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-61-md {
    margin-top: 3.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-62-md {
    margin-top: 3.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-63-md {
    margin-top: 3.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-64-md {
    margin-top: 4rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-65-md {
    margin-top: 4.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-66-md {
    margin-top: 4.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-67-md {
    margin-top: 4.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-68-md {
    margin-top: 4.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-69-md {
    margin-top: 4.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-70-md {
    margin-top: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-71-md {
    margin-top: 4.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-72-md {
    margin-top: 4.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-73-md {
    margin-top: 4.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-74-md {
    margin-top: 4.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-75-md {
    margin-top: 4.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-76-md {
    margin-top: 4.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-77-md {
    margin-top: 4.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-78-md {
    margin-top: 4.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-79-md {
    margin-top: 4.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-80-md {
    margin-top: 5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-81-md {
    margin-top: 5.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-82-md {
    margin-top: 5.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-83-md {
    margin-top: 5.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-84-md {
    margin-top: 5.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-85-md {
    margin-top: 5.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-86-md {
    margin-top: 5.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-87-md {
    margin-top: 5.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-88-md {
    margin-top: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-89-md {
    margin-top: 5.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-90-md {
    margin-top: 5.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-91-md {
    margin-top: 5.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-92-md {
    margin-top: 5.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-93-md {
    margin-top: 5.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-94-md {
    margin-top: 5.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-95-md {
    margin-top: 5.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-96-md {
    margin-top: 6rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-97-md {
    margin-top: 6.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-98-md {
    margin-top: 6.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-99-md {
    margin-top: 6.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-top-100-md {
    margin-top: 6.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-1-sm {
    margin-top: 0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-2-sm {
    margin-top: 0.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-3-sm {
    margin-top: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-4-sm {
    margin-top: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-5-sm {
    margin-top: 0.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-6-sm {
    margin-top: 0.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-7-sm {
    margin-top: 0.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-8-sm {
    margin-top: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-9-sm {
    margin-top: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-10-sm {
    margin-top: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-11-sm {
    margin-top: 0.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-12-sm {
    margin-top: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-13-sm {
    margin-top: 0.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-14-sm {
    margin-top: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-15-sm {
    margin-top: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-16-sm {
    margin-top: 1rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-17-sm {
    margin-top: 1.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-18-sm {
    margin-top: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-19-sm {
    margin-top: 1.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-20-sm {
    margin-top: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-21-sm {
    margin-top: 1.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-22-sm {
    margin-top: 1.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-23-sm {
    margin-top: 1.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-24-sm {
    margin-top: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-25-sm {
    margin-top: 1.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-26-sm {
    margin-top: 1.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-27-sm {
    margin-top: 1.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-28-sm {
    margin-top: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-29-sm {
    margin-top: 1.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-30-sm {
    margin-top: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-31-sm {
    margin-top: 1.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-32-sm {
    margin-top: 2rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-33-sm {
    margin-top: 2.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-34-sm {
    margin-top: 2.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-35-sm {
    margin-top: 2.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-36-sm {
    margin-top: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-37-sm {
    margin-top: 2.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-38-sm {
    margin-top: 2.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-39-sm {
    margin-top: 2.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-40-sm {
    margin-top: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-41-sm {
    margin-top: 2.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-42-sm {
    margin-top: 2.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-43-sm {
    margin-top: 2.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-44-sm {
    margin-top: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-45-sm {
    margin-top: 2.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-46-sm {
    margin-top: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-47-sm {
    margin-top: 2.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-48-sm {
    margin-top: 3rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-49-sm {
    margin-top: 3.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-50-sm {
    margin-top: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-51-sm {
    margin-top: 3.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-52-sm {
    margin-top: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-53-sm {
    margin-top: 3.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-54-sm {
    margin-top: 3.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-55-sm {
    margin-top: 3.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-56-sm {
    margin-top: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-57-sm {
    margin-top: 3.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-58-sm {
    margin-top: 3.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-59-sm {
    margin-top: 3.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-60-sm {
    margin-top: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-61-sm {
    margin-top: 3.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-62-sm {
    margin-top: 3.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-63-sm {
    margin-top: 3.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-64-sm {
    margin-top: 4rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-65-sm {
    margin-top: 4.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-66-sm {
    margin-top: 4.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-67-sm {
    margin-top: 4.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-68-sm {
    margin-top: 4.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-69-sm {
    margin-top: 4.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-70-sm {
    margin-top: 4.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-71-sm {
    margin-top: 4.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-72-sm {
    margin-top: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-73-sm {
    margin-top: 4.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-74-sm {
    margin-top: 4.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-75-sm {
    margin-top: 4.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-76-sm {
    margin-top: 4.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-77-sm {
    margin-top: 4.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-78-sm {
    margin-top: 4.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-79-sm {
    margin-top: 4.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-80-sm {
    margin-top: 5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-81-sm {
    margin-top: 5.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-82-sm {
    margin-top: 5.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-83-sm {
    margin-top: 5.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-84-sm {
    margin-top: 5.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-85-sm {
    margin-top: 5.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-86-sm {
    margin-top: 5.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-87-sm {
    margin-top: 5.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-88-sm {
    margin-top: 5.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-89-sm {
    margin-top: 5.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-90-sm {
    margin-top: 5.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-91-sm {
    margin-top: 5.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-92-sm {
    margin-top: 5.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-93-sm {
    margin-top: 5.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-94-sm {
    margin-top: 5.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-95-sm {
    margin-top: 5.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-96-sm {
    margin-top: 6rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-97-sm {
    margin-top: 6.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-98-sm {
    margin-top: 6.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-99-sm {
    margin-top: 6.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-top-100-sm {
    margin-top: 6.25rem;
  }
}
.margin-right-1 {
  margin-right: 0.0625rem;
}
.margin-right-2 {
  margin-right: 0.125rem;
}
.margin-right-3 {
  margin-right: 0.1875rem;
}
.margin-right-4 {
  margin-right: 0.25rem;
}
.margin-right-5 {
  margin-right: 0.3125rem;
}
.margin-right-6 {
  margin-right: 0.375rem;
}
.margin-right-7 {
  margin-right: 0.4375rem;
}
.margin-right-8 {
  margin-right: 0.5rem;
}
.margin-right-9 {
  margin-right: 0.5625rem;
}
.margin-right-10 {
  margin-right: 0.625rem;
}
.margin-right-11 {
  margin-right: 0.6875rem;
}
.margin-right-12 {
  margin-right: 0.75rem;
}
.margin-right-13 {
  margin-right: 0.8125rem;
}
.margin-right-14 {
  margin-right: 0.875rem;
}
.margin-right-15 {
  margin-right: 0.9375rem;
}
.margin-right-16 {
  margin-right: 1rem;
}
.margin-right-17 {
  margin-right: 1.0625rem;
}
.margin-right-18 {
  margin-right: 1.125rem;
}
.margin-right-19 {
  margin-right: 1.1875rem;
}
.margin-right-20 {
  margin-right: 1.25rem;
}
.margin-right-21 {
  margin-right: 1.3125rem;
}
.margin-right-22 {
  margin-right: 1.375rem;
}
.margin-right-23 {
  margin-right: 1.4375rem;
}
.margin-right-24 {
  margin-right: 1.5rem;
}
.margin-right-25 {
  margin-right: 1.5625rem;
}
.margin-right-26 {
  margin-right: 1.625rem;
}
.margin-right-27 {
  margin-right: 1.6875rem;
}
.margin-right-28 {
  margin-right: 1.75rem;
}
.margin-right-29 {
  margin-right: 1.8125rem;
}
.margin-right-30 {
  margin-right: 1.875rem;
}
.margin-right-31 {
  margin-right: 1.9375rem;
}
.margin-right-32 {
  margin-right: 2rem;
}
.margin-right-33 {
  margin-right: 2.0625rem;
}
.margin-right-34 {
  margin-right: 2.125rem;
}
.margin-right-35 {
  margin-right: 2.1875rem;
}
.margin-right-36 {
  margin-right: 2.25rem;
}
.margin-right-37 {
  margin-right: 2.3125rem;
}
.margin-right-38 {
  margin-right: 2.375rem;
}
.margin-right-39 {
  margin-right: 2.4375rem;
}
.margin-right-40 {
  margin-right: 2.5rem;
}
.margin-right-41 {
  margin-right: 2.5625rem;
}
.margin-right-42 {
  margin-right: 2.625rem;
}
.margin-right-43 {
  margin-right: 2.6875rem;
}
.margin-right-44 {
  margin-right: 2.75rem;
}
.margin-right-45 {
  margin-right: 2.8125rem;
}
.margin-right-46 {
  margin-right: 2.875rem;
}
.margin-right-47 {
  margin-right: 2.9375rem;
}
.margin-right-48 {
  margin-right: 3rem;
}
.margin-right-49 {
  margin-right: 3.0625rem;
}
.margin-right-50 {
  margin-right: 3.125rem;
}
.margin-right-51 {
  margin-right: 3.1875rem;
}
.margin-right-52 {
  margin-right: 3.25rem;
}
.margin-right-53 {
  margin-right: 3.3125rem;
}
.margin-right-54 {
  margin-right: 3.375rem;
}
.margin-right-55 {
  margin-right: 3.4375rem;
}
.margin-right-56 {
  margin-right: 3.5rem;
}
.margin-right-57 {
  margin-right: 3.5625rem;
}
.margin-right-58 {
  margin-right: 3.625rem;
}
.margin-right-59 {
  margin-right: 3.6875rem;
}
.margin-right-60 {
  margin-right: 3.75rem;
}
.margin-right-61 {
  margin-right: 3.8125rem;
}
.margin-right-62 {
  margin-right: 3.875rem;
}
.margin-right-63 {
  margin-right: 3.9375rem;
}
.margin-right-64 {
  margin-right: 4rem;
}
.margin-right-65 {
  margin-right: 4.0625rem;
}
.margin-right-66 {
  margin-right: 4.125rem;
}
.margin-right-67 {
  margin-right: 4.1875rem;
}
.margin-right-68 {
  margin-right: 4.25rem;
}
.margin-right-69 {
  margin-right: 4.3125rem;
}
.margin-right-70 {
  margin-right: 4.375rem;
}
.margin-right-71 {
  margin-right: 4.4375rem;
}
.margin-right-72 {
  margin-right: 4.5rem;
}
.margin-right-73 {
  margin-right: 4.5625rem;
}
.margin-right-74 {
  margin-right: 4.625rem;
}
.margin-right-75 {
  margin-right: 4.6875rem;
}
.margin-right-76 {
  margin-right: 4.75rem;
}
.margin-right-77 {
  margin-right: 4.8125rem;
}
.margin-right-78 {
  margin-right: 4.875rem;
}
.margin-right-79 {
  margin-right: 4.9375rem;
}
.margin-right-80 {
  margin-right: 5rem;
}
.margin-right-81 {
  margin-right: 5.0625rem;
}
.margin-right-82 {
  margin-right: 5.125rem;
}
.margin-right-83 {
  margin-right: 5.1875rem;
}
.margin-right-84 {
  margin-right: 5.25rem;
}
.margin-right-85 {
  margin-right: 5.3125rem;
}
.margin-right-86 {
  margin-right: 5.375rem;
}
.margin-right-87 {
  margin-right: 5.4375rem;
}
.margin-right-88 {
  margin-right: 5.5rem;
}
.margin-right-89 {
  margin-right: 5.5625rem;
}
.margin-right-90 {
  margin-right: 5.625rem;
}
.margin-right-91 {
  margin-right: 5.6875rem;
}
.margin-right-92 {
  margin-right: 5.75rem;
}
.margin-right-93 {
  margin-right: 5.8125rem;
}
.margin-right-94 {
  margin-right: 5.875rem;
}
.margin-right-95 {
  margin-right: 5.9375rem;
}
.margin-right-96 {
  margin-right: 6rem;
}
.margin-right-97 {
  margin-right: 6.0625rem;
}
.margin-right-98 {
  margin-right: 6.125rem;
}
.margin-right-99 {
  margin-right: 6.1875rem;
}
.margin-right-100 {
  margin-right: 6.25rem;
}
@media (max-width: 1199.98px) {
  .margin-right-1-lg {
    margin-right: 0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-2-lg {
    margin-right: 0.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-3-lg {
    margin-right: 0.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-4-lg {
    margin-right: 0.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-5-lg {
    margin-right: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-6-lg {
    margin-right: 0.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-7-lg {
    margin-right: 0.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-8-lg {
    margin-right: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-9-lg {
    margin-right: 0.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-10-lg {
    margin-right: 0.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-11-lg {
    margin-right: 0.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-12-lg {
    margin-right: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-13-lg {
    margin-right: 0.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-14-lg {
    margin-right: 0.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-15-lg {
    margin-right: 0.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-16-lg {
    margin-right: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-17-lg {
    margin-right: 1.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-18-lg {
    margin-right: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-19-lg {
    margin-right: 1.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-20-lg {
    margin-right: 1.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-21-lg {
    margin-right: 1.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-22-lg {
    margin-right: 1.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-23-lg {
    margin-right: 1.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-24-lg {
    margin-right: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-25-lg {
    margin-right: 1.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-26-lg {
    margin-right: 1.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-27-lg {
    margin-right: 1.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-28-lg {
    margin-right: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-29-lg {
    margin-right: 1.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-30-lg {
    margin-right: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-31-lg {
    margin-right: 1.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-32-lg {
    margin-right: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-33-lg {
    margin-right: 2.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-34-lg {
    margin-right: 2.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-35-lg {
    margin-right: 2.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-36-lg {
    margin-right: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-37-lg {
    margin-right: 2.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-38-lg {
    margin-right: 2.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-39-lg {
    margin-right: 2.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-40-lg {
    margin-right: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-41-lg {
    margin-right: 2.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-42-lg {
    margin-right: 2.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-43-lg {
    margin-right: 2.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-44-lg {
    margin-right: 2.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-45-lg {
    margin-right: 2.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-46-lg {
    margin-right: 2.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-47-lg {
    margin-right: 2.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-48-lg {
    margin-right: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-49-lg {
    margin-right: 3.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-50-lg {
    margin-right: 3.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-51-lg {
    margin-right: 3.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-52-lg {
    margin-right: 3.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-53-lg {
    margin-right: 3.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-54-lg {
    margin-right: 3.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-55-lg {
    margin-right: 3.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-56-lg {
    margin-right: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-57-lg {
    margin-right: 3.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-58-lg {
    margin-right: 3.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-59-lg {
    margin-right: 3.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-60-lg {
    margin-right: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-61-lg {
    margin-right: 3.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-62-lg {
    margin-right: 3.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-63-lg {
    margin-right: 3.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-64-lg {
    margin-right: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-65-lg {
    margin-right: 4.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-66-lg {
    margin-right: 4.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-67-lg {
    margin-right: 4.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-68-lg {
    margin-right: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-69-lg {
    margin-right: 4.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-70-lg {
    margin-right: 4.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-71-lg {
    margin-right: 4.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-72-lg {
    margin-right: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-73-lg {
    margin-right: 4.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-74-lg {
    margin-right: 4.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-75-lg {
    margin-right: 4.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-76-lg {
    margin-right: 4.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-77-lg {
    margin-right: 4.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-78-lg {
    margin-right: 4.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-79-lg {
    margin-right: 4.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-80-lg {
    margin-right: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-81-lg {
    margin-right: 5.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-82-lg {
    margin-right: 5.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-83-lg {
    margin-right: 5.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-84-lg {
    margin-right: 5.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-85-lg {
    margin-right: 5.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-86-lg {
    margin-right: 5.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-87-lg {
    margin-right: 5.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-88-lg {
    margin-right: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-89-lg {
    margin-right: 5.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-90-lg {
    margin-right: 5.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-91-lg {
    margin-right: 5.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-92-lg {
    margin-right: 5.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-93-lg {
    margin-right: 5.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-94-lg {
    margin-right: 5.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-95-lg {
    margin-right: 5.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-96-lg {
    margin-right: 6rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-97-lg {
    margin-right: 6.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-98-lg {
    margin-right: 6.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-99-lg {
    margin-right: 6.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-right-100-lg {
    margin-right: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-1-md {
    margin-right: 0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-2-md {
    margin-right: 0.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-3-md {
    margin-right: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-4-md {
    margin-right: 0.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-5-md {
    margin-right: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-6-md {
    margin-right: 0.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-7-md {
    margin-right: 0.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-8-md {
    margin-right: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-9-md {
    margin-right: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-10-md {
    margin-right: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-11-md {
    margin-right: 0.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-12-md {
    margin-right: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-13-md {
    margin-right: 0.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-14-md {
    margin-right: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-15-md {
    margin-right: 0.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-16-md {
    margin-right: 1rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-17-md {
    margin-right: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-18-md {
    margin-right: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-19-md {
    margin-right: 1.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-20-md {
    margin-right: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-21-md {
    margin-right: 1.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-22-md {
    margin-right: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-23-md {
    margin-right: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-24-md {
    margin-right: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-25-md {
    margin-right: 1.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-26-md {
    margin-right: 1.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-27-md {
    margin-right: 1.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-28-md {
    margin-right: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-29-md {
    margin-right: 1.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-30-md {
    margin-right: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-31-md {
    margin-right: 1.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-32-md {
    margin-right: 2rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-33-md {
    margin-right: 2.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-34-md {
    margin-right: 2.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-35-md {
    margin-right: 2.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-36-md {
    margin-right: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-37-md {
    margin-right: 2.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-38-md {
    margin-right: 2.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-39-md {
    margin-right: 2.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-40-md {
    margin-right: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-41-md {
    margin-right: 2.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-42-md {
    margin-right: 2.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-43-md {
    margin-right: 2.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-44-md {
    margin-right: 2.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-45-md {
    margin-right: 2.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-46-md {
    margin-right: 2.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-47-md {
    margin-right: 2.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-48-md {
    margin-right: 3rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-49-md {
    margin-right: 3.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-50-md {
    margin-right: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-51-md {
    margin-right: 3.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-52-md {
    margin-right: 3.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-53-md {
    margin-right: 3.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-54-md {
    margin-right: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-55-md {
    margin-right: 3.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-56-md {
    margin-right: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-57-md {
    margin-right: 3.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-58-md {
    margin-right: 3.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-59-md {
    margin-right: 3.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-60-md {
    margin-right: 3.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-61-md {
    margin-right: 3.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-62-md {
    margin-right: 3.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-63-md {
    margin-right: 3.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-64-md {
    margin-right: 4rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-65-md {
    margin-right: 4.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-66-md {
    margin-right: 4.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-67-md {
    margin-right: 4.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-68-md {
    margin-right: 4.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-69-md {
    margin-right: 4.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-70-md {
    margin-right: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-71-md {
    margin-right: 4.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-72-md {
    margin-right: 4.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-73-md {
    margin-right: 4.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-74-md {
    margin-right: 4.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-75-md {
    margin-right: 4.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-76-md {
    margin-right: 4.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-77-md {
    margin-right: 4.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-78-md {
    margin-right: 4.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-79-md {
    margin-right: 4.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-80-md {
    margin-right: 5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-81-md {
    margin-right: 5.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-82-md {
    margin-right: 5.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-83-md {
    margin-right: 5.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-84-md {
    margin-right: 5.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-85-md {
    margin-right: 5.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-86-md {
    margin-right: 5.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-87-md {
    margin-right: 5.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-88-md {
    margin-right: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-89-md {
    margin-right: 5.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-90-md {
    margin-right: 5.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-91-md {
    margin-right: 5.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-92-md {
    margin-right: 5.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-93-md {
    margin-right: 5.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-94-md {
    margin-right: 5.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-95-md {
    margin-right: 5.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-96-md {
    margin-right: 6rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-97-md {
    margin-right: 6.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-98-md {
    margin-right: 6.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-99-md {
    margin-right: 6.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-right-100-md {
    margin-right: 6.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-1-sm {
    margin-right: 0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-2-sm {
    margin-right: 0.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-3-sm {
    margin-right: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-4-sm {
    margin-right: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-5-sm {
    margin-right: 0.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-6-sm {
    margin-right: 0.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-7-sm {
    margin-right: 0.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-8-sm {
    margin-right: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-9-sm {
    margin-right: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-10-sm {
    margin-right: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-11-sm {
    margin-right: 0.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-12-sm {
    margin-right: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-13-sm {
    margin-right: 0.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-14-sm {
    margin-right: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-15-sm {
    margin-right: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-16-sm {
    margin-right: 1rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-17-sm {
    margin-right: 1.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-18-sm {
    margin-right: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-19-sm {
    margin-right: 1.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-20-sm {
    margin-right: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-21-sm {
    margin-right: 1.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-22-sm {
    margin-right: 1.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-23-sm {
    margin-right: 1.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-24-sm {
    margin-right: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-25-sm {
    margin-right: 1.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-26-sm {
    margin-right: 1.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-27-sm {
    margin-right: 1.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-28-sm {
    margin-right: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-29-sm {
    margin-right: 1.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-30-sm {
    margin-right: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-31-sm {
    margin-right: 1.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-32-sm {
    margin-right: 2rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-33-sm {
    margin-right: 2.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-34-sm {
    margin-right: 2.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-35-sm {
    margin-right: 2.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-36-sm {
    margin-right: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-37-sm {
    margin-right: 2.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-38-sm {
    margin-right: 2.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-39-sm {
    margin-right: 2.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-40-sm {
    margin-right: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-41-sm {
    margin-right: 2.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-42-sm {
    margin-right: 2.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-43-sm {
    margin-right: 2.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-44-sm {
    margin-right: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-45-sm {
    margin-right: 2.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-46-sm {
    margin-right: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-47-sm {
    margin-right: 2.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-48-sm {
    margin-right: 3rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-49-sm {
    margin-right: 3.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-50-sm {
    margin-right: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-51-sm {
    margin-right: 3.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-52-sm {
    margin-right: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-53-sm {
    margin-right: 3.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-54-sm {
    margin-right: 3.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-55-sm {
    margin-right: 3.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-56-sm {
    margin-right: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-57-sm {
    margin-right: 3.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-58-sm {
    margin-right: 3.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-59-sm {
    margin-right: 3.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-60-sm {
    margin-right: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-61-sm {
    margin-right: 3.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-62-sm {
    margin-right: 3.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-63-sm {
    margin-right: 3.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-64-sm {
    margin-right: 4rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-65-sm {
    margin-right: 4.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-66-sm {
    margin-right: 4.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-67-sm {
    margin-right: 4.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-68-sm {
    margin-right: 4.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-69-sm {
    margin-right: 4.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-70-sm {
    margin-right: 4.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-71-sm {
    margin-right: 4.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-72-sm {
    margin-right: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-73-sm {
    margin-right: 4.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-74-sm {
    margin-right: 4.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-75-sm {
    margin-right: 4.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-76-sm {
    margin-right: 4.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-77-sm {
    margin-right: 4.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-78-sm {
    margin-right: 4.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-79-sm {
    margin-right: 4.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-80-sm {
    margin-right: 5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-81-sm {
    margin-right: 5.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-82-sm {
    margin-right: 5.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-83-sm {
    margin-right: 5.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-84-sm {
    margin-right: 5.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-85-sm {
    margin-right: 5.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-86-sm {
    margin-right: 5.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-87-sm {
    margin-right: 5.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-88-sm {
    margin-right: 5.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-89-sm {
    margin-right: 5.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-90-sm {
    margin-right: 5.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-91-sm {
    margin-right: 5.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-92-sm {
    margin-right: 5.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-93-sm {
    margin-right: 5.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-94-sm {
    margin-right: 5.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-95-sm {
    margin-right: 5.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-96-sm {
    margin-right: 6rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-97-sm {
    margin-right: 6.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-98-sm {
    margin-right: 6.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-99-sm {
    margin-right: 6.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-right-100-sm {
    margin-right: 6.25rem;
  }
}
.margin-bottom-1 {
  margin-bottom: 0.0625rem;
}
.margin-bottom-2 {
  margin-bottom: 0.125rem;
}
.margin-bottom-3 {
  margin-bottom: 0.1875rem;
}
.margin-bottom-4 {
  margin-bottom: 0.25rem;
}
.margin-bottom-5 {
  margin-bottom: 0.3125rem;
}
.margin-bottom-6 {
  margin-bottom: 0.375rem;
}
.margin-bottom-7 {
  margin-bottom: 0.4375rem;
}
.margin-bottom-8 {
  margin-bottom: 0.5rem;
}
.margin-bottom-9 {
  margin-bottom: 0.5625rem;
}
.margin-bottom-10 {
  margin-bottom: 0.625rem;
}
.margin-bottom-11 {
  margin-bottom: 0.6875rem;
}
.margin-bottom-12 {
  margin-bottom: 0.75rem;
}
.margin-bottom-13 {
  margin-bottom: 0.8125rem;
}
.margin-bottom-14 {
  margin-bottom: 0.875rem;
}
.margin-bottom-15 {
  margin-bottom: 0.9375rem;
}
.margin-bottom-16 {
  margin-bottom: 1rem;
}
.margin-bottom-17 {
  margin-bottom: 1.0625rem;
}
.margin-bottom-18 {
  margin-bottom: 1.125rem;
}
.margin-bottom-19 {
  margin-bottom: 1.1875rem;
}
.margin-bottom-20 {
  margin-bottom: 1.25rem;
}
.margin-bottom-21 {
  margin-bottom: 1.3125rem;
}
.margin-bottom-22 {
  margin-bottom: 1.375rem;
}
.margin-bottom-23 {
  margin-bottom: 1.4375rem;
}
.margin-bottom-24 {
  margin-bottom: 1.5rem;
}
.margin-bottom-25 {
  margin-bottom: 1.5625rem;
}
.margin-bottom-26 {
  margin-bottom: 1.625rem;
}
.margin-bottom-27 {
  margin-bottom: 1.6875rem;
}
.margin-bottom-28 {
  margin-bottom: 1.75rem;
}
.margin-bottom-29 {
  margin-bottom: 1.8125rem;
}
.margin-bottom-30 {
  margin-bottom: 1.875rem;
}
.margin-bottom-31 {
  margin-bottom: 1.9375rem;
}
.margin-bottom-32 {
  margin-bottom: 2rem;
}
.margin-bottom-33 {
  margin-bottom: 2.0625rem;
}
.margin-bottom-34 {
  margin-bottom: 2.125rem;
}
.margin-bottom-35 {
  margin-bottom: 2.1875rem;
}
.margin-bottom-36 {
  margin-bottom: 2.25rem;
}
.margin-bottom-37 {
  margin-bottom: 2.3125rem;
}
.margin-bottom-38 {
  margin-bottom: 2.375rem;
}
.margin-bottom-39 {
  margin-bottom: 2.4375rem;
}
.margin-bottom-40 {
  margin-bottom: 2.5rem;
}
.margin-bottom-41 {
  margin-bottom: 2.5625rem;
}
.margin-bottom-42 {
  margin-bottom: 2.625rem;
}
.margin-bottom-43 {
  margin-bottom: 2.6875rem;
}
.margin-bottom-44 {
  margin-bottom: 2.75rem;
}
.margin-bottom-45 {
  margin-bottom: 2.8125rem;
}
.margin-bottom-46 {
  margin-bottom: 2.875rem;
}
.margin-bottom-47 {
  margin-bottom: 2.9375rem;
}
.margin-bottom-48 {
  margin-bottom: 3rem;
}
.margin-bottom-49 {
  margin-bottom: 3.0625rem;
}
.margin-bottom-50 {
  margin-bottom: 3.125rem;
}
.margin-bottom-51 {
  margin-bottom: 3.1875rem;
}
.margin-bottom-52 {
  margin-bottom: 3.25rem;
}
.margin-bottom-53 {
  margin-bottom: 3.3125rem;
}
.margin-bottom-54 {
  margin-bottom: 3.375rem;
}
.margin-bottom-55 {
  margin-bottom: 3.4375rem;
}
.margin-bottom-56 {
  margin-bottom: 3.5rem;
}
.margin-bottom-57 {
  margin-bottom: 3.5625rem;
}
.margin-bottom-58 {
  margin-bottom: 3.625rem;
}
.margin-bottom-59 {
  margin-bottom: 3.6875rem;
}
.margin-bottom-60 {
  margin-bottom: 3.75rem;
}
.margin-bottom-61 {
  margin-bottom: 3.8125rem;
}
.margin-bottom-62 {
  margin-bottom: 3.875rem;
}
.margin-bottom-63 {
  margin-bottom: 3.9375rem;
}
.margin-bottom-64 {
  margin-bottom: 4rem;
}
.margin-bottom-65 {
  margin-bottom: 4.0625rem;
}
.margin-bottom-66 {
  margin-bottom: 4.125rem;
}
.margin-bottom-67 {
  margin-bottom: 4.1875rem;
}
.margin-bottom-68 {
  margin-bottom: 4.25rem;
}
.margin-bottom-69 {
  margin-bottom: 4.3125rem;
}
.margin-bottom-70 {
  margin-bottom: 4.375rem;
}
.margin-bottom-71 {
  margin-bottom: 4.4375rem;
}
.margin-bottom-72 {
  margin-bottom: 4.5rem;
}
.margin-bottom-73 {
  margin-bottom: 4.5625rem;
}
.margin-bottom-74 {
  margin-bottom: 4.625rem;
}
.margin-bottom-75 {
  margin-bottom: 4.6875rem;
}
.margin-bottom-76 {
  margin-bottom: 4.75rem;
}
.margin-bottom-77 {
  margin-bottom: 4.8125rem;
}
.margin-bottom-78 {
  margin-bottom: 4.875rem;
}
.margin-bottom-79 {
  margin-bottom: 4.9375rem;
}
.margin-bottom-80 {
  margin-bottom: 5rem;
}
.margin-bottom-81 {
  margin-bottom: 5.0625rem;
}
.margin-bottom-82 {
  margin-bottom: 5.125rem;
}
.margin-bottom-83 {
  margin-bottom: 5.1875rem;
}
.margin-bottom-84 {
  margin-bottom: 5.25rem;
}
.margin-bottom-85 {
  margin-bottom: 5.3125rem;
}
.margin-bottom-86 {
  margin-bottom: 5.375rem;
}
.margin-bottom-87 {
  margin-bottom: 5.4375rem;
}
.margin-bottom-88 {
  margin-bottom: 5.5rem;
}
.margin-bottom-89 {
  margin-bottom: 5.5625rem;
}
.margin-bottom-90 {
  margin-bottom: 5.625rem;
}
.margin-bottom-91 {
  margin-bottom: 5.6875rem;
}
.margin-bottom-92 {
  margin-bottom: 5.75rem;
}
.margin-bottom-93 {
  margin-bottom: 5.8125rem;
}
.margin-bottom-94 {
  margin-bottom: 5.875rem;
}
.margin-bottom-95 {
  margin-bottom: 5.9375rem;
}
.margin-bottom-96 {
  margin-bottom: 6rem;
}
.margin-bottom-97 {
  margin-bottom: 6.0625rem;
}
.margin-bottom-98 {
  margin-bottom: 6.125rem;
}
.margin-bottom-99 {
  margin-bottom: 6.1875rem;
}
.margin-bottom-100 {
  margin-bottom: 6.25rem;
}
@media (max-width: 1199.98px) {
  .margin-bottom-1-lg {
    margin-bottom: 0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-2-lg {
    margin-bottom: 0.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-3-lg {
    margin-bottom: 0.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-4-lg {
    margin-bottom: 0.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-5-lg {
    margin-bottom: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-6-lg {
    margin-bottom: 0.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-7-lg {
    margin-bottom: 0.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-8-lg {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-9-lg {
    margin-bottom: 0.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-10-lg {
    margin-bottom: 0.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-11-lg {
    margin-bottom: 0.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-12-lg {
    margin-bottom: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-13-lg {
    margin-bottom: 0.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-14-lg {
    margin-bottom: 0.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-15-lg {
    margin-bottom: 0.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-16-lg {
    margin-bottom: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-17-lg {
    margin-bottom: 1.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-18-lg {
    margin-bottom: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-19-lg {
    margin-bottom: 1.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-20-lg {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-21-lg {
    margin-bottom: 1.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-22-lg {
    margin-bottom: 1.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-23-lg {
    margin-bottom: 1.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-24-lg {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-25-lg {
    margin-bottom: 1.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-26-lg {
    margin-bottom: 1.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-27-lg {
    margin-bottom: 1.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-28-lg {
    margin-bottom: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-29-lg {
    margin-bottom: 1.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-30-lg {
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-31-lg {
    margin-bottom: 1.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-32-lg {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-33-lg {
    margin-bottom: 2.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-34-lg {
    margin-bottom: 2.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-35-lg {
    margin-bottom: 2.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-36-lg {
    margin-bottom: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-37-lg {
    margin-bottom: 2.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-38-lg {
    margin-bottom: 2.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-39-lg {
    margin-bottom: 2.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-40-lg {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-41-lg {
    margin-bottom: 2.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-42-lg {
    margin-bottom: 2.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-43-lg {
    margin-bottom: 2.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-44-lg {
    margin-bottom: 2.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-45-lg {
    margin-bottom: 2.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-46-lg {
    margin-bottom: 2.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-47-lg {
    margin-bottom: 2.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-48-lg {
    margin-bottom: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-49-lg {
    margin-bottom: 3.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-50-lg {
    margin-bottom: 3.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-51-lg {
    margin-bottom: 3.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-52-lg {
    margin-bottom: 3.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-53-lg {
    margin-bottom: 3.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-54-lg {
    margin-bottom: 3.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-55-lg {
    margin-bottom: 3.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-56-lg {
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-57-lg {
    margin-bottom: 3.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-58-lg {
    margin-bottom: 3.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-59-lg {
    margin-bottom: 3.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-60-lg {
    margin-bottom: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-61-lg {
    margin-bottom: 3.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-62-lg {
    margin-bottom: 3.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-63-lg {
    margin-bottom: 3.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-64-lg {
    margin-bottom: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-65-lg {
    margin-bottom: 4.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-66-lg {
    margin-bottom: 4.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-67-lg {
    margin-bottom: 4.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-68-lg {
    margin-bottom: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-69-lg {
    margin-bottom: 4.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-70-lg {
    margin-bottom: 4.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-71-lg {
    margin-bottom: 4.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-72-lg {
    margin-bottom: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-73-lg {
    margin-bottom: 4.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-74-lg {
    margin-bottom: 4.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-75-lg {
    margin-bottom: 4.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-76-lg {
    margin-bottom: 4.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-77-lg {
    margin-bottom: 4.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-78-lg {
    margin-bottom: 4.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-79-lg {
    margin-bottom: 4.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-80-lg {
    margin-bottom: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-81-lg {
    margin-bottom: 5.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-82-lg {
    margin-bottom: 5.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-83-lg {
    margin-bottom: 5.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-84-lg {
    margin-bottom: 5.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-85-lg {
    margin-bottom: 5.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-86-lg {
    margin-bottom: 5.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-87-lg {
    margin-bottom: 5.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-88-lg {
    margin-bottom: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-89-lg {
    margin-bottom: 5.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-90-lg {
    margin-bottom: 5.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-91-lg {
    margin-bottom: 5.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-92-lg {
    margin-bottom: 5.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-93-lg {
    margin-bottom: 5.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-94-lg {
    margin-bottom: 5.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-95-lg {
    margin-bottom: 5.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-96-lg {
    margin-bottom: 6rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-97-lg {
    margin-bottom: 6.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-98-lg {
    margin-bottom: 6.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-99-lg {
    margin-bottom: 6.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-bottom-100-lg {
    margin-bottom: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-1-md {
    margin-bottom: 0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-2-md {
    margin-bottom: 0.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-3-md {
    margin-bottom: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-4-md {
    margin-bottom: 0.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-5-md {
    margin-bottom: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-6-md {
    margin-bottom: 0.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-7-md {
    margin-bottom: 0.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-8-md {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-9-md {
    margin-bottom: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-10-md {
    margin-bottom: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-11-md {
    margin-bottom: 0.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-12-md {
    margin-bottom: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-13-md {
    margin-bottom: 0.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-14-md {
    margin-bottom: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-15-md {
    margin-bottom: 0.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-16-md {
    margin-bottom: 1rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-17-md {
    margin-bottom: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-18-md {
    margin-bottom: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-19-md {
    margin-bottom: 1.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-20-md {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-21-md {
    margin-bottom: 1.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-22-md {
    margin-bottom: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-23-md {
    margin-bottom: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-24-md {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-25-md {
    margin-bottom: 1.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-26-md {
    margin-bottom: 1.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-27-md {
    margin-bottom: 1.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-28-md {
    margin-bottom: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-29-md {
    margin-bottom: 1.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-30-md {
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-31-md {
    margin-bottom: 1.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-32-md {
    margin-bottom: 2rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-33-md {
    margin-bottom: 2.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-34-md {
    margin-bottom: 2.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-35-md {
    margin-bottom: 2.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-36-md {
    margin-bottom: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-37-md {
    margin-bottom: 2.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-38-md {
    margin-bottom: 2.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-39-md {
    margin-bottom: 2.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-40-md {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-41-md {
    margin-bottom: 2.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-42-md {
    margin-bottom: 2.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-43-md {
    margin-bottom: 2.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-44-md {
    margin-bottom: 2.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-45-md {
    margin-bottom: 2.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-46-md {
    margin-bottom: 2.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-47-md {
    margin-bottom: 2.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-48-md {
    margin-bottom: 3rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-49-md {
    margin-bottom: 3.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-50-md {
    margin-bottom: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-51-md {
    margin-bottom: 3.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-52-md {
    margin-bottom: 3.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-53-md {
    margin-bottom: 3.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-54-md {
    margin-bottom: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-55-md {
    margin-bottom: 3.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-56-md {
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-57-md {
    margin-bottom: 3.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-58-md {
    margin-bottom: 3.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-59-md {
    margin-bottom: 3.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-60-md {
    margin-bottom: 3.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-61-md {
    margin-bottom: 3.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-62-md {
    margin-bottom: 3.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-63-md {
    margin-bottom: 3.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-64-md {
    margin-bottom: 4rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-65-md {
    margin-bottom: 4.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-66-md {
    margin-bottom: 4.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-67-md {
    margin-bottom: 4.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-68-md {
    margin-bottom: 4.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-69-md {
    margin-bottom: 4.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-70-md {
    margin-bottom: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-71-md {
    margin-bottom: 4.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-72-md {
    margin-bottom: 4.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-73-md {
    margin-bottom: 4.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-74-md {
    margin-bottom: 4.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-75-md {
    margin-bottom: 4.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-76-md {
    margin-bottom: 4.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-77-md {
    margin-bottom: 4.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-78-md {
    margin-bottom: 4.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-79-md {
    margin-bottom: 4.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-80-md {
    margin-bottom: 5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-81-md {
    margin-bottom: 5.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-82-md {
    margin-bottom: 5.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-83-md {
    margin-bottom: 5.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-84-md {
    margin-bottom: 5.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-85-md {
    margin-bottom: 5.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-86-md {
    margin-bottom: 5.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-87-md {
    margin-bottom: 5.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-88-md {
    margin-bottom: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-89-md {
    margin-bottom: 5.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-90-md {
    margin-bottom: 5.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-91-md {
    margin-bottom: 5.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-92-md {
    margin-bottom: 5.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-93-md {
    margin-bottom: 5.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-94-md {
    margin-bottom: 5.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-95-md {
    margin-bottom: 5.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-96-md {
    margin-bottom: 6rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-97-md {
    margin-bottom: 6.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-98-md {
    margin-bottom: 6.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-99-md {
    margin-bottom: 6.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-bottom-100-md {
    margin-bottom: 6.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-1-sm {
    margin-bottom: 0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-2-sm {
    margin-bottom: 0.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-3-sm {
    margin-bottom: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-4-sm {
    margin-bottom: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-5-sm {
    margin-bottom: 0.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-6-sm {
    margin-bottom: 0.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-7-sm {
    margin-bottom: 0.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-8-sm {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-9-sm {
    margin-bottom: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-10-sm {
    margin-bottom: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-11-sm {
    margin-bottom: 0.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-12-sm {
    margin-bottom: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-13-sm {
    margin-bottom: 0.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-14-sm {
    margin-bottom: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-15-sm {
    margin-bottom: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-16-sm {
    margin-bottom: 1rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-17-sm {
    margin-bottom: 1.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-18-sm {
    margin-bottom: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-19-sm {
    margin-bottom: 1.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-20-sm {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-21-sm {
    margin-bottom: 1.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-22-sm {
    margin-bottom: 1.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-23-sm {
    margin-bottom: 1.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-24-sm {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-25-sm {
    margin-bottom: 1.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-26-sm {
    margin-bottom: 1.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-27-sm {
    margin-bottom: 1.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-28-sm {
    margin-bottom: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-29-sm {
    margin-bottom: 1.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-30-sm {
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-31-sm {
    margin-bottom: 1.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-32-sm {
    margin-bottom: 2rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-33-sm {
    margin-bottom: 2.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-34-sm {
    margin-bottom: 2.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-35-sm {
    margin-bottom: 2.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-36-sm {
    margin-bottom: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-37-sm {
    margin-bottom: 2.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-38-sm {
    margin-bottom: 2.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-39-sm {
    margin-bottom: 2.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-40-sm {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-41-sm {
    margin-bottom: 2.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-42-sm {
    margin-bottom: 2.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-43-sm {
    margin-bottom: 2.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-44-sm {
    margin-bottom: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-45-sm {
    margin-bottom: 2.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-46-sm {
    margin-bottom: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-47-sm {
    margin-bottom: 2.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-48-sm {
    margin-bottom: 3rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-49-sm {
    margin-bottom: 3.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-50-sm {
    margin-bottom: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-51-sm {
    margin-bottom: 3.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-52-sm {
    margin-bottom: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-53-sm {
    margin-bottom: 3.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-54-sm {
    margin-bottom: 3.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-55-sm {
    margin-bottom: 3.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-56-sm {
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-57-sm {
    margin-bottom: 3.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-58-sm {
    margin-bottom: 3.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-59-sm {
    margin-bottom: 3.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-60-sm {
    margin-bottom: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-61-sm {
    margin-bottom: 3.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-62-sm {
    margin-bottom: 3.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-63-sm {
    margin-bottom: 3.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-64-sm {
    margin-bottom: 4rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-65-sm {
    margin-bottom: 4.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-66-sm {
    margin-bottom: 4.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-67-sm {
    margin-bottom: 4.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-68-sm {
    margin-bottom: 4.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-69-sm {
    margin-bottom: 4.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-70-sm {
    margin-bottom: 4.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-71-sm {
    margin-bottom: 4.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-72-sm {
    margin-bottom: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-73-sm {
    margin-bottom: 4.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-74-sm {
    margin-bottom: 4.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-75-sm {
    margin-bottom: 4.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-76-sm {
    margin-bottom: 4.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-77-sm {
    margin-bottom: 4.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-78-sm {
    margin-bottom: 4.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-79-sm {
    margin-bottom: 4.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-80-sm {
    margin-bottom: 5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-81-sm {
    margin-bottom: 5.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-82-sm {
    margin-bottom: 5.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-83-sm {
    margin-bottom: 5.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-84-sm {
    margin-bottom: 5.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-85-sm {
    margin-bottom: 5.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-86-sm {
    margin-bottom: 5.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-87-sm {
    margin-bottom: 5.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-88-sm {
    margin-bottom: 5.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-89-sm {
    margin-bottom: 5.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-90-sm {
    margin-bottom: 5.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-91-sm {
    margin-bottom: 5.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-92-sm {
    margin-bottom: 5.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-93-sm {
    margin-bottom: 5.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-94-sm {
    margin-bottom: 5.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-95-sm {
    margin-bottom: 5.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-96-sm {
    margin-bottom: 6rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-97-sm {
    margin-bottom: 6.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-98-sm {
    margin-bottom: 6.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-99-sm {
    margin-bottom: 6.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-bottom-100-sm {
    margin-bottom: 6.25rem;
  }
}
.margin-left-1 {
  margin-left: 0.0625rem;
}
.margin-left-2 {
  margin-left: 0.125rem;
}
.margin-left-3 {
  margin-left: 0.1875rem;
}
.margin-left-4 {
  margin-left: 0.25rem;
}
.margin-left-5 {
  margin-left: 0.3125rem;
}
.margin-left-6 {
  margin-left: 0.375rem;
}
.margin-left-7 {
  margin-left: 0.4375rem;
}
.margin-left-8 {
  margin-left: 0.5rem;
}
.margin-left-9 {
  margin-left: 0.5625rem;
}
.margin-left-10 {
  margin-left: 0.625rem;
}
.margin-left-11 {
  margin-left: 0.6875rem;
}
.margin-left-12 {
  margin-left: 0.75rem;
}
.margin-left-13 {
  margin-left: 0.8125rem;
}
.margin-left-14 {
  margin-left: 0.875rem;
}
.margin-left-15 {
  margin-left: 0.9375rem;
}
.margin-left-16 {
  margin-left: 1rem;
}
.margin-left-17 {
  margin-left: 1.0625rem;
}
.margin-left-18 {
  margin-left: 1.125rem;
}
.margin-left-19 {
  margin-left: 1.1875rem;
}
.margin-left-20 {
  margin-left: 1.25rem;
}
.margin-left-21 {
  margin-left: 1.3125rem;
}
.margin-left-22 {
  margin-left: 1.375rem;
}
.margin-left-23 {
  margin-left: 1.4375rem;
}
.margin-left-24 {
  margin-left: 1.5rem;
}
.margin-left-25 {
  margin-left: 1.5625rem;
}
.margin-left-26 {
  margin-left: 1.625rem;
}
.margin-left-27 {
  margin-left: 1.6875rem;
}
.margin-left-28 {
  margin-left: 1.75rem;
}
.margin-left-29 {
  margin-left: 1.8125rem;
}
.margin-left-30 {
  margin-left: 1.875rem;
}
.margin-left-31 {
  margin-left: 1.9375rem;
}
.margin-left-32 {
  margin-left: 2rem;
}
.margin-left-33 {
  margin-left: 2.0625rem;
}
.margin-left-34 {
  margin-left: 2.125rem;
}
.margin-left-35 {
  margin-left: 2.1875rem;
}
.margin-left-36 {
  margin-left: 2.25rem;
}
.margin-left-37 {
  margin-left: 2.3125rem;
}
.margin-left-38 {
  margin-left: 2.375rem;
}
.margin-left-39 {
  margin-left: 2.4375rem;
}
.margin-left-40 {
  margin-left: 2.5rem;
}
.margin-left-41 {
  margin-left: 2.5625rem;
}
.margin-left-42 {
  margin-left: 2.625rem;
}
.margin-left-43 {
  margin-left: 2.6875rem;
}
.margin-left-44 {
  margin-left: 2.75rem;
}
.margin-left-45 {
  margin-left: 2.8125rem;
}
.margin-left-46 {
  margin-left: 2.875rem;
}
.margin-left-47 {
  margin-left: 2.9375rem;
}
.margin-left-48 {
  margin-left: 3rem;
}
.margin-left-49 {
  margin-left: 3.0625rem;
}
.margin-left-50 {
  margin-left: 3.125rem;
}
.margin-left-51 {
  margin-left: 3.1875rem;
}
.margin-left-52 {
  margin-left: 3.25rem;
}
.margin-left-53 {
  margin-left: 3.3125rem;
}
.margin-left-54 {
  margin-left: 3.375rem;
}
.margin-left-55 {
  margin-left: 3.4375rem;
}
.margin-left-56 {
  margin-left: 3.5rem;
}
.margin-left-57 {
  margin-left: 3.5625rem;
}
.margin-left-58 {
  margin-left: 3.625rem;
}
.margin-left-59 {
  margin-left: 3.6875rem;
}
.margin-left-60 {
  margin-left: 3.75rem;
}
.margin-left-61 {
  margin-left: 3.8125rem;
}
.margin-left-62 {
  margin-left: 3.875rem;
}
.margin-left-63 {
  margin-left: 3.9375rem;
}
.margin-left-64 {
  margin-left: 4rem;
}
.margin-left-65 {
  margin-left: 4.0625rem;
}
.margin-left-66 {
  margin-left: 4.125rem;
}
.margin-left-67 {
  margin-left: 4.1875rem;
}
.margin-left-68 {
  margin-left: 4.25rem;
}
.margin-left-69 {
  margin-left: 4.3125rem;
}
.margin-left-70 {
  margin-left: 4.375rem;
}
.margin-left-71 {
  margin-left: 4.4375rem;
}
.margin-left-72 {
  margin-left: 4.5rem;
}
.margin-left-73 {
  margin-left: 4.5625rem;
}
.margin-left-74 {
  margin-left: 4.625rem;
}
.margin-left-75 {
  margin-left: 4.6875rem;
}
.margin-left-76 {
  margin-left: 4.75rem;
}
.margin-left-77 {
  margin-left: 4.8125rem;
}
.margin-left-78 {
  margin-left: 4.875rem;
}
.margin-left-79 {
  margin-left: 4.9375rem;
}
.margin-left-80 {
  margin-left: 5rem;
}
.margin-left-81 {
  margin-left: 5.0625rem;
}
.margin-left-82 {
  margin-left: 5.125rem;
}
.margin-left-83 {
  margin-left: 5.1875rem;
}
.margin-left-84 {
  margin-left: 5.25rem;
}
.margin-left-85 {
  margin-left: 5.3125rem;
}
.margin-left-86 {
  margin-left: 5.375rem;
}
.margin-left-87 {
  margin-left: 5.4375rem;
}
.margin-left-88 {
  margin-left: 5.5rem;
}
.margin-left-89 {
  margin-left: 5.5625rem;
}
.margin-left-90 {
  margin-left: 5.625rem;
}
.margin-left-91 {
  margin-left: 5.6875rem;
}
.margin-left-92 {
  margin-left: 5.75rem;
}
.margin-left-93 {
  margin-left: 5.8125rem;
}
.margin-left-94 {
  margin-left: 5.875rem;
}
.margin-left-95 {
  margin-left: 5.9375rem;
}
.margin-left-96 {
  margin-left: 6rem;
}
.margin-left-97 {
  margin-left: 6.0625rem;
}
.margin-left-98 {
  margin-left: 6.125rem;
}
.margin-left-99 {
  margin-left: 6.1875rem;
}
.margin-left-100 {
  margin-left: 6.25rem;
}
@media (max-width: 1199.98px) {
  .margin-left-1-lg {
    margin-left: 0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-2-lg {
    margin-left: 0.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-3-lg {
    margin-left: 0.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-4-lg {
    margin-left: 0.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-5-lg {
    margin-left: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-6-lg {
    margin-left: 0.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-7-lg {
    margin-left: 0.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-8-lg {
    margin-left: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-9-lg {
    margin-left: 0.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-10-lg {
    margin-left: 0.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-11-lg {
    margin-left: 0.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-12-lg {
    margin-left: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-13-lg {
    margin-left: 0.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-14-lg {
    margin-left: 0.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-15-lg {
    margin-left: 0.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-16-lg {
    margin-left: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-17-lg {
    margin-left: 1.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-18-lg {
    margin-left: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-19-lg {
    margin-left: 1.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-20-lg {
    margin-left: 1.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-21-lg {
    margin-left: 1.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-22-lg {
    margin-left: 1.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-23-lg {
    margin-left: 1.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-24-lg {
    margin-left: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-25-lg {
    margin-left: 1.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-26-lg {
    margin-left: 1.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-27-lg {
    margin-left: 1.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-28-lg {
    margin-left: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-29-lg {
    margin-left: 1.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-30-lg {
    margin-left: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-31-lg {
    margin-left: 1.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-32-lg {
    margin-left: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-33-lg {
    margin-left: 2.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-34-lg {
    margin-left: 2.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-35-lg {
    margin-left: 2.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-36-lg {
    margin-left: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-37-lg {
    margin-left: 2.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-38-lg {
    margin-left: 2.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-39-lg {
    margin-left: 2.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-40-lg {
    margin-left: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-41-lg {
    margin-left: 2.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-42-lg {
    margin-left: 2.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-43-lg {
    margin-left: 2.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-44-lg {
    margin-left: 2.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-45-lg {
    margin-left: 2.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-46-lg {
    margin-left: 2.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-47-lg {
    margin-left: 2.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-48-lg {
    margin-left: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-49-lg {
    margin-left: 3.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-50-lg {
    margin-left: 3.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-51-lg {
    margin-left: 3.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-52-lg {
    margin-left: 3.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-53-lg {
    margin-left: 3.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-54-lg {
    margin-left: 3.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-55-lg {
    margin-left: 3.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-56-lg {
    margin-left: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-57-lg {
    margin-left: 3.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-58-lg {
    margin-left: 3.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-59-lg {
    margin-left: 3.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-60-lg {
    margin-left: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-61-lg {
    margin-left: 3.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-62-lg {
    margin-left: 3.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-63-lg {
    margin-left: 3.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-64-lg {
    margin-left: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-65-lg {
    margin-left: 4.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-66-lg {
    margin-left: 4.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-67-lg {
    margin-left: 4.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-68-lg {
    margin-left: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-69-lg {
    margin-left: 4.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-70-lg {
    margin-left: 4.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-71-lg {
    margin-left: 4.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-72-lg {
    margin-left: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-73-lg {
    margin-left: 4.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-74-lg {
    margin-left: 4.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-75-lg {
    margin-left: 4.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-76-lg {
    margin-left: 4.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-77-lg {
    margin-left: 4.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-78-lg {
    margin-left: 4.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-79-lg {
    margin-left: 4.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-80-lg {
    margin-left: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-81-lg {
    margin-left: 5.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-82-lg {
    margin-left: 5.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-83-lg {
    margin-left: 5.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-84-lg {
    margin-left: 5.25rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-85-lg {
    margin-left: 5.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-86-lg {
    margin-left: 5.375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-87-lg {
    margin-left: 5.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-88-lg {
    margin-left: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-89-lg {
    margin-left: 5.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-90-lg {
    margin-left: 5.625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-91-lg {
    margin-left: 5.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-92-lg {
    margin-left: 5.75rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-93-lg {
    margin-left: 5.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-94-lg {
    margin-left: 5.875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-95-lg {
    margin-left: 5.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-96-lg {
    margin-left: 6rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-97-lg {
    margin-left: 6.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-98-lg {
    margin-left: 6.125rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-99-lg {
    margin-left: 6.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .margin-left-100-lg {
    margin-left: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-1-md {
    margin-left: 0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-2-md {
    margin-left: 0.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-3-md {
    margin-left: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-4-md {
    margin-left: 0.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-5-md {
    margin-left: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-6-md {
    margin-left: 0.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-7-md {
    margin-left: 0.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-8-md {
    margin-left: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-9-md {
    margin-left: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-10-md {
    margin-left: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-11-md {
    margin-left: 0.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-12-md {
    margin-left: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-13-md {
    margin-left: 0.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-14-md {
    margin-left: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-15-md {
    margin-left: 0.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-16-md {
    margin-left: 1rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-17-md {
    margin-left: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-18-md {
    margin-left: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-19-md {
    margin-left: 1.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-20-md {
    margin-left: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-21-md {
    margin-left: 1.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-22-md {
    margin-left: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-23-md {
    margin-left: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-24-md {
    margin-left: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-25-md {
    margin-left: 1.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-26-md {
    margin-left: 1.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-27-md {
    margin-left: 1.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-28-md {
    margin-left: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-29-md {
    margin-left: 1.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-30-md {
    margin-left: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-31-md {
    margin-left: 1.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-32-md {
    margin-left: 2rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-33-md {
    margin-left: 2.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-34-md {
    margin-left: 2.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-35-md {
    margin-left: 2.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-36-md {
    margin-left: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-37-md {
    margin-left: 2.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-38-md {
    margin-left: 2.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-39-md {
    margin-left: 2.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-40-md {
    margin-left: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-41-md {
    margin-left: 2.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-42-md {
    margin-left: 2.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-43-md {
    margin-left: 2.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-44-md {
    margin-left: 2.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-45-md {
    margin-left: 2.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-46-md {
    margin-left: 2.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-47-md {
    margin-left: 2.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-48-md {
    margin-left: 3rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-49-md {
    margin-left: 3.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-50-md {
    margin-left: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-51-md {
    margin-left: 3.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-52-md {
    margin-left: 3.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-53-md {
    margin-left: 3.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-54-md {
    margin-left: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-55-md {
    margin-left: 3.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-56-md {
    margin-left: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-57-md {
    margin-left: 3.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-58-md {
    margin-left: 3.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-59-md {
    margin-left: 3.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-60-md {
    margin-left: 3.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-61-md {
    margin-left: 3.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-62-md {
    margin-left: 3.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-63-md {
    margin-left: 3.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-64-md {
    margin-left: 4rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-65-md {
    margin-left: 4.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-66-md {
    margin-left: 4.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-67-md {
    margin-left: 4.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-68-md {
    margin-left: 4.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-69-md {
    margin-left: 4.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-70-md {
    margin-left: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-71-md {
    margin-left: 4.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-72-md {
    margin-left: 4.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-73-md {
    margin-left: 4.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-74-md {
    margin-left: 4.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-75-md {
    margin-left: 4.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-76-md {
    margin-left: 4.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-77-md {
    margin-left: 4.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-78-md {
    margin-left: 4.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-79-md {
    margin-left: 4.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-80-md {
    margin-left: 5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-81-md {
    margin-left: 5.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-82-md {
    margin-left: 5.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-83-md {
    margin-left: 5.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-84-md {
    margin-left: 5.25rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-85-md {
    margin-left: 5.3125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-86-md {
    margin-left: 5.375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-87-md {
    margin-left: 5.4375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-88-md {
    margin-left: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-89-md {
    margin-left: 5.5625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-90-md {
    margin-left: 5.625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-91-md {
    margin-left: 5.6875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-92-md {
    margin-left: 5.75rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-93-md {
    margin-left: 5.8125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-94-md {
    margin-left: 5.875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-95-md {
    margin-left: 5.9375rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-96-md {
    margin-left: 6rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-97-md {
    margin-left: 6.0625rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-98-md {
    margin-left: 6.125rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-99-md {
    margin-left: 6.1875rem;
  }
}
@media (max-width: 991.98px) {
  .margin-left-100-md {
    margin-left: 6.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-1-sm {
    margin-left: 0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-2-sm {
    margin-left: 0.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-3-sm {
    margin-left: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-4-sm {
    margin-left: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-5-sm {
    margin-left: 0.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-6-sm {
    margin-left: 0.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-7-sm {
    margin-left: 0.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-8-sm {
    margin-left: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-9-sm {
    margin-left: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-10-sm {
    margin-left: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-11-sm {
    margin-left: 0.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-12-sm {
    margin-left: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-13-sm {
    margin-left: 0.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-14-sm {
    margin-left: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-15-sm {
    margin-left: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-16-sm {
    margin-left: 1rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-17-sm {
    margin-left: 1.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-18-sm {
    margin-left: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-19-sm {
    margin-left: 1.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-20-sm {
    margin-left: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-21-sm {
    margin-left: 1.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-22-sm {
    margin-left: 1.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-23-sm {
    margin-left: 1.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-24-sm {
    margin-left: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-25-sm {
    margin-left: 1.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-26-sm {
    margin-left: 1.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-27-sm {
    margin-left: 1.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-28-sm {
    margin-left: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-29-sm {
    margin-left: 1.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-30-sm {
    margin-left: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-31-sm {
    margin-left: 1.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-32-sm {
    margin-left: 2rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-33-sm {
    margin-left: 2.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-34-sm {
    margin-left: 2.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-35-sm {
    margin-left: 2.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-36-sm {
    margin-left: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-37-sm {
    margin-left: 2.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-38-sm {
    margin-left: 2.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-39-sm {
    margin-left: 2.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-40-sm {
    margin-left: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-41-sm {
    margin-left: 2.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-42-sm {
    margin-left: 2.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-43-sm {
    margin-left: 2.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-44-sm {
    margin-left: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-45-sm {
    margin-left: 2.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-46-sm {
    margin-left: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-47-sm {
    margin-left: 2.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-48-sm {
    margin-left: 3rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-49-sm {
    margin-left: 3.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-50-sm {
    margin-left: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-51-sm {
    margin-left: 3.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-52-sm {
    margin-left: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-53-sm {
    margin-left: 3.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-54-sm {
    margin-left: 3.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-55-sm {
    margin-left: 3.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-56-sm {
    margin-left: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-57-sm {
    margin-left: 3.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-58-sm {
    margin-left: 3.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-59-sm {
    margin-left: 3.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-60-sm {
    margin-left: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-61-sm {
    margin-left: 3.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-62-sm {
    margin-left: 3.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-63-sm {
    margin-left: 3.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-64-sm {
    margin-left: 4rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-65-sm {
    margin-left: 4.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-66-sm {
    margin-left: 4.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-67-sm {
    margin-left: 4.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-68-sm {
    margin-left: 4.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-69-sm {
    margin-left: 4.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-70-sm {
    margin-left: 4.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-71-sm {
    margin-left: 4.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-72-sm {
    margin-left: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-73-sm {
    margin-left: 4.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-74-sm {
    margin-left: 4.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-75-sm {
    margin-left: 4.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-76-sm {
    margin-left: 4.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-77-sm {
    margin-left: 4.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-78-sm {
    margin-left: 4.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-79-sm {
    margin-left: 4.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-80-sm {
    margin-left: 5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-81-sm {
    margin-left: 5.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-82-sm {
    margin-left: 5.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-83-sm {
    margin-left: 5.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-84-sm {
    margin-left: 5.25rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-85-sm {
    margin-left: 5.3125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-86-sm {
    margin-left: 5.375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-87-sm {
    margin-left: 5.4375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-88-sm {
    margin-left: 5.5rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-89-sm {
    margin-left: 5.5625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-90-sm {
    margin-left: 5.625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-91-sm {
    margin-left: 5.6875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-92-sm {
    margin-left: 5.75rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-93-sm {
    margin-left: 5.8125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-94-sm {
    margin-left: 5.875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-95-sm {
    margin-left: 5.9375rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-96-sm {
    margin-left: 6rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-97-sm {
    margin-left: 6.0625rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-98-sm {
    margin-left: 6.125rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-99-sm {
    margin-left: 6.1875rem;
  }
}
@media (max-width: 767.98px) {
  .margin-left-100-sm {
    margin-left: 6.25rem;
  }
}

.padding-top-1 {
  padding-top: 0.0625rem;
}
.padding-top-2 {
  padding-top: 0.125rem;
}
.padding-top-3 {
  padding-top: 0.1875rem;
}
.padding-top-4 {
  padding-top: 0.25rem;
}
.padding-top-5 {
  padding-top: 0.3125rem;
}
.padding-top-6 {
  padding-top: 0.375rem;
}
.padding-top-7 {
  padding-top: 0.4375rem;
}
.padding-top-8 {
  padding-top: 0.5rem;
}
.padding-top-9 {
  padding-top: 0.5625rem;
}
.padding-top-10 {
  padding-top: 0.625rem;
}
.padding-top-11 {
  padding-top: 0.6875rem;
}
.padding-top-12 {
  padding-top: 0.75rem;
}
.padding-top-13 {
  padding-top: 0.8125rem;
}
.padding-top-14 {
  padding-top: 0.875rem;
}
.padding-top-15 {
  padding-top: 0.9375rem;
}
.padding-top-16 {
  padding-top: 1rem;
}
.padding-top-17 {
  padding-top: 1.0625rem;
}
.padding-top-18 {
  padding-top: 1.125rem;
}
.padding-top-19 {
  padding-top: 1.1875rem;
}
.padding-top-20 {
  padding-top: 1.25rem;
}
.padding-top-21 {
  padding-top: 1.3125rem;
}
.padding-top-22 {
  padding-top: 1.375rem;
}
.padding-top-23 {
  padding-top: 1.4375rem;
}
.padding-top-24 {
  padding-top: 1.5rem;
}
.padding-top-25 {
  padding-top: 1.5625rem;
}
.padding-top-26 {
  padding-top: 1.625rem;
}
.padding-top-27 {
  padding-top: 1.6875rem;
}
.padding-top-28 {
  padding-top: 1.75rem;
}
.padding-top-29 {
  padding-top: 1.8125rem;
}
.padding-top-30 {
  padding-top: 1.875rem;
}
.padding-top-31 {
  padding-top: 1.9375rem;
}
.padding-top-32 {
  padding-top: 2rem;
}
.padding-top-33 {
  padding-top: 2.0625rem;
}
.padding-top-34 {
  padding-top: 2.125rem;
}
.padding-top-35 {
  padding-top: 2.1875rem;
}
.padding-top-36 {
  padding-top: 2.25rem;
}
.padding-top-37 {
  padding-top: 2.3125rem;
}
.padding-top-38 {
  padding-top: 2.375rem;
}
.padding-top-39 {
  padding-top: 2.4375rem;
}
.padding-top-40 {
  padding-top: 2.5rem;
}
.padding-top-41 {
  padding-top: 2.5625rem;
}
.padding-top-42 {
  padding-top: 2.625rem;
}
.padding-top-43 {
  padding-top: 2.6875rem;
}
.padding-top-44 {
  padding-top: 2.75rem;
}
.padding-top-45 {
  padding-top: 2.8125rem;
}
.padding-top-46 {
  padding-top: 2.875rem;
}
.padding-top-47 {
  padding-top: 2.9375rem;
}
.padding-top-48 {
  padding-top: 3rem;
}
.padding-top-49 {
  padding-top: 3.0625rem;
}
.padding-top-50 {
  padding-top: 3.125rem;
}
.padding-top-51 {
  padding-top: 3.1875rem;
}
.padding-top-52 {
  padding-top: 3.25rem;
}
.padding-top-53 {
  padding-top: 3.3125rem;
}
.padding-top-54 {
  padding-top: 3.375rem;
}
.padding-top-55 {
  padding-top: 3.4375rem;
}
.padding-top-56 {
  padding-top: 3.5rem;
}
.padding-top-57 {
  padding-top: 3.5625rem;
}
.padding-top-58 {
  padding-top: 3.625rem;
}
.padding-top-59 {
  padding-top: 3.6875rem;
}
.padding-top-60 {
  padding-top: 3.75rem;
}
.padding-top-61 {
  padding-top: 3.8125rem;
}
.padding-top-62 {
  padding-top: 3.875rem;
}
.padding-top-63 {
  padding-top: 3.9375rem;
}
.padding-top-64 {
  padding-top: 4rem;
}
.padding-top-65 {
  padding-top: 4.0625rem;
}
.padding-top-66 {
  padding-top: 4.125rem;
}
.padding-top-67 {
  padding-top: 4.1875rem;
}
.padding-top-68 {
  padding-top: 4.25rem;
}
.padding-top-69 {
  padding-top: 4.3125rem;
}
.padding-top-70 {
  padding-top: 4.375rem;
}
.padding-top-71 {
  padding-top: 4.4375rem;
}
.padding-top-72 {
  padding-top: 4.5rem;
}
.padding-top-73 {
  padding-top: 4.5625rem;
}
.padding-top-74 {
  padding-top: 4.625rem;
}
.padding-top-75 {
  padding-top: 4.6875rem;
}
.padding-top-76 {
  padding-top: 4.75rem;
}
.padding-top-77 {
  padding-top: 4.8125rem;
}
.padding-top-78 {
  padding-top: 4.875rem;
}
.padding-top-79 {
  padding-top: 4.9375rem;
}
.padding-top-80 {
  padding-top: 5rem;
}
.padding-top-81 {
  padding-top: 5.0625rem;
}
.padding-top-82 {
  padding-top: 5.125rem;
}
.padding-top-83 {
  padding-top: 5.1875rem;
}
.padding-top-84 {
  padding-top: 5.25rem;
}
.padding-top-85 {
  padding-top: 5.3125rem;
}
.padding-top-86 {
  padding-top: 5.375rem;
}
.padding-top-87 {
  padding-top: 5.4375rem;
}
.padding-top-88 {
  padding-top: 5.5rem;
}
.padding-top-89 {
  padding-top: 5.5625rem;
}
.padding-top-90 {
  padding-top: 5.625rem;
}
.padding-top-91 {
  padding-top: 5.6875rem;
}
.padding-top-92 {
  padding-top: 5.75rem;
}
.padding-top-93 {
  padding-top: 5.8125rem;
}
.padding-top-94 {
  padding-top: 5.875rem;
}
.padding-top-95 {
  padding-top: 5.9375rem;
}
.padding-top-96 {
  padding-top: 6rem;
}
.padding-top-97 {
  padding-top: 6.0625rem;
}
.padding-top-98 {
  padding-top: 6.125rem;
}
.padding-top-99 {
  padding-top: 6.1875rem;
}
.padding-top-100 {
  padding-top: 6.25rem;
}
@media (max-width: 1199.98px) {
  .padding-top-1-lg {
    padding-top: 0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-2-lg {
    padding-top: 0.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-3-lg {
    padding-top: 0.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-4-lg {
    padding-top: 0.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-5-lg {
    padding-top: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-6-lg {
    padding-top: 0.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-7-lg {
    padding-top: 0.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-8-lg {
    padding-top: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-9-lg {
    padding-top: 0.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-10-lg {
    padding-top: 0.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-11-lg {
    padding-top: 0.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-12-lg {
    padding-top: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-13-lg {
    padding-top: 0.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-14-lg {
    padding-top: 0.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-15-lg {
    padding-top: 0.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-16-lg {
    padding-top: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-17-lg {
    padding-top: 1.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-18-lg {
    padding-top: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-19-lg {
    padding-top: 1.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-20-lg {
    padding-top: 1.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-21-lg {
    padding-top: 1.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-22-lg {
    padding-top: 1.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-23-lg {
    padding-top: 1.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-24-lg {
    padding-top: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-25-lg {
    padding-top: 1.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-26-lg {
    padding-top: 1.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-27-lg {
    padding-top: 1.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-28-lg {
    padding-top: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-29-lg {
    padding-top: 1.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-30-lg {
    padding-top: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-31-lg {
    padding-top: 1.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-32-lg {
    padding-top: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-33-lg {
    padding-top: 2.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-34-lg {
    padding-top: 2.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-35-lg {
    padding-top: 2.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-36-lg {
    padding-top: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-37-lg {
    padding-top: 2.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-38-lg {
    padding-top: 2.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-39-lg {
    padding-top: 2.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-40-lg {
    padding-top: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-41-lg {
    padding-top: 2.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-42-lg {
    padding-top: 2.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-43-lg {
    padding-top: 2.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-44-lg {
    padding-top: 2.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-45-lg {
    padding-top: 2.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-46-lg {
    padding-top: 2.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-47-lg {
    padding-top: 2.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-48-lg {
    padding-top: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-49-lg {
    padding-top: 3.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-50-lg {
    padding-top: 3.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-51-lg {
    padding-top: 3.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-52-lg {
    padding-top: 3.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-53-lg {
    padding-top: 3.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-54-lg {
    padding-top: 3.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-55-lg {
    padding-top: 3.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-56-lg {
    padding-top: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-57-lg {
    padding-top: 3.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-58-lg {
    padding-top: 3.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-59-lg {
    padding-top: 3.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-60-lg {
    padding-top: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-61-lg {
    padding-top: 3.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-62-lg {
    padding-top: 3.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-63-lg {
    padding-top: 3.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-64-lg {
    padding-top: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-65-lg {
    padding-top: 4.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-66-lg {
    padding-top: 4.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-67-lg {
    padding-top: 4.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-68-lg {
    padding-top: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-69-lg {
    padding-top: 4.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-70-lg {
    padding-top: 4.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-71-lg {
    padding-top: 4.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-72-lg {
    padding-top: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-73-lg {
    padding-top: 4.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-74-lg {
    padding-top: 4.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-75-lg {
    padding-top: 4.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-76-lg {
    padding-top: 4.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-77-lg {
    padding-top: 4.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-78-lg {
    padding-top: 4.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-79-lg {
    padding-top: 4.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-80-lg {
    padding-top: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-81-lg {
    padding-top: 5.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-82-lg {
    padding-top: 5.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-83-lg {
    padding-top: 5.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-84-lg {
    padding-top: 5.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-85-lg {
    padding-top: 5.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-86-lg {
    padding-top: 5.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-87-lg {
    padding-top: 5.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-88-lg {
    padding-top: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-89-lg {
    padding-top: 5.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-90-lg {
    padding-top: 5.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-91-lg {
    padding-top: 5.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-92-lg {
    padding-top: 5.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-93-lg {
    padding-top: 5.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-94-lg {
    padding-top: 5.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-95-lg {
    padding-top: 5.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-96-lg {
    padding-top: 6rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-97-lg {
    padding-top: 6.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-98-lg {
    padding-top: 6.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-99-lg {
    padding-top: 6.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-top-100-lg {
    padding-top: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-1-md {
    padding-top: 0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-2-md {
    padding-top: 0.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-3-md {
    padding-top: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-4-md {
    padding-top: 0.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-5-md {
    padding-top: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-6-md {
    padding-top: 0.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-7-md {
    padding-top: 0.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-8-md {
    padding-top: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-9-md {
    padding-top: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-10-md {
    padding-top: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-11-md {
    padding-top: 0.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-12-md {
    padding-top: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-13-md {
    padding-top: 0.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-14-md {
    padding-top: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-15-md {
    padding-top: 0.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-16-md {
    padding-top: 1rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-17-md {
    padding-top: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-18-md {
    padding-top: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-19-md {
    padding-top: 1.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-20-md {
    padding-top: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-21-md {
    padding-top: 1.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-22-md {
    padding-top: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-23-md {
    padding-top: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-24-md {
    padding-top: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-25-md {
    padding-top: 1.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-26-md {
    padding-top: 1.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-27-md {
    padding-top: 1.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-28-md {
    padding-top: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-29-md {
    padding-top: 1.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-30-md {
    padding-top: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-31-md {
    padding-top: 1.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-32-md {
    padding-top: 2rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-33-md {
    padding-top: 2.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-34-md {
    padding-top: 2.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-35-md {
    padding-top: 2.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-36-md {
    padding-top: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-37-md {
    padding-top: 2.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-38-md {
    padding-top: 2.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-39-md {
    padding-top: 2.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-40-md {
    padding-top: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-41-md {
    padding-top: 2.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-42-md {
    padding-top: 2.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-43-md {
    padding-top: 2.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-44-md {
    padding-top: 2.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-45-md {
    padding-top: 2.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-46-md {
    padding-top: 2.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-47-md {
    padding-top: 2.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-48-md {
    padding-top: 3rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-49-md {
    padding-top: 3.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-50-md {
    padding-top: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-51-md {
    padding-top: 3.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-52-md {
    padding-top: 3.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-53-md {
    padding-top: 3.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-54-md {
    padding-top: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-55-md {
    padding-top: 3.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-56-md {
    padding-top: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-57-md {
    padding-top: 3.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-58-md {
    padding-top: 3.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-59-md {
    padding-top: 3.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-60-md {
    padding-top: 3.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-61-md {
    padding-top: 3.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-62-md {
    padding-top: 3.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-63-md {
    padding-top: 3.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-64-md {
    padding-top: 4rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-65-md {
    padding-top: 4.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-66-md {
    padding-top: 4.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-67-md {
    padding-top: 4.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-68-md {
    padding-top: 4.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-69-md {
    padding-top: 4.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-70-md {
    padding-top: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-71-md {
    padding-top: 4.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-72-md {
    padding-top: 4.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-73-md {
    padding-top: 4.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-74-md {
    padding-top: 4.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-75-md {
    padding-top: 4.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-76-md {
    padding-top: 4.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-77-md {
    padding-top: 4.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-78-md {
    padding-top: 4.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-79-md {
    padding-top: 4.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-80-md {
    padding-top: 5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-81-md {
    padding-top: 5.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-82-md {
    padding-top: 5.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-83-md {
    padding-top: 5.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-84-md {
    padding-top: 5.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-85-md {
    padding-top: 5.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-86-md {
    padding-top: 5.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-87-md {
    padding-top: 5.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-88-md {
    padding-top: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-89-md {
    padding-top: 5.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-90-md {
    padding-top: 5.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-91-md {
    padding-top: 5.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-92-md {
    padding-top: 5.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-93-md {
    padding-top: 5.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-94-md {
    padding-top: 5.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-95-md {
    padding-top: 5.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-96-md {
    padding-top: 6rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-97-md {
    padding-top: 6.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-98-md {
    padding-top: 6.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-99-md {
    padding-top: 6.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-top-100-md {
    padding-top: 6.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-1-sm {
    padding-top: 0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-2-sm {
    padding-top: 0.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-3-sm {
    padding-top: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-4-sm {
    padding-top: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-5-sm {
    padding-top: 0.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-6-sm {
    padding-top: 0.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-7-sm {
    padding-top: 0.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-8-sm {
    padding-top: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-9-sm {
    padding-top: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-10-sm {
    padding-top: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-11-sm {
    padding-top: 0.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-12-sm {
    padding-top: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-13-sm {
    padding-top: 0.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-14-sm {
    padding-top: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-15-sm {
    padding-top: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-16-sm {
    padding-top: 1rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-17-sm {
    padding-top: 1.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-18-sm {
    padding-top: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-19-sm {
    padding-top: 1.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-20-sm {
    padding-top: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-21-sm {
    padding-top: 1.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-22-sm {
    padding-top: 1.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-23-sm {
    padding-top: 1.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-24-sm {
    padding-top: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-25-sm {
    padding-top: 1.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-26-sm {
    padding-top: 1.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-27-sm {
    padding-top: 1.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-28-sm {
    padding-top: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-29-sm {
    padding-top: 1.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-30-sm {
    padding-top: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-31-sm {
    padding-top: 1.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-32-sm {
    padding-top: 2rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-33-sm {
    padding-top: 2.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-34-sm {
    padding-top: 2.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-35-sm {
    padding-top: 2.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-36-sm {
    padding-top: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-37-sm {
    padding-top: 2.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-38-sm {
    padding-top: 2.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-39-sm {
    padding-top: 2.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-40-sm {
    padding-top: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-41-sm {
    padding-top: 2.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-42-sm {
    padding-top: 2.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-43-sm {
    padding-top: 2.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-44-sm {
    padding-top: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-45-sm {
    padding-top: 2.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-46-sm {
    padding-top: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-47-sm {
    padding-top: 2.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-48-sm {
    padding-top: 3rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-49-sm {
    padding-top: 3.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-50-sm {
    padding-top: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-51-sm {
    padding-top: 3.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-52-sm {
    padding-top: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-53-sm {
    padding-top: 3.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-54-sm {
    padding-top: 3.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-55-sm {
    padding-top: 3.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-56-sm {
    padding-top: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-57-sm {
    padding-top: 3.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-58-sm {
    padding-top: 3.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-59-sm {
    padding-top: 3.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-60-sm {
    padding-top: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-61-sm {
    padding-top: 3.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-62-sm {
    padding-top: 3.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-63-sm {
    padding-top: 3.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-64-sm {
    padding-top: 4rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-65-sm {
    padding-top: 4.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-66-sm {
    padding-top: 4.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-67-sm {
    padding-top: 4.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-68-sm {
    padding-top: 4.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-69-sm {
    padding-top: 4.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-70-sm {
    padding-top: 4.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-71-sm {
    padding-top: 4.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-72-sm {
    padding-top: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-73-sm {
    padding-top: 4.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-74-sm {
    padding-top: 4.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-75-sm {
    padding-top: 4.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-76-sm {
    padding-top: 4.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-77-sm {
    padding-top: 4.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-78-sm {
    padding-top: 4.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-79-sm {
    padding-top: 4.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-80-sm {
    padding-top: 5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-81-sm {
    padding-top: 5.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-82-sm {
    padding-top: 5.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-83-sm {
    padding-top: 5.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-84-sm {
    padding-top: 5.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-85-sm {
    padding-top: 5.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-86-sm {
    padding-top: 5.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-87-sm {
    padding-top: 5.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-88-sm {
    padding-top: 5.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-89-sm {
    padding-top: 5.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-90-sm {
    padding-top: 5.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-91-sm {
    padding-top: 5.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-92-sm {
    padding-top: 5.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-93-sm {
    padding-top: 5.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-94-sm {
    padding-top: 5.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-95-sm {
    padding-top: 5.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-96-sm {
    padding-top: 6rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-97-sm {
    padding-top: 6.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-98-sm {
    padding-top: 6.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-99-sm {
    padding-top: 6.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-top-100-sm {
    padding-top: 6.25rem;
  }
}
.padding-right-1 {
  padding-right: 0.0625rem;
}
.padding-right-2 {
  padding-right: 0.125rem;
}
.padding-right-3 {
  padding-right: 0.1875rem;
}
.padding-right-4 {
  padding-right: 0.25rem;
}
.padding-right-5 {
  padding-right: 0.3125rem;
}
.padding-right-6 {
  padding-right: 0.375rem;
}
.padding-right-7 {
  padding-right: 0.4375rem;
}
.padding-right-8 {
  padding-right: 0.5rem;
}
.padding-right-9 {
  padding-right: 0.5625rem;
}
.padding-right-10 {
  padding-right: 0.625rem;
}
.padding-right-11 {
  padding-right: 0.6875rem;
}
.padding-right-12 {
  padding-right: 0.75rem;
}
.padding-right-13 {
  padding-right: 0.8125rem;
}
.padding-right-14 {
  padding-right: 0.875rem;
}
.padding-right-15 {
  padding-right: 0.9375rem;
}
.padding-right-16 {
  padding-right: 1rem;
}
.padding-right-17 {
  padding-right: 1.0625rem;
}
.padding-right-18 {
  padding-right: 1.125rem;
}
.padding-right-19 {
  padding-right: 1.1875rem;
}
.padding-right-20 {
  padding-right: 1.25rem;
}
.padding-right-21 {
  padding-right: 1.3125rem;
}
.padding-right-22 {
  padding-right: 1.375rem;
}
.padding-right-23 {
  padding-right: 1.4375rem;
}
.padding-right-24 {
  padding-right: 1.5rem;
}
.padding-right-25 {
  padding-right: 1.5625rem;
}
.padding-right-26 {
  padding-right: 1.625rem;
}
.padding-right-27 {
  padding-right: 1.6875rem;
}
.padding-right-28 {
  padding-right: 1.75rem;
}
.padding-right-29 {
  padding-right: 1.8125rem;
}
.padding-right-30 {
  padding-right: 1.875rem;
}
.padding-right-31 {
  padding-right: 1.9375rem;
}
.padding-right-32 {
  padding-right: 2rem;
}
.padding-right-33 {
  padding-right: 2.0625rem;
}
.padding-right-34 {
  padding-right: 2.125rem;
}
.padding-right-35 {
  padding-right: 2.1875rem;
}
.padding-right-36 {
  padding-right: 2.25rem;
}
.padding-right-37 {
  padding-right: 2.3125rem;
}
.padding-right-38 {
  padding-right: 2.375rem;
}
.padding-right-39 {
  padding-right: 2.4375rem;
}
.padding-right-40 {
  padding-right: 2.5rem;
}
.padding-right-41 {
  padding-right: 2.5625rem;
}
.padding-right-42 {
  padding-right: 2.625rem;
}
.padding-right-43 {
  padding-right: 2.6875rem;
}
.padding-right-44 {
  padding-right: 2.75rem;
}
.padding-right-45 {
  padding-right: 2.8125rem;
}
.padding-right-46 {
  padding-right: 2.875rem;
}
.padding-right-47 {
  padding-right: 2.9375rem;
}
.padding-right-48 {
  padding-right: 3rem;
}
.padding-right-49 {
  padding-right: 3.0625rem;
}
.padding-right-50 {
  padding-right: 3.125rem;
}
.padding-right-51 {
  padding-right: 3.1875rem;
}
.padding-right-52 {
  padding-right: 3.25rem;
}
.padding-right-53 {
  padding-right: 3.3125rem;
}
.padding-right-54 {
  padding-right: 3.375rem;
}
.padding-right-55 {
  padding-right: 3.4375rem;
}
.padding-right-56 {
  padding-right: 3.5rem;
}
.padding-right-57 {
  padding-right: 3.5625rem;
}
.padding-right-58 {
  padding-right: 3.625rem;
}
.padding-right-59 {
  padding-right: 3.6875rem;
}
.padding-right-60 {
  padding-right: 3.75rem;
}
.padding-right-61 {
  padding-right: 3.8125rem;
}
.padding-right-62 {
  padding-right: 3.875rem;
}
.padding-right-63 {
  padding-right: 3.9375rem;
}
.padding-right-64 {
  padding-right: 4rem;
}
.padding-right-65 {
  padding-right: 4.0625rem;
}
.padding-right-66 {
  padding-right: 4.125rem;
}
.padding-right-67 {
  padding-right: 4.1875rem;
}
.padding-right-68 {
  padding-right: 4.25rem;
}
.padding-right-69 {
  padding-right: 4.3125rem;
}
.padding-right-70 {
  padding-right: 4.375rem;
}
.padding-right-71 {
  padding-right: 4.4375rem;
}
.padding-right-72 {
  padding-right: 4.5rem;
}
.padding-right-73 {
  padding-right: 4.5625rem;
}
.padding-right-74 {
  padding-right: 4.625rem;
}
.padding-right-75 {
  padding-right: 4.6875rem;
}
.padding-right-76 {
  padding-right: 4.75rem;
}
.padding-right-77 {
  padding-right: 4.8125rem;
}
.padding-right-78 {
  padding-right: 4.875rem;
}
.padding-right-79 {
  padding-right: 4.9375rem;
}
.padding-right-80 {
  padding-right: 5rem;
}
.padding-right-81 {
  padding-right: 5.0625rem;
}
.padding-right-82 {
  padding-right: 5.125rem;
}
.padding-right-83 {
  padding-right: 5.1875rem;
}
.padding-right-84 {
  padding-right: 5.25rem;
}
.padding-right-85 {
  padding-right: 5.3125rem;
}
.padding-right-86 {
  padding-right: 5.375rem;
}
.padding-right-87 {
  padding-right: 5.4375rem;
}
.padding-right-88 {
  padding-right: 5.5rem;
}
.padding-right-89 {
  padding-right: 5.5625rem;
}
.padding-right-90 {
  padding-right: 5.625rem;
}
.padding-right-91 {
  padding-right: 5.6875rem;
}
.padding-right-92 {
  padding-right: 5.75rem;
}
.padding-right-93 {
  padding-right: 5.8125rem;
}
.padding-right-94 {
  padding-right: 5.875rem;
}
.padding-right-95 {
  padding-right: 5.9375rem;
}
.padding-right-96 {
  padding-right: 6rem;
}
.padding-right-97 {
  padding-right: 6.0625rem;
}
.padding-right-98 {
  padding-right: 6.125rem;
}
.padding-right-99 {
  padding-right: 6.1875rem;
}
.padding-right-100 {
  padding-right: 6.25rem;
}
@media (max-width: 1199.98px) {
  .padding-right-1-lg {
    padding-right: 0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-2-lg {
    padding-right: 0.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-3-lg {
    padding-right: 0.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-4-lg {
    padding-right: 0.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-5-lg {
    padding-right: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-6-lg {
    padding-right: 0.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-7-lg {
    padding-right: 0.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-8-lg {
    padding-right: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-9-lg {
    padding-right: 0.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-10-lg {
    padding-right: 0.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-11-lg {
    padding-right: 0.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-12-lg {
    padding-right: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-13-lg {
    padding-right: 0.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-14-lg {
    padding-right: 0.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-15-lg {
    padding-right: 0.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-16-lg {
    padding-right: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-17-lg {
    padding-right: 1.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-18-lg {
    padding-right: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-19-lg {
    padding-right: 1.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-20-lg {
    padding-right: 1.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-21-lg {
    padding-right: 1.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-22-lg {
    padding-right: 1.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-23-lg {
    padding-right: 1.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-24-lg {
    padding-right: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-25-lg {
    padding-right: 1.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-26-lg {
    padding-right: 1.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-27-lg {
    padding-right: 1.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-28-lg {
    padding-right: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-29-lg {
    padding-right: 1.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-30-lg {
    padding-right: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-31-lg {
    padding-right: 1.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-32-lg {
    padding-right: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-33-lg {
    padding-right: 2.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-34-lg {
    padding-right: 2.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-35-lg {
    padding-right: 2.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-36-lg {
    padding-right: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-37-lg {
    padding-right: 2.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-38-lg {
    padding-right: 2.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-39-lg {
    padding-right: 2.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-40-lg {
    padding-right: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-41-lg {
    padding-right: 2.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-42-lg {
    padding-right: 2.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-43-lg {
    padding-right: 2.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-44-lg {
    padding-right: 2.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-45-lg {
    padding-right: 2.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-46-lg {
    padding-right: 2.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-47-lg {
    padding-right: 2.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-48-lg {
    padding-right: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-49-lg {
    padding-right: 3.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-50-lg {
    padding-right: 3.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-51-lg {
    padding-right: 3.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-52-lg {
    padding-right: 3.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-53-lg {
    padding-right: 3.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-54-lg {
    padding-right: 3.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-55-lg {
    padding-right: 3.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-56-lg {
    padding-right: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-57-lg {
    padding-right: 3.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-58-lg {
    padding-right: 3.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-59-lg {
    padding-right: 3.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-60-lg {
    padding-right: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-61-lg {
    padding-right: 3.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-62-lg {
    padding-right: 3.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-63-lg {
    padding-right: 3.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-64-lg {
    padding-right: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-65-lg {
    padding-right: 4.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-66-lg {
    padding-right: 4.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-67-lg {
    padding-right: 4.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-68-lg {
    padding-right: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-69-lg {
    padding-right: 4.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-70-lg {
    padding-right: 4.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-71-lg {
    padding-right: 4.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-72-lg {
    padding-right: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-73-lg {
    padding-right: 4.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-74-lg {
    padding-right: 4.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-75-lg {
    padding-right: 4.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-76-lg {
    padding-right: 4.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-77-lg {
    padding-right: 4.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-78-lg {
    padding-right: 4.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-79-lg {
    padding-right: 4.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-80-lg {
    padding-right: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-81-lg {
    padding-right: 5.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-82-lg {
    padding-right: 5.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-83-lg {
    padding-right: 5.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-84-lg {
    padding-right: 5.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-85-lg {
    padding-right: 5.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-86-lg {
    padding-right: 5.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-87-lg {
    padding-right: 5.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-88-lg {
    padding-right: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-89-lg {
    padding-right: 5.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-90-lg {
    padding-right: 5.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-91-lg {
    padding-right: 5.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-92-lg {
    padding-right: 5.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-93-lg {
    padding-right: 5.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-94-lg {
    padding-right: 5.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-95-lg {
    padding-right: 5.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-96-lg {
    padding-right: 6rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-97-lg {
    padding-right: 6.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-98-lg {
    padding-right: 6.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-99-lg {
    padding-right: 6.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-100-lg {
    padding-right: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-1-md {
    padding-right: 0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-2-md {
    padding-right: 0.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-3-md {
    padding-right: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-4-md {
    padding-right: 0.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-5-md {
    padding-right: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-6-md {
    padding-right: 0.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-7-md {
    padding-right: 0.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-8-md {
    padding-right: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-9-md {
    padding-right: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-10-md {
    padding-right: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-11-md {
    padding-right: 0.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-12-md {
    padding-right: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-13-md {
    padding-right: 0.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-14-md {
    padding-right: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-15-md {
    padding-right: 0.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-16-md {
    padding-right: 1rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-17-md {
    padding-right: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-18-md {
    padding-right: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-19-md {
    padding-right: 1.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-20-md {
    padding-right: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-21-md {
    padding-right: 1.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-22-md {
    padding-right: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-23-md {
    padding-right: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-24-md {
    padding-right: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-25-md {
    padding-right: 1.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-26-md {
    padding-right: 1.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-27-md {
    padding-right: 1.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-28-md {
    padding-right: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-29-md {
    padding-right: 1.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-30-md {
    padding-right: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-31-md {
    padding-right: 1.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-32-md {
    padding-right: 2rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-33-md {
    padding-right: 2.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-34-md {
    padding-right: 2.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-35-md {
    padding-right: 2.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-36-md {
    padding-right: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-37-md {
    padding-right: 2.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-38-md {
    padding-right: 2.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-39-md {
    padding-right: 2.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-40-md {
    padding-right: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-41-md {
    padding-right: 2.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-42-md {
    padding-right: 2.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-43-md {
    padding-right: 2.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-44-md {
    padding-right: 2.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-45-md {
    padding-right: 2.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-46-md {
    padding-right: 2.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-47-md {
    padding-right: 2.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-48-md {
    padding-right: 3rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-49-md {
    padding-right: 3.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-50-md {
    padding-right: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-51-md {
    padding-right: 3.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-52-md {
    padding-right: 3.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-53-md {
    padding-right: 3.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-54-md {
    padding-right: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-55-md {
    padding-right: 3.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-56-md {
    padding-right: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-57-md {
    padding-right: 3.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-58-md {
    padding-right: 3.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-59-md {
    padding-right: 3.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-60-md {
    padding-right: 3.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-61-md {
    padding-right: 3.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-62-md {
    padding-right: 3.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-63-md {
    padding-right: 3.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-64-md {
    padding-right: 4rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-65-md {
    padding-right: 4.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-66-md {
    padding-right: 4.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-67-md {
    padding-right: 4.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-68-md {
    padding-right: 4.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-69-md {
    padding-right: 4.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-70-md {
    padding-right: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-71-md {
    padding-right: 4.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-72-md {
    padding-right: 4.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-73-md {
    padding-right: 4.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-74-md {
    padding-right: 4.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-75-md {
    padding-right: 4.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-76-md {
    padding-right: 4.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-77-md {
    padding-right: 4.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-78-md {
    padding-right: 4.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-79-md {
    padding-right: 4.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-80-md {
    padding-right: 5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-81-md {
    padding-right: 5.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-82-md {
    padding-right: 5.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-83-md {
    padding-right: 5.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-84-md {
    padding-right: 5.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-85-md {
    padding-right: 5.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-86-md {
    padding-right: 5.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-87-md {
    padding-right: 5.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-88-md {
    padding-right: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-89-md {
    padding-right: 5.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-90-md {
    padding-right: 5.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-91-md {
    padding-right: 5.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-92-md {
    padding-right: 5.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-93-md {
    padding-right: 5.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-94-md {
    padding-right: 5.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-95-md {
    padding-right: 5.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-96-md {
    padding-right: 6rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-97-md {
    padding-right: 6.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-98-md {
    padding-right: 6.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-99-md {
    padding-right: 6.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-right-100-md {
    padding-right: 6.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-1-sm {
    padding-right: 0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-2-sm {
    padding-right: 0.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-3-sm {
    padding-right: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-4-sm {
    padding-right: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-5-sm {
    padding-right: 0.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-6-sm {
    padding-right: 0.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-7-sm {
    padding-right: 0.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-8-sm {
    padding-right: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-9-sm {
    padding-right: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-10-sm {
    padding-right: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-11-sm {
    padding-right: 0.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-12-sm {
    padding-right: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-13-sm {
    padding-right: 0.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-14-sm {
    padding-right: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-15-sm {
    padding-right: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-16-sm {
    padding-right: 1rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-17-sm {
    padding-right: 1.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-18-sm {
    padding-right: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-19-sm {
    padding-right: 1.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-20-sm {
    padding-right: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-21-sm {
    padding-right: 1.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-22-sm {
    padding-right: 1.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-23-sm {
    padding-right: 1.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-24-sm {
    padding-right: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-25-sm {
    padding-right: 1.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-26-sm {
    padding-right: 1.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-27-sm {
    padding-right: 1.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-28-sm {
    padding-right: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-29-sm {
    padding-right: 1.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-30-sm {
    padding-right: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-31-sm {
    padding-right: 1.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-32-sm {
    padding-right: 2rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-33-sm {
    padding-right: 2.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-34-sm {
    padding-right: 2.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-35-sm {
    padding-right: 2.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-36-sm {
    padding-right: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-37-sm {
    padding-right: 2.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-38-sm {
    padding-right: 2.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-39-sm {
    padding-right: 2.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-40-sm {
    padding-right: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-41-sm {
    padding-right: 2.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-42-sm {
    padding-right: 2.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-43-sm {
    padding-right: 2.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-44-sm {
    padding-right: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-45-sm {
    padding-right: 2.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-46-sm {
    padding-right: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-47-sm {
    padding-right: 2.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-48-sm {
    padding-right: 3rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-49-sm {
    padding-right: 3.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-50-sm {
    padding-right: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-51-sm {
    padding-right: 3.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-52-sm {
    padding-right: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-53-sm {
    padding-right: 3.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-54-sm {
    padding-right: 3.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-55-sm {
    padding-right: 3.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-56-sm {
    padding-right: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-57-sm {
    padding-right: 3.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-58-sm {
    padding-right: 3.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-59-sm {
    padding-right: 3.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-60-sm {
    padding-right: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-61-sm {
    padding-right: 3.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-62-sm {
    padding-right: 3.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-63-sm {
    padding-right: 3.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-64-sm {
    padding-right: 4rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-65-sm {
    padding-right: 4.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-66-sm {
    padding-right: 4.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-67-sm {
    padding-right: 4.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-68-sm {
    padding-right: 4.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-69-sm {
    padding-right: 4.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-70-sm {
    padding-right: 4.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-71-sm {
    padding-right: 4.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-72-sm {
    padding-right: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-73-sm {
    padding-right: 4.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-74-sm {
    padding-right: 4.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-75-sm {
    padding-right: 4.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-76-sm {
    padding-right: 4.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-77-sm {
    padding-right: 4.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-78-sm {
    padding-right: 4.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-79-sm {
    padding-right: 4.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-80-sm {
    padding-right: 5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-81-sm {
    padding-right: 5.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-82-sm {
    padding-right: 5.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-83-sm {
    padding-right: 5.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-84-sm {
    padding-right: 5.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-85-sm {
    padding-right: 5.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-86-sm {
    padding-right: 5.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-87-sm {
    padding-right: 5.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-88-sm {
    padding-right: 5.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-89-sm {
    padding-right: 5.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-90-sm {
    padding-right: 5.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-91-sm {
    padding-right: 5.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-92-sm {
    padding-right: 5.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-93-sm {
    padding-right: 5.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-94-sm {
    padding-right: 5.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-95-sm {
    padding-right: 5.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-96-sm {
    padding-right: 6rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-97-sm {
    padding-right: 6.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-98-sm {
    padding-right: 6.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-99-sm {
    padding-right: 6.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-right-100-sm {
    padding-right: 6.25rem;
  }
}
.padding-bottom-1 {
  padding-bottom: 0.0625rem;
}
.padding-bottom-2 {
  padding-bottom: 0.125rem;
}
.padding-bottom-3 {
  padding-bottom: 0.1875rem;
}
.padding-bottom-4 {
  padding-bottom: 0.25rem;
}
.padding-bottom-5 {
  padding-bottom: 0.3125rem;
}
.padding-bottom-6 {
  padding-bottom: 0.375rem;
}
.padding-bottom-7 {
  padding-bottom: 0.4375rem;
}
.padding-bottom-8 {
  padding-bottom: 0.5rem;
}
.padding-bottom-9 {
  padding-bottom: 0.5625rem;
}
.padding-bottom-10 {
  padding-bottom: 0.625rem;
}
.padding-bottom-11 {
  padding-bottom: 0.6875rem;
}
.padding-bottom-12 {
  padding-bottom: 0.75rem;
}
.padding-bottom-13 {
  padding-bottom: 0.8125rem;
}
.padding-bottom-14 {
  padding-bottom: 0.875rem;
}
.padding-bottom-15 {
  padding-bottom: 0.9375rem;
}
.padding-bottom-16 {
  padding-bottom: 1rem;
}
.padding-bottom-17 {
  padding-bottom: 1.0625rem;
}
.padding-bottom-18 {
  padding-bottom: 1.125rem;
}
.padding-bottom-19 {
  padding-bottom: 1.1875rem;
}
.padding-bottom-20 {
  padding-bottom: 1.25rem;
}
.padding-bottom-21 {
  padding-bottom: 1.3125rem;
}
.padding-bottom-22 {
  padding-bottom: 1.375rem;
}
.padding-bottom-23 {
  padding-bottom: 1.4375rem;
}
.padding-bottom-24 {
  padding-bottom: 1.5rem;
}
.padding-bottom-25 {
  padding-bottom: 1.5625rem;
}
.padding-bottom-26 {
  padding-bottom: 1.625rem;
}
.padding-bottom-27 {
  padding-bottom: 1.6875rem;
}
.padding-bottom-28 {
  padding-bottom: 1.75rem;
}
.padding-bottom-29 {
  padding-bottom: 1.8125rem;
}
.padding-bottom-30 {
  padding-bottom: 1.875rem;
}
.padding-bottom-31 {
  padding-bottom: 1.9375rem;
}
.padding-bottom-32 {
  padding-bottom: 2rem;
}
.padding-bottom-33 {
  padding-bottom: 2.0625rem;
}
.padding-bottom-34 {
  padding-bottom: 2.125rem;
}
.padding-bottom-35 {
  padding-bottom: 2.1875rem;
}
.padding-bottom-36 {
  padding-bottom: 2.25rem;
}
.padding-bottom-37 {
  padding-bottom: 2.3125rem;
}
.padding-bottom-38 {
  padding-bottom: 2.375rem;
}
.padding-bottom-39 {
  padding-bottom: 2.4375rem;
}
.padding-bottom-40 {
  padding-bottom: 2.5rem;
}
.padding-bottom-41 {
  padding-bottom: 2.5625rem;
}
.padding-bottom-42 {
  padding-bottom: 2.625rem;
}
.padding-bottom-43 {
  padding-bottom: 2.6875rem;
}
.padding-bottom-44 {
  padding-bottom: 2.75rem;
}
.padding-bottom-45 {
  padding-bottom: 2.8125rem;
}
.padding-bottom-46 {
  padding-bottom: 2.875rem;
}
.padding-bottom-47 {
  padding-bottom: 2.9375rem;
}
.padding-bottom-48 {
  padding-bottom: 3rem;
}
.padding-bottom-49 {
  padding-bottom: 3.0625rem;
}
.padding-bottom-50 {
  padding-bottom: 3.125rem;
}
.padding-bottom-51 {
  padding-bottom: 3.1875rem;
}
.padding-bottom-52 {
  padding-bottom: 3.25rem;
}
.padding-bottom-53 {
  padding-bottom: 3.3125rem;
}
.padding-bottom-54 {
  padding-bottom: 3.375rem;
}
.padding-bottom-55 {
  padding-bottom: 3.4375rem;
}
.padding-bottom-56 {
  padding-bottom: 3.5rem;
}
.padding-bottom-57 {
  padding-bottom: 3.5625rem;
}
.padding-bottom-58 {
  padding-bottom: 3.625rem;
}
.padding-bottom-59 {
  padding-bottom: 3.6875rem;
}
.padding-bottom-60 {
  padding-bottom: 3.75rem;
}
.padding-bottom-61 {
  padding-bottom: 3.8125rem;
}
.padding-bottom-62 {
  padding-bottom: 3.875rem;
}
.padding-bottom-63 {
  padding-bottom: 3.9375rem;
}
.padding-bottom-64 {
  padding-bottom: 4rem;
}
.padding-bottom-65 {
  padding-bottom: 4.0625rem;
}
.padding-bottom-66 {
  padding-bottom: 4.125rem;
}
.padding-bottom-67 {
  padding-bottom: 4.1875rem;
}
.padding-bottom-68 {
  padding-bottom: 4.25rem;
}
.padding-bottom-69 {
  padding-bottom: 4.3125rem;
}
.padding-bottom-70 {
  padding-bottom: 4.375rem;
}
.padding-bottom-71 {
  padding-bottom: 4.4375rem;
}
.padding-bottom-72 {
  padding-bottom: 4.5rem;
}
.padding-bottom-73 {
  padding-bottom: 4.5625rem;
}
.padding-bottom-74 {
  padding-bottom: 4.625rem;
}
.padding-bottom-75 {
  padding-bottom: 4.6875rem;
}
.padding-bottom-76 {
  padding-bottom: 4.75rem;
}
.padding-bottom-77 {
  padding-bottom: 4.8125rem;
}
.padding-bottom-78 {
  padding-bottom: 4.875rem;
}
.padding-bottom-79 {
  padding-bottom: 4.9375rem;
}
.padding-bottom-80 {
  padding-bottom: 5rem;
}
.padding-bottom-81 {
  padding-bottom: 5.0625rem;
}
.padding-bottom-82 {
  padding-bottom: 5.125rem;
}
.padding-bottom-83 {
  padding-bottom: 5.1875rem;
}
.padding-bottom-84 {
  padding-bottom: 5.25rem;
}
.padding-bottom-85 {
  padding-bottom: 5.3125rem;
}
.padding-bottom-86 {
  padding-bottom: 5.375rem;
}
.padding-bottom-87 {
  padding-bottom: 5.4375rem;
}
.padding-bottom-88 {
  padding-bottom: 5.5rem;
}
.padding-bottom-89 {
  padding-bottom: 5.5625rem;
}
.padding-bottom-90 {
  padding-bottom: 5.625rem;
}
.padding-bottom-91 {
  padding-bottom: 5.6875rem;
}
.padding-bottom-92 {
  padding-bottom: 5.75rem;
}
.padding-bottom-93 {
  padding-bottom: 5.8125rem;
}
.padding-bottom-94 {
  padding-bottom: 5.875rem;
}
.padding-bottom-95 {
  padding-bottom: 5.9375rem;
}
.padding-bottom-96 {
  padding-bottom: 6rem;
}
.padding-bottom-97 {
  padding-bottom: 6.0625rem;
}
.padding-bottom-98 {
  padding-bottom: 6.125rem;
}
.padding-bottom-99 {
  padding-bottom: 6.1875rem;
}
.padding-bottom-100 {
  padding-bottom: 6.25rem;
}
@media (max-width: 1199.98px) {
  .padding-bottom-1-lg {
    padding-bottom: 0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-2-lg {
    padding-bottom: 0.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-3-lg {
    padding-bottom: 0.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-4-lg {
    padding-bottom: 0.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-5-lg {
    padding-bottom: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-6-lg {
    padding-bottom: 0.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-7-lg {
    padding-bottom: 0.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-8-lg {
    padding-bottom: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-9-lg {
    padding-bottom: 0.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-10-lg {
    padding-bottom: 0.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-11-lg {
    padding-bottom: 0.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-12-lg {
    padding-bottom: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-13-lg {
    padding-bottom: 0.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-14-lg {
    padding-bottom: 0.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-15-lg {
    padding-bottom: 0.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-16-lg {
    padding-bottom: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-17-lg {
    padding-bottom: 1.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-18-lg {
    padding-bottom: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-19-lg {
    padding-bottom: 1.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-20-lg {
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-21-lg {
    padding-bottom: 1.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-22-lg {
    padding-bottom: 1.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-23-lg {
    padding-bottom: 1.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-24-lg {
    padding-bottom: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-25-lg {
    padding-bottom: 1.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-26-lg {
    padding-bottom: 1.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-27-lg {
    padding-bottom: 1.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-28-lg {
    padding-bottom: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-29-lg {
    padding-bottom: 1.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-30-lg {
    padding-bottom: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-31-lg {
    padding-bottom: 1.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-32-lg {
    padding-bottom: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-33-lg {
    padding-bottom: 2.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-34-lg {
    padding-bottom: 2.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-35-lg {
    padding-bottom: 2.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-36-lg {
    padding-bottom: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-37-lg {
    padding-bottom: 2.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-38-lg {
    padding-bottom: 2.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-39-lg {
    padding-bottom: 2.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-40-lg {
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-41-lg {
    padding-bottom: 2.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-42-lg {
    padding-bottom: 2.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-43-lg {
    padding-bottom: 2.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-44-lg {
    padding-bottom: 2.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-45-lg {
    padding-bottom: 2.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-46-lg {
    padding-bottom: 2.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-47-lg {
    padding-bottom: 2.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-48-lg {
    padding-bottom: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-49-lg {
    padding-bottom: 3.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-50-lg {
    padding-bottom: 3.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-51-lg {
    padding-bottom: 3.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-52-lg {
    padding-bottom: 3.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-53-lg {
    padding-bottom: 3.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-54-lg {
    padding-bottom: 3.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-55-lg {
    padding-bottom: 3.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-56-lg {
    padding-bottom: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-57-lg {
    padding-bottom: 3.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-58-lg {
    padding-bottom: 3.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-59-lg {
    padding-bottom: 3.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-60-lg {
    padding-bottom: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-61-lg {
    padding-bottom: 3.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-62-lg {
    padding-bottom: 3.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-63-lg {
    padding-bottom: 3.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-64-lg {
    padding-bottom: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-65-lg {
    padding-bottom: 4.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-66-lg {
    padding-bottom: 4.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-67-lg {
    padding-bottom: 4.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-68-lg {
    padding-bottom: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-69-lg {
    padding-bottom: 4.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-70-lg {
    padding-bottom: 4.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-71-lg {
    padding-bottom: 4.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-72-lg {
    padding-bottom: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-73-lg {
    padding-bottom: 4.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-74-lg {
    padding-bottom: 4.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-75-lg {
    padding-bottom: 4.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-76-lg {
    padding-bottom: 4.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-77-lg {
    padding-bottom: 4.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-78-lg {
    padding-bottom: 4.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-79-lg {
    padding-bottom: 4.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-80-lg {
    padding-bottom: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-81-lg {
    padding-bottom: 5.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-82-lg {
    padding-bottom: 5.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-83-lg {
    padding-bottom: 5.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-84-lg {
    padding-bottom: 5.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-85-lg {
    padding-bottom: 5.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-86-lg {
    padding-bottom: 5.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-87-lg {
    padding-bottom: 5.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-88-lg {
    padding-bottom: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-89-lg {
    padding-bottom: 5.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-90-lg {
    padding-bottom: 5.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-91-lg {
    padding-bottom: 5.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-92-lg {
    padding-bottom: 5.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-93-lg {
    padding-bottom: 5.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-94-lg {
    padding-bottom: 5.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-95-lg {
    padding-bottom: 5.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-96-lg {
    padding-bottom: 6rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-97-lg {
    padding-bottom: 6.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-98-lg {
    padding-bottom: 6.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-99-lg {
    padding-bottom: 6.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-bottom-100-lg {
    padding-bottom: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-1-md {
    padding-bottom: 0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-2-md {
    padding-bottom: 0.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-3-md {
    padding-bottom: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-4-md {
    padding-bottom: 0.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-5-md {
    padding-bottom: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-6-md {
    padding-bottom: 0.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-7-md {
    padding-bottom: 0.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-8-md {
    padding-bottom: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-9-md {
    padding-bottom: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-10-md {
    padding-bottom: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-11-md {
    padding-bottom: 0.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-12-md {
    padding-bottom: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-13-md {
    padding-bottom: 0.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-14-md {
    padding-bottom: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-15-md {
    padding-bottom: 0.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-16-md {
    padding-bottom: 1rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-17-md {
    padding-bottom: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-18-md {
    padding-bottom: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-19-md {
    padding-bottom: 1.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-20-md {
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-21-md {
    padding-bottom: 1.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-22-md {
    padding-bottom: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-23-md {
    padding-bottom: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-24-md {
    padding-bottom: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-25-md {
    padding-bottom: 1.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-26-md {
    padding-bottom: 1.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-27-md {
    padding-bottom: 1.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-28-md {
    padding-bottom: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-29-md {
    padding-bottom: 1.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-30-md {
    padding-bottom: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-31-md {
    padding-bottom: 1.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-32-md {
    padding-bottom: 2rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-33-md {
    padding-bottom: 2.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-34-md {
    padding-bottom: 2.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-35-md {
    padding-bottom: 2.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-36-md {
    padding-bottom: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-37-md {
    padding-bottom: 2.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-38-md {
    padding-bottom: 2.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-39-md {
    padding-bottom: 2.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-40-md {
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-41-md {
    padding-bottom: 2.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-42-md {
    padding-bottom: 2.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-43-md {
    padding-bottom: 2.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-44-md {
    padding-bottom: 2.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-45-md {
    padding-bottom: 2.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-46-md {
    padding-bottom: 2.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-47-md {
    padding-bottom: 2.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-48-md {
    padding-bottom: 3rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-49-md {
    padding-bottom: 3.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-50-md {
    padding-bottom: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-51-md {
    padding-bottom: 3.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-52-md {
    padding-bottom: 3.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-53-md {
    padding-bottom: 3.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-54-md {
    padding-bottom: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-55-md {
    padding-bottom: 3.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-56-md {
    padding-bottom: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-57-md {
    padding-bottom: 3.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-58-md {
    padding-bottom: 3.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-59-md {
    padding-bottom: 3.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-60-md {
    padding-bottom: 3.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-61-md {
    padding-bottom: 3.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-62-md {
    padding-bottom: 3.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-63-md {
    padding-bottom: 3.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-64-md {
    padding-bottom: 4rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-65-md {
    padding-bottom: 4.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-66-md {
    padding-bottom: 4.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-67-md {
    padding-bottom: 4.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-68-md {
    padding-bottom: 4.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-69-md {
    padding-bottom: 4.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-70-md {
    padding-bottom: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-71-md {
    padding-bottom: 4.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-72-md {
    padding-bottom: 4.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-73-md {
    padding-bottom: 4.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-74-md {
    padding-bottom: 4.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-75-md {
    padding-bottom: 4.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-76-md {
    padding-bottom: 4.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-77-md {
    padding-bottom: 4.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-78-md {
    padding-bottom: 4.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-79-md {
    padding-bottom: 4.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-80-md {
    padding-bottom: 5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-81-md {
    padding-bottom: 5.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-82-md {
    padding-bottom: 5.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-83-md {
    padding-bottom: 5.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-84-md {
    padding-bottom: 5.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-85-md {
    padding-bottom: 5.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-86-md {
    padding-bottom: 5.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-87-md {
    padding-bottom: 5.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-88-md {
    padding-bottom: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-89-md {
    padding-bottom: 5.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-90-md {
    padding-bottom: 5.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-91-md {
    padding-bottom: 5.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-92-md {
    padding-bottom: 5.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-93-md {
    padding-bottom: 5.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-94-md {
    padding-bottom: 5.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-95-md {
    padding-bottom: 5.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-96-md {
    padding-bottom: 6rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-97-md {
    padding-bottom: 6.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-98-md {
    padding-bottom: 6.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-99-md {
    padding-bottom: 6.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-bottom-100-md {
    padding-bottom: 6.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-1-sm {
    padding-bottom: 0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-2-sm {
    padding-bottom: 0.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-3-sm {
    padding-bottom: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-4-sm {
    padding-bottom: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-5-sm {
    padding-bottom: 0.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-6-sm {
    padding-bottom: 0.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-7-sm {
    padding-bottom: 0.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-8-sm {
    padding-bottom: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-9-sm {
    padding-bottom: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-10-sm {
    padding-bottom: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-11-sm {
    padding-bottom: 0.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-12-sm {
    padding-bottom: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-13-sm {
    padding-bottom: 0.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-14-sm {
    padding-bottom: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-15-sm {
    padding-bottom: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-16-sm {
    padding-bottom: 1rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-17-sm {
    padding-bottom: 1.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-18-sm {
    padding-bottom: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-19-sm {
    padding-bottom: 1.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-20-sm {
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-21-sm {
    padding-bottom: 1.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-22-sm {
    padding-bottom: 1.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-23-sm {
    padding-bottom: 1.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-24-sm {
    padding-bottom: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-25-sm {
    padding-bottom: 1.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-26-sm {
    padding-bottom: 1.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-27-sm {
    padding-bottom: 1.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-28-sm {
    padding-bottom: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-29-sm {
    padding-bottom: 1.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-30-sm {
    padding-bottom: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-31-sm {
    padding-bottom: 1.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-32-sm {
    padding-bottom: 2rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-33-sm {
    padding-bottom: 2.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-34-sm {
    padding-bottom: 2.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-35-sm {
    padding-bottom: 2.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-36-sm {
    padding-bottom: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-37-sm {
    padding-bottom: 2.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-38-sm {
    padding-bottom: 2.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-39-sm {
    padding-bottom: 2.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-40-sm {
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-41-sm {
    padding-bottom: 2.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-42-sm {
    padding-bottom: 2.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-43-sm {
    padding-bottom: 2.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-44-sm {
    padding-bottom: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-45-sm {
    padding-bottom: 2.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-46-sm {
    padding-bottom: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-47-sm {
    padding-bottom: 2.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-48-sm {
    padding-bottom: 3rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-49-sm {
    padding-bottom: 3.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-50-sm {
    padding-bottom: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-51-sm {
    padding-bottom: 3.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-52-sm {
    padding-bottom: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-53-sm {
    padding-bottom: 3.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-54-sm {
    padding-bottom: 3.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-55-sm {
    padding-bottom: 3.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-56-sm {
    padding-bottom: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-57-sm {
    padding-bottom: 3.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-58-sm {
    padding-bottom: 3.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-59-sm {
    padding-bottom: 3.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-60-sm {
    padding-bottom: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-61-sm {
    padding-bottom: 3.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-62-sm {
    padding-bottom: 3.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-63-sm {
    padding-bottom: 3.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-64-sm {
    padding-bottom: 4rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-65-sm {
    padding-bottom: 4.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-66-sm {
    padding-bottom: 4.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-67-sm {
    padding-bottom: 4.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-68-sm {
    padding-bottom: 4.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-69-sm {
    padding-bottom: 4.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-70-sm {
    padding-bottom: 4.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-71-sm {
    padding-bottom: 4.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-72-sm {
    padding-bottom: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-73-sm {
    padding-bottom: 4.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-74-sm {
    padding-bottom: 4.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-75-sm {
    padding-bottom: 4.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-76-sm {
    padding-bottom: 4.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-77-sm {
    padding-bottom: 4.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-78-sm {
    padding-bottom: 4.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-79-sm {
    padding-bottom: 4.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-80-sm {
    padding-bottom: 5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-81-sm {
    padding-bottom: 5.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-82-sm {
    padding-bottom: 5.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-83-sm {
    padding-bottom: 5.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-84-sm {
    padding-bottom: 5.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-85-sm {
    padding-bottom: 5.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-86-sm {
    padding-bottom: 5.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-87-sm {
    padding-bottom: 5.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-88-sm {
    padding-bottom: 5.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-89-sm {
    padding-bottom: 5.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-90-sm {
    padding-bottom: 5.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-91-sm {
    padding-bottom: 5.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-92-sm {
    padding-bottom: 5.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-93-sm {
    padding-bottom: 5.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-94-sm {
    padding-bottom: 5.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-95-sm {
    padding-bottom: 5.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-96-sm {
    padding-bottom: 6rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-97-sm {
    padding-bottom: 6.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-98-sm {
    padding-bottom: 6.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-99-sm {
    padding-bottom: 6.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-bottom-100-sm {
    padding-bottom: 6.25rem;
  }
}
.padding-left-1 {
  padding-left: 0.0625rem;
}
.padding-left-2 {
  padding-left: 0.125rem;
}
.padding-left-3 {
  padding-left: 0.1875rem;
}
.padding-left-4 {
  padding-left: 0.25rem;
}
.padding-left-5 {
  padding-left: 0.3125rem;
}
.padding-left-6 {
  padding-left: 0.375rem;
}
.padding-left-7 {
  padding-left: 0.4375rem;
}
.padding-left-8 {
  padding-left: 0.5rem;
}
.padding-left-9 {
  padding-left: 0.5625rem;
}
.padding-left-10 {
  padding-left: 0.625rem;
}
.padding-left-11 {
  padding-left: 0.6875rem;
}
.padding-left-12 {
  padding-left: 0.75rem;
}
.padding-left-13 {
  padding-left: 0.8125rem;
}
.padding-left-14 {
  padding-left: 0.875rem;
}
.padding-left-15 {
  padding-left: 0.9375rem;
}
.padding-left-16 {
  padding-left: 1rem;
}
.padding-left-17 {
  padding-left: 1.0625rem;
}
.padding-left-18 {
  padding-left: 1.125rem;
}
.padding-left-19 {
  padding-left: 1.1875rem;
}
.padding-left-20 {
  padding-left: 1.25rem;
}
.padding-left-21 {
  padding-left: 1.3125rem;
}
.padding-left-22 {
  padding-left: 1.375rem;
}
.padding-left-23 {
  padding-left: 1.4375rem;
}
.padding-left-24 {
  padding-left: 1.5rem;
}
.padding-left-25 {
  padding-left: 1.5625rem;
}
.padding-left-26 {
  padding-left: 1.625rem;
}
.padding-left-27 {
  padding-left: 1.6875rem;
}
.padding-left-28 {
  padding-left: 1.75rem;
}
.padding-left-29 {
  padding-left: 1.8125rem;
}
.padding-left-30 {
  padding-left: 1.875rem;
}
.padding-left-31 {
  padding-left: 1.9375rem;
}
.padding-left-32 {
  padding-left: 2rem;
}
.padding-left-33 {
  padding-left: 2.0625rem;
}
.padding-left-34 {
  padding-left: 2.125rem;
}
.padding-left-35 {
  padding-left: 2.1875rem;
}
.padding-left-36 {
  padding-left: 2.25rem;
}
.padding-left-37 {
  padding-left: 2.3125rem;
}
.padding-left-38 {
  padding-left: 2.375rem;
}
.padding-left-39 {
  padding-left: 2.4375rem;
}
.padding-left-40 {
  padding-left: 2.5rem;
}
.padding-left-41 {
  padding-left: 2.5625rem;
}
.padding-left-42 {
  padding-left: 2.625rem;
}
.padding-left-43 {
  padding-left: 2.6875rem;
}
.padding-left-44 {
  padding-left: 2.75rem;
}
.padding-left-45 {
  padding-left: 2.8125rem;
}
.padding-left-46 {
  padding-left: 2.875rem;
}
.padding-left-47 {
  padding-left: 2.9375rem;
}
.padding-left-48 {
  padding-left: 3rem;
}
.padding-left-49 {
  padding-left: 3.0625rem;
}
.padding-left-50 {
  padding-left: 3.125rem;
}
.padding-left-51 {
  padding-left: 3.1875rem;
}
.padding-left-52 {
  padding-left: 3.25rem;
}
.padding-left-53 {
  padding-left: 3.3125rem;
}
.padding-left-54 {
  padding-left: 3.375rem;
}
.padding-left-55 {
  padding-left: 3.4375rem;
}
.padding-left-56 {
  padding-left: 3.5rem;
}
.padding-left-57 {
  padding-left: 3.5625rem;
}
.padding-left-58 {
  padding-left: 3.625rem;
}
.padding-left-59 {
  padding-left: 3.6875rem;
}
.padding-left-60 {
  padding-left: 3.75rem;
}
.padding-left-61 {
  padding-left: 3.8125rem;
}
.padding-left-62 {
  padding-left: 3.875rem;
}
.padding-left-63 {
  padding-left: 3.9375rem;
}
.padding-left-64 {
  padding-left: 4rem;
}
.padding-left-65 {
  padding-left: 4.0625rem;
}
.padding-left-66 {
  padding-left: 4.125rem;
}
.padding-left-67 {
  padding-left: 4.1875rem;
}
.padding-left-68 {
  padding-left: 4.25rem;
}
.padding-left-69 {
  padding-left: 4.3125rem;
}
.padding-left-70 {
  padding-left: 4.375rem;
}
.padding-left-71 {
  padding-left: 4.4375rem;
}
.padding-left-72 {
  padding-left: 4.5rem;
}
.padding-left-73 {
  padding-left: 4.5625rem;
}
.padding-left-74 {
  padding-left: 4.625rem;
}
.padding-left-75 {
  padding-left: 4.6875rem;
}
.padding-left-76 {
  padding-left: 4.75rem;
}
.padding-left-77 {
  padding-left: 4.8125rem;
}
.padding-left-78 {
  padding-left: 4.875rem;
}
.padding-left-79 {
  padding-left: 4.9375rem;
}
.padding-left-80 {
  padding-left: 5rem;
}
.padding-left-81 {
  padding-left: 5.0625rem;
}
.padding-left-82 {
  padding-left: 5.125rem;
}
.padding-left-83 {
  padding-left: 5.1875rem;
}
.padding-left-84 {
  padding-left: 5.25rem;
}
.padding-left-85 {
  padding-left: 5.3125rem;
}
.padding-left-86 {
  padding-left: 5.375rem;
}
.padding-left-87 {
  padding-left: 5.4375rem;
}
.padding-left-88 {
  padding-left: 5.5rem;
}
.padding-left-89 {
  padding-left: 5.5625rem;
}
.padding-left-90 {
  padding-left: 5.625rem;
}
.padding-left-91 {
  padding-left: 5.6875rem;
}
.padding-left-92 {
  padding-left: 5.75rem;
}
.padding-left-93 {
  padding-left: 5.8125rem;
}
.padding-left-94 {
  padding-left: 5.875rem;
}
.padding-left-95 {
  padding-left: 5.9375rem;
}
.padding-left-96 {
  padding-left: 6rem;
}
.padding-left-97 {
  padding-left: 6.0625rem;
}
.padding-left-98 {
  padding-left: 6.125rem;
}
.padding-left-99 {
  padding-left: 6.1875rem;
}
.padding-left-100 {
  padding-left: 6.25rem;
}
@media (max-width: 1199.98px) {
  .padding-left-1-lg {
    padding-left: 0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-2-lg {
    padding-left: 0.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-3-lg {
    padding-left: 0.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-4-lg {
    padding-left: 0.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-5-lg {
    padding-left: 0.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-6-lg {
    padding-left: 0.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-7-lg {
    padding-left: 0.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-8-lg {
    padding-left: 0.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-9-lg {
    padding-left: 0.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-10-lg {
    padding-left: 0.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-11-lg {
    padding-left: 0.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-12-lg {
    padding-left: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-13-lg {
    padding-left: 0.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-14-lg {
    padding-left: 0.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-15-lg {
    padding-left: 0.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-16-lg {
    padding-left: 1rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-17-lg {
    padding-left: 1.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-18-lg {
    padding-left: 1.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-19-lg {
    padding-left: 1.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-20-lg {
    padding-left: 1.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-21-lg {
    padding-left: 1.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-22-lg {
    padding-left: 1.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-23-lg {
    padding-left: 1.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-24-lg {
    padding-left: 1.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-25-lg {
    padding-left: 1.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-26-lg {
    padding-left: 1.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-27-lg {
    padding-left: 1.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-28-lg {
    padding-left: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-29-lg {
    padding-left: 1.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-30-lg {
    padding-left: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-31-lg {
    padding-left: 1.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-32-lg {
    padding-left: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-33-lg {
    padding-left: 2.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-34-lg {
    padding-left: 2.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-35-lg {
    padding-left: 2.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-36-lg {
    padding-left: 2.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-37-lg {
    padding-left: 2.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-38-lg {
    padding-left: 2.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-39-lg {
    padding-left: 2.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-40-lg {
    padding-left: 2.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-41-lg {
    padding-left: 2.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-42-lg {
    padding-left: 2.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-43-lg {
    padding-left: 2.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-44-lg {
    padding-left: 2.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-45-lg {
    padding-left: 2.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-46-lg {
    padding-left: 2.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-47-lg {
    padding-left: 2.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-48-lg {
    padding-left: 3rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-49-lg {
    padding-left: 3.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-50-lg {
    padding-left: 3.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-51-lg {
    padding-left: 3.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-52-lg {
    padding-left: 3.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-53-lg {
    padding-left: 3.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-54-lg {
    padding-left: 3.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-55-lg {
    padding-left: 3.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-56-lg {
    padding-left: 3.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-57-lg {
    padding-left: 3.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-58-lg {
    padding-left: 3.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-59-lg {
    padding-left: 3.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-60-lg {
    padding-left: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-61-lg {
    padding-left: 3.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-62-lg {
    padding-left: 3.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-63-lg {
    padding-left: 3.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-64-lg {
    padding-left: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-65-lg {
    padding-left: 4.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-66-lg {
    padding-left: 4.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-67-lg {
    padding-left: 4.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-68-lg {
    padding-left: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-69-lg {
    padding-left: 4.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-70-lg {
    padding-left: 4.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-71-lg {
    padding-left: 4.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-72-lg {
    padding-left: 4.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-73-lg {
    padding-left: 4.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-74-lg {
    padding-left: 4.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-75-lg {
    padding-left: 4.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-76-lg {
    padding-left: 4.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-77-lg {
    padding-left: 4.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-78-lg {
    padding-left: 4.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-79-lg {
    padding-left: 4.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-80-lg {
    padding-left: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-81-lg {
    padding-left: 5.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-82-lg {
    padding-left: 5.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-83-lg {
    padding-left: 5.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-84-lg {
    padding-left: 5.25rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-85-lg {
    padding-left: 5.3125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-86-lg {
    padding-left: 5.375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-87-lg {
    padding-left: 5.4375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-88-lg {
    padding-left: 5.5rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-89-lg {
    padding-left: 5.5625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-90-lg {
    padding-left: 5.625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-91-lg {
    padding-left: 5.6875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-92-lg {
    padding-left: 5.75rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-93-lg {
    padding-left: 5.8125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-94-lg {
    padding-left: 5.875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-95-lg {
    padding-left: 5.9375rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-96-lg {
    padding-left: 6rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-97-lg {
    padding-left: 6.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-98-lg {
    padding-left: 6.125rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-99-lg {
    padding-left: 6.1875rem;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-100-lg {
    padding-left: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-1-md {
    padding-left: 0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-2-md {
    padding-left: 0.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-3-md {
    padding-left: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-4-md {
    padding-left: 0.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-5-md {
    padding-left: 0.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-6-md {
    padding-left: 0.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-7-md {
    padding-left: 0.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-8-md {
    padding-left: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-9-md {
    padding-left: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-10-md {
    padding-left: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-11-md {
    padding-left: 0.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-12-md {
    padding-left: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-13-md {
    padding-left: 0.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-14-md {
    padding-left: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-15-md {
    padding-left: 0.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-16-md {
    padding-left: 1rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-17-md {
    padding-left: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-18-md {
    padding-left: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-19-md {
    padding-left: 1.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-20-md {
    padding-left: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-21-md {
    padding-left: 1.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-22-md {
    padding-left: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-23-md {
    padding-left: 1.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-24-md {
    padding-left: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-25-md {
    padding-left: 1.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-26-md {
    padding-left: 1.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-27-md {
    padding-left: 1.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-28-md {
    padding-left: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-29-md {
    padding-left: 1.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-30-md {
    padding-left: 1.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-31-md {
    padding-left: 1.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-32-md {
    padding-left: 2rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-33-md {
    padding-left: 2.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-34-md {
    padding-left: 2.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-35-md {
    padding-left: 2.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-36-md {
    padding-left: 2.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-37-md {
    padding-left: 2.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-38-md {
    padding-left: 2.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-39-md {
    padding-left: 2.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-40-md {
    padding-left: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-41-md {
    padding-left: 2.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-42-md {
    padding-left: 2.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-43-md {
    padding-left: 2.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-44-md {
    padding-left: 2.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-45-md {
    padding-left: 2.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-46-md {
    padding-left: 2.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-47-md {
    padding-left: 2.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-48-md {
    padding-left: 3rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-49-md {
    padding-left: 3.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-50-md {
    padding-left: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-51-md {
    padding-left: 3.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-52-md {
    padding-left: 3.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-53-md {
    padding-left: 3.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-54-md {
    padding-left: 3.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-55-md {
    padding-left: 3.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-56-md {
    padding-left: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-57-md {
    padding-left: 3.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-58-md {
    padding-left: 3.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-59-md {
    padding-left: 3.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-60-md {
    padding-left: 3.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-61-md {
    padding-left: 3.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-62-md {
    padding-left: 3.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-63-md {
    padding-left: 3.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-64-md {
    padding-left: 4rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-65-md {
    padding-left: 4.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-66-md {
    padding-left: 4.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-67-md {
    padding-left: 4.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-68-md {
    padding-left: 4.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-69-md {
    padding-left: 4.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-70-md {
    padding-left: 4.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-71-md {
    padding-left: 4.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-72-md {
    padding-left: 4.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-73-md {
    padding-left: 4.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-74-md {
    padding-left: 4.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-75-md {
    padding-left: 4.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-76-md {
    padding-left: 4.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-77-md {
    padding-left: 4.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-78-md {
    padding-left: 4.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-79-md {
    padding-left: 4.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-80-md {
    padding-left: 5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-81-md {
    padding-left: 5.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-82-md {
    padding-left: 5.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-83-md {
    padding-left: 5.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-84-md {
    padding-left: 5.25rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-85-md {
    padding-left: 5.3125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-86-md {
    padding-left: 5.375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-87-md {
    padding-left: 5.4375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-88-md {
    padding-left: 5.5rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-89-md {
    padding-left: 5.5625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-90-md {
    padding-left: 5.625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-91-md {
    padding-left: 5.6875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-92-md {
    padding-left: 5.75rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-93-md {
    padding-left: 5.8125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-94-md {
    padding-left: 5.875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-95-md {
    padding-left: 5.9375rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-96-md {
    padding-left: 6rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-97-md {
    padding-left: 6.0625rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-98-md {
    padding-left: 6.125rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-99-md {
    padding-left: 6.1875rem;
  }
}
@media (max-width: 991.98px) {
  .padding-left-100-md {
    padding-left: 6.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-1-sm {
    padding-left: 0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-2-sm {
    padding-left: 0.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-3-sm {
    padding-left: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-4-sm {
    padding-left: 0.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-5-sm {
    padding-left: 0.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-6-sm {
    padding-left: 0.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-7-sm {
    padding-left: 0.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-8-sm {
    padding-left: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-9-sm {
    padding-left: 0.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-10-sm {
    padding-left: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-11-sm {
    padding-left: 0.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-12-sm {
    padding-left: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-13-sm {
    padding-left: 0.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-14-sm {
    padding-left: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-15-sm {
    padding-left: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-16-sm {
    padding-left: 1rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-17-sm {
    padding-left: 1.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-18-sm {
    padding-left: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-19-sm {
    padding-left: 1.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-20-sm {
    padding-left: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-21-sm {
    padding-left: 1.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-22-sm {
    padding-left: 1.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-23-sm {
    padding-left: 1.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-24-sm {
    padding-left: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-25-sm {
    padding-left: 1.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-26-sm {
    padding-left: 1.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-27-sm {
    padding-left: 1.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-28-sm {
    padding-left: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-29-sm {
    padding-left: 1.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-30-sm {
    padding-left: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-31-sm {
    padding-left: 1.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-32-sm {
    padding-left: 2rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-33-sm {
    padding-left: 2.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-34-sm {
    padding-left: 2.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-35-sm {
    padding-left: 2.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-36-sm {
    padding-left: 2.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-37-sm {
    padding-left: 2.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-38-sm {
    padding-left: 2.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-39-sm {
    padding-left: 2.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-40-sm {
    padding-left: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-41-sm {
    padding-left: 2.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-42-sm {
    padding-left: 2.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-43-sm {
    padding-left: 2.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-44-sm {
    padding-left: 2.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-45-sm {
    padding-left: 2.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-46-sm {
    padding-left: 2.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-47-sm {
    padding-left: 2.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-48-sm {
    padding-left: 3rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-49-sm {
    padding-left: 3.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-50-sm {
    padding-left: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-51-sm {
    padding-left: 3.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-52-sm {
    padding-left: 3.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-53-sm {
    padding-left: 3.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-54-sm {
    padding-left: 3.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-55-sm {
    padding-left: 3.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-56-sm {
    padding-left: 3.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-57-sm {
    padding-left: 3.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-58-sm {
    padding-left: 3.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-59-sm {
    padding-left: 3.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-60-sm {
    padding-left: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-61-sm {
    padding-left: 3.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-62-sm {
    padding-left: 3.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-63-sm {
    padding-left: 3.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-64-sm {
    padding-left: 4rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-65-sm {
    padding-left: 4.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-66-sm {
    padding-left: 4.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-67-sm {
    padding-left: 4.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-68-sm {
    padding-left: 4.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-69-sm {
    padding-left: 4.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-70-sm {
    padding-left: 4.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-71-sm {
    padding-left: 4.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-72-sm {
    padding-left: 4.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-73-sm {
    padding-left: 4.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-74-sm {
    padding-left: 4.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-75-sm {
    padding-left: 4.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-76-sm {
    padding-left: 4.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-77-sm {
    padding-left: 4.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-78-sm {
    padding-left: 4.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-79-sm {
    padding-left: 4.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-80-sm {
    padding-left: 5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-81-sm {
    padding-left: 5.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-82-sm {
    padding-left: 5.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-83-sm {
    padding-left: 5.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-84-sm {
    padding-left: 5.25rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-85-sm {
    padding-left: 5.3125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-86-sm {
    padding-left: 5.375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-87-sm {
    padding-left: 5.4375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-88-sm {
    padding-left: 5.5rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-89-sm {
    padding-left: 5.5625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-90-sm {
    padding-left: 5.625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-91-sm {
    padding-left: 5.6875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-92-sm {
    padding-left: 5.75rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-93-sm {
    padding-left: 5.8125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-94-sm {
    padding-left: 5.875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-95-sm {
    padding-left: 5.9375rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-96-sm {
    padding-left: 6rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-97-sm {
    padding-left: 6.0625rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-98-sm {
    padding-left: 6.125rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-99-sm {
    padding-left: 6.1875rem;
  }
}
@media (max-width: 767.98px) {
  .padding-left-100-sm {
    padding-left: 6.25rem;
  }
}

.width-1 {
  width: 0.0625rem;
}
.width-2 {
  width: 0.125rem;
}
.width-3 {
  width: 0.1875rem;
}
.width-4 {
  width: 0.25rem;
}
.width-5 {
  width: 0.3125rem;
}
.width-6 {
  width: 0.375rem;
}
.width-7 {
  width: 0.4375rem;
}
.width-8 {
  width: 0.5rem;
}
.width-9 {
  width: 0.5625rem;
}
.width-10 {
  width: 0.625rem;
}
.width-11 {
  width: 0.6875rem;
}
.width-12 {
  width: 0.75rem;
}
.width-13 {
  width: 0.8125rem;
}
.width-14 {
  width: 0.875rem;
}
.width-15 {
  width: 0.9375rem;
}
.width-16 {
  width: 1rem;
}
.width-17 {
  width: 1.0625rem;
}
.width-18 {
  width: 1.125rem;
}
.width-19 {
  width: 1.1875rem;
}
.width-20 {
  width: 1.25rem;
}
.width-21 {
  width: 1.3125rem;
}
.width-22 {
  width: 1.375rem;
}
.width-23 {
  width: 1.4375rem;
}
.width-24 {
  width: 1.5rem;
}
.width-25 {
  width: 1.5625rem;
}
.width-26 {
  width: 1.625rem;
}
.width-27 {
  width: 1.6875rem;
}
.width-28 {
  width: 1.75rem;
}
.width-29 {
  width: 1.8125rem;
}
.width-30 {
  width: 1.875rem;
}
.width-31 {
  width: 1.9375rem;
}
.width-32 {
  width: 2rem;
}
.width-33 {
  width: 2.0625rem;
}
.width-34 {
  width: 2.125rem;
}
.width-35 {
  width: 2.1875rem;
}
.width-36 {
  width: 2.25rem;
}
.width-37 {
  width: 2.3125rem;
}
.width-38 {
  width: 2.375rem;
}
.width-39 {
  width: 2.4375rem;
}
.width-40 {
  width: 2.5rem;
}
.width-41 {
  width: 2.5625rem;
}
.width-42 {
  width: 2.625rem;
}
.width-43 {
  width: 2.6875rem;
}
.width-44 {
  width: 2.75rem;
}
.width-45 {
  width: 2.8125rem;
}
.width-46 {
  width: 2.875rem;
}
.width-47 {
  width: 2.9375rem;
}
.width-48 {
  width: 3rem;
}
.width-49 {
  width: 3.0625rem;
}
.width-50 {
  width: 3.125rem;
}
.width-51 {
  width: 3.1875rem;
}
.width-52 {
  width: 3.25rem;
}
.width-53 {
  width: 3.3125rem;
}
.width-54 {
  width: 3.375rem;
}
.width-55 {
  width: 3.4375rem;
}
.width-56 {
  width: 3.5rem;
}
.width-57 {
  width: 3.5625rem;
}
.width-58 {
  width: 3.625rem;
}
.width-59 {
  width: 3.6875rem;
}
.width-60 {
  width: 3.75rem;
}
.width-61 {
  width: 3.8125rem;
}
.width-62 {
  width: 3.875rem;
}
.width-63 {
  width: 3.9375rem;
}
.width-64 {
  width: 4rem;
}
.width-65 {
  width: 4.0625rem;
}
.width-66 {
  width: 4.125rem;
}
.width-67 {
  width: 4.1875rem;
}
.width-68 {
  width: 4.25rem;
}
.width-69 {
  width: 4.3125rem;
}
.width-70 {
  width: 4.375rem;
}
.width-71 {
  width: 4.4375rem;
}
.width-72 {
  width: 4.5rem;
}
.width-73 {
  width: 4.5625rem;
}
.width-74 {
  width: 4.625rem;
}
.width-75 {
  width: 4.6875rem;
}
.width-76 {
  width: 4.75rem;
}
.width-77 {
  width: 4.8125rem;
}
.width-78 {
  width: 4.875rem;
}
.width-79 {
  width: 4.9375rem;
}
.width-80 {
  width: 5rem;
}
.width-81 {
  width: 5.0625rem;
}
.width-82 {
  width: 5.125rem;
}
.width-83 {
  width: 5.1875rem;
}
.width-84 {
  width: 5.25rem;
}
.width-85 {
  width: 5.3125rem;
}
.width-86 {
  width: 5.375rem;
}
.width-87 {
  width: 5.4375rem;
}
.width-88 {
  width: 5.5rem;
}
.width-89 {
  width: 5.5625rem;
}
.width-90 {
  width: 5.625rem;
}
.width-91 {
  width: 5.6875rem;
}
.width-92 {
  width: 5.75rem;
}
.width-93 {
  width: 5.8125rem;
}
.width-94 {
  width: 5.875rem;
}
.width-95 {
  width: 5.9375rem;
}
.width-96 {
  width: 6rem;
}
.width-97 {
  width: 6.0625rem;
}
.width-98 {
  width: 6.125rem;
}
.width-99 {
  width: 6.1875rem;
}
.width-100 {
  width: 6.25rem;
}
.width-101 {
  width: 6.3125rem;
}
.width-102 {
  width: 6.375rem;
}
.width-103 {
  width: 6.4375rem;
}
.width-104 {
  width: 6.5rem;
}
.width-105 {
  width: 6.5625rem;
}
.width-106 {
  width: 6.625rem;
}
.width-107 {
  width: 6.6875rem;
}
.width-108 {
  width: 6.75rem;
}
.width-109 {
  width: 6.8125rem;
}
.width-110 {
  width: 6.875rem;
}
.width-111 {
  width: 6.9375rem;
}
.width-112 {
  width: 7rem;
}
.width-113 {
  width: 7.0625rem;
}
.width-114 {
  width: 7.125rem;
}
.width-115 {
  width: 7.1875rem;
}
.width-116 {
  width: 7.25rem;
}
.width-117 {
  width: 7.3125rem;
}
.width-118 {
  width: 7.375rem;
}
.width-119 {
  width: 7.4375rem;
}
.width-120 {
  width: 7.5rem;
}
.width-121 {
  width: 7.5625rem;
}
.width-122 {
  width: 7.625rem;
}
.width-123 {
  width: 7.6875rem;
}
.width-124 {
  width: 7.75rem;
}
.width-125 {
  width: 7.8125rem;
}
.width-126 {
  width: 7.875rem;
}
.width-127 {
  width: 7.9375rem;
}
.width-128 {
  width: 8rem;
}
.width-129 {
  width: 8.0625rem;
}
.width-130 {
  width: 8.125rem;
}
.width-131 {
  width: 8.1875rem;
}
.width-132 {
  width: 8.25rem;
}
.width-133 {
  width: 8.3125rem;
}
.width-134 {
  width: 8.375rem;
}
.width-135 {
  width: 8.4375rem;
}
.width-136 {
  width: 8.5rem;
}
.width-137 {
  width: 8.5625rem;
}
.width-138 {
  width: 8.625rem;
}
.width-139 {
  width: 8.6875rem;
}
.width-140 {
  width: 8.75rem;
}
.width-141 {
  width: 8.8125rem;
}
.width-142 {
  width: 8.875rem;
}
.width-143 {
  width: 8.9375rem;
}
.width-144 {
  width: 9rem;
}
.width-145 {
  width: 9.0625rem;
}
.width-146 {
  width: 9.125rem;
}
.width-147 {
  width: 9.1875rem;
}
.width-148 {
  width: 9.25rem;
}
.width-149 {
  width: 9.3125rem;
}
.width-150 {
  width: 9.375rem;
}
.width-151 {
  width: 9.4375rem;
}
.width-152 {
  width: 9.5rem;
}
.width-153 {
  width: 9.5625rem;
}
.width-154 {
  width: 9.625rem;
}
.width-155 {
  width: 9.6875rem;
}
.width-156 {
  width: 9.75rem;
}
.width-157 {
  width: 9.8125rem;
}
.width-158 {
  width: 9.875rem;
}
.width-159 {
  width: 9.9375rem;
}
.width-160 {
  width: 10rem;
}
.width-161 {
  width: 10.0625rem;
}
.width-162 {
  width: 10.125rem;
}
.width-163 {
  width: 10.1875rem;
}
.width-164 {
  width: 10.25rem;
}
.width-165 {
  width: 10.3125rem;
}
.width-166 {
  width: 10.375rem;
}
.width-167 {
  width: 10.4375rem;
}
.width-168 {
  width: 10.5rem;
}
.width-169 {
  width: 10.5625rem;
}
.width-170 {
  width: 10.625rem;
}
.width-171 {
  width: 10.6875rem;
}
.width-172 {
  width: 10.75rem;
}
.width-173 {
  width: 10.8125rem;
}
.width-174 {
  width: 10.875rem;
}
.width-175 {
  width: 10.9375rem;
}
.width-176 {
  width: 11rem;
}
.width-177 {
  width: 11.0625rem;
}
.width-178 {
  width: 11.125rem;
}
.width-179 {
  width: 11.1875rem;
}
.width-180 {
  width: 11.25rem;
}
.width-181 {
  width: 11.3125rem;
}
.width-182 {
  width: 11.375rem;
}
.width-183 {
  width: 11.4375rem;
}
.width-184 {
  width: 11.5rem;
}
.width-185 {
  width: 11.5625rem;
}
.width-186 {
  width: 11.625rem;
}
.width-187 {
  width: 11.6875rem;
}
.width-188 {
  width: 11.75rem;
}
.width-189 {
  width: 11.8125rem;
}
.width-190 {
  width: 11.875rem;
}
.width-191 {
  width: 11.9375rem;
}
.width-192 {
  width: 12rem;
}
.width-193 {
  width: 12.0625rem;
}
.width-194 {
  width: 12.125rem;
}
.width-195 {
  width: 12.1875rem;
}
.width-196 {
  width: 12.25rem;
}
.width-197 {
  width: 12.3125rem;
}
.width-198 {
  width: 12.375rem;
}
.width-199 {
  width: 12.4375rem;
}
.width-200 {
  width: 12.5rem;
}
.width-201 {
  width: 12.5625rem;
}
.width-202 {
  width: 12.625rem;
}
.width-203 {
  width: 12.6875rem;
}
.width-204 {
  width: 12.75rem;
}
.width-205 {
  width: 12.8125rem;
}
.width-206 {
  width: 12.875rem;
}
.width-207 {
  width: 12.9375rem;
}
.width-208 {
  width: 13rem;
}
.width-209 {
  width: 13.0625rem;
}
.width-210 {
  width: 13.125rem;
}
.width-211 {
  width: 13.1875rem;
}
.width-212 {
  width: 13.25rem;
}
.width-213 {
  width: 13.3125rem;
}
.width-214 {
  width: 13.375rem;
}
.width-215 {
  width: 13.4375rem;
}
.width-216 {
  width: 13.5rem;
}
.width-217 {
  width: 13.5625rem;
}
.width-218 {
  width: 13.625rem;
}
.width-219 {
  width: 13.6875rem;
}
.width-220 {
  width: 13.75rem;
}
.width-221 {
  width: 13.8125rem;
}
.width-222 {
  width: 13.875rem;
}
.width-223 {
  width: 13.9375rem;
}
.width-224 {
  width: 14rem;
}
.width-225 {
  width: 14.0625rem;
}
.width-226 {
  width: 14.125rem;
}
.width-227 {
  width: 14.1875rem;
}
.width-228 {
  width: 14.25rem;
}
.width-229 {
  width: 14.3125rem;
}
.width-230 {
  width: 14.375rem;
}
.width-231 {
  width: 14.4375rem;
}
.width-232 {
  width: 14.5rem;
}
.width-233 {
  width: 14.5625rem;
}
.width-234 {
  width: 14.625rem;
}
.width-235 {
  width: 14.6875rem;
}
.width-236 {
  width: 14.75rem;
}
.width-237 {
  width: 14.8125rem;
}
.width-238 {
  width: 14.875rem;
}
.width-239 {
  width: 14.9375rem;
}
.width-240 {
  width: 15rem;
}
.width-241 {
  width: 15.0625rem;
}
.width-242 {
  width: 15.125rem;
}
.width-243 {
  width: 15.1875rem;
}
.width-244 {
  width: 15.25rem;
}
.width-245 {
  width: 15.3125rem;
}
.width-246 {
  width: 15.375rem;
}
.width-247 {
  width: 15.4375rem;
}
.width-248 {
  width: 15.5rem;
}
.width-249 {
  width: 15.5625rem;
}
.width-250 {
  width: 15.625rem;
}
.width-251 {
  width: 15.6875rem;
}
.width-252 {
  width: 15.75rem;
}
.width-253 {
  width: 15.8125rem;
}
.width-254 {
  width: 15.875rem;
}
.width-255 {
  width: 15.9375rem;
}
.width-256 {
  width: 16rem;
}
.width-257 {
  width: 16.0625rem;
}
.width-258 {
  width: 16.125rem;
}
.width-259 {
  width: 16.1875rem;
}
.width-260 {
  width: 16.25rem;
}
.width-261 {
  width: 16.3125rem;
}
.width-262 {
  width: 16.375rem;
}
.width-263 {
  width: 16.4375rem;
}
.width-264 {
  width: 16.5rem;
}
.width-265 {
  width: 16.5625rem;
}
.width-266 {
  width: 16.625rem;
}
.width-267 {
  width: 16.6875rem;
}
.width-268 {
  width: 16.75rem;
}
.width-269 {
  width: 16.8125rem;
}
.width-270 {
  width: 16.875rem;
}
.width-271 {
  width: 16.9375rem;
}
.width-272 {
  width: 17rem;
}
.width-273 {
  width: 17.0625rem;
}
.width-274 {
  width: 17.125rem;
}
.width-275 {
  width: 17.1875rem;
}
.width-276 {
  width: 17.25rem;
}
.width-277 {
  width: 17.3125rem;
}
.width-278 {
  width: 17.375rem;
}
.width-279 {
  width: 17.4375rem;
}
.width-280 {
  width: 17.5rem;
}
.width-281 {
  width: 17.5625rem;
}
.width-282 {
  width: 17.625rem;
}
.width-283 {
  width: 17.6875rem;
}
.width-284 {
  width: 17.75rem;
}
.width-285 {
  width: 17.8125rem;
}
.width-286 {
  width: 17.875rem;
}
.width-287 {
  width: 17.9375rem;
}
.width-288 {
  width: 18rem;
}
.width-289 {
  width: 18.0625rem;
}
.width-290 {
  width: 18.125rem;
}
.width-291 {
  width: 18.1875rem;
}
.width-292 {
  width: 18.25rem;
}
.width-293 {
  width: 18.3125rem;
}
.width-294 {
  width: 18.375rem;
}
.width-295 {
  width: 18.4375rem;
}
.width-296 {
  width: 18.5rem;
}
.width-297 {
  width: 18.5625rem;
}
.width-298 {
  width: 18.625rem;
}
.width-299 {
  width: 18.6875rem;
}
.width-300 {
  width: 18.75rem;
}
.width-301 {
  width: 18.8125rem;
}
.width-302 {
  width: 18.875rem;
}
.width-303 {
  width: 18.9375rem;
}
.width-304 {
  width: 19rem;
}
.width-305 {
  width: 19.0625rem;
}
.width-306 {
  width: 19.125rem;
}
.width-307 {
  width: 19.1875rem;
}
.width-308 {
  width: 19.25rem;
}
.width-309 {
  width: 19.3125rem;
}
.width-310 {
  width: 19.375rem;
}
.width-311 {
  width: 19.4375rem;
}
.width-312 {
  width: 19.5rem;
}
.width-313 {
  width: 19.5625rem;
}
.width-314 {
  width: 19.625rem;
}
.width-315 {
  width: 19.6875rem;
}
.width-316 {
  width: 19.75rem;
}
.width-317 {
  width: 19.8125rem;
}
.width-318 {
  width: 19.875rem;
}
.width-319 {
  width: 19.9375rem;
}
.width-320 {
  width: 20rem;
}
.width-321 {
  width: 20.0625rem;
}
.width-322 {
  width: 20.125rem;
}
.width-323 {
  width: 20.1875rem;
}
.width-324 {
  width: 20.25rem;
}
.width-325 {
  width: 20.3125rem;
}
.width-326 {
  width: 20.375rem;
}
.width-327 {
  width: 20.4375rem;
}
.width-328 {
  width: 20.5rem;
}
.width-329 {
  width: 20.5625rem;
}
.width-330 {
  width: 20.625rem;
}
.width-331 {
  width: 20.6875rem;
}
.width-332 {
  width: 20.75rem;
}
.width-333 {
  width: 20.8125rem;
}
.width-334 {
  width: 20.875rem;
}
.width-335 {
  width: 20.9375rem;
}
.width-336 {
  width: 21rem;
}
.width-337 {
  width: 21.0625rem;
}
.width-338 {
  width: 21.125rem;
}
.width-339 {
  width: 21.1875rem;
}
.width-340 {
  width: 21.25rem;
}
.width-341 {
  width: 21.3125rem;
}
.width-342 {
  width: 21.375rem;
}
.width-343 {
  width: 21.4375rem;
}
.width-344 {
  width: 21.5rem;
}
.width-345 {
  width: 21.5625rem;
}
.width-346 {
  width: 21.625rem;
}
.width-347 {
  width: 21.6875rem;
}
.width-348 {
  width: 21.75rem;
}
.width-349 {
  width: 21.8125rem;
}
.width-350 {
  width: 21.875rem;
}
.width-351 {
  width: 21.9375rem;
}
.width-352 {
  width: 22rem;
}
.width-353 {
  width: 22.0625rem;
}
.width-354 {
  width: 22.125rem;
}
.width-355 {
  width: 22.1875rem;
}
.width-356 {
  width: 22.25rem;
}
.width-357 {
  width: 22.3125rem;
}
.width-358 {
  width: 22.375rem;
}
.width-359 {
  width: 22.4375rem;
}
.width-360 {
  width: 22.5rem;
}
.width-361 {
  width: 22.5625rem;
}
.width-362 {
  width: 22.625rem;
}
.width-363 {
  width: 22.6875rem;
}
.width-364 {
  width: 22.75rem;
}
.width-365 {
  width: 22.8125rem;
}
.width-366 {
  width: 22.875rem;
}
.width-367 {
  width: 22.9375rem;
}
.width-368 {
  width: 23rem;
}
.width-369 {
  width: 23.0625rem;
}
.width-370 {
  width: 23.125rem;
}
.width-371 {
  width: 23.1875rem;
}
.width-372 {
  width: 23.25rem;
}
.width-373 {
  width: 23.3125rem;
}
.width-374 {
  width: 23.375rem;
}
.width-375 {
  width: 23.4375rem;
}
.width-376 {
  width: 23.5rem;
}
.width-377 {
  width: 23.5625rem;
}
.width-378 {
  width: 23.625rem;
}
.width-379 {
  width: 23.6875rem;
}
.width-380 {
  width: 23.75rem;
}
.width-381 {
  width: 23.8125rem;
}
.width-382 {
  width: 23.875rem;
}
.width-383 {
  width: 23.9375rem;
}
.width-384 {
  width: 24rem;
}
.width-385 {
  width: 24.0625rem;
}
.width-386 {
  width: 24.125rem;
}
.width-387 {
  width: 24.1875rem;
}
.width-388 {
  width: 24.25rem;
}
.width-389 {
  width: 24.3125rem;
}
.width-390 {
  width: 24.375rem;
}
.width-391 {
  width: 24.4375rem;
}
.width-392 {
  width: 24.5rem;
}
.width-393 {
  width: 24.5625rem;
}
.width-394 {
  width: 24.625rem;
}
.width-395 {
  width: 24.6875rem;
}
.width-396 {
  width: 24.75rem;
}
.width-397 {
  width: 24.8125rem;
}
.width-398 {
  width: 24.875rem;
}
.width-399 {
  width: 24.9375rem;
}
.width-400 {
  width: 25rem;
}
.width-401 {
  width: 25.0625rem;
}
.width-402 {
  width: 25.125rem;
}
.width-403 {
  width: 25.1875rem;
}
.width-404 {
  width: 25.25rem;
}
.width-405 {
  width: 25.3125rem;
}
.width-406 {
  width: 25.375rem;
}
.width-407 {
  width: 25.4375rem;
}
.width-408 {
  width: 25.5rem;
}
.width-409 {
  width: 25.5625rem;
}
.width-410 {
  width: 25.625rem;
}
.width-411 {
  width: 25.6875rem;
}
.width-412 {
  width: 25.75rem;
}
.width-413 {
  width: 25.8125rem;
}
.width-414 {
  width: 25.875rem;
}
.width-415 {
  width: 25.9375rem;
}
.width-416 {
  width: 26rem;
}
.width-417 {
  width: 26.0625rem;
}
.width-418 {
  width: 26.125rem;
}
.width-419 {
  width: 26.1875rem;
}
.width-420 {
  width: 26.25rem;
}
.width-421 {
  width: 26.3125rem;
}
.width-422 {
  width: 26.375rem;
}
.width-423 {
  width: 26.4375rem;
}
.width-424 {
  width: 26.5rem;
}
.width-425 {
  width: 26.5625rem;
}
.width-426 {
  width: 26.625rem;
}
.width-427 {
  width: 26.6875rem;
}
.width-428 {
  width: 26.75rem;
}
.width-429 {
  width: 26.8125rem;
}
.width-430 {
  width: 26.875rem;
}
.width-431 {
  width: 26.9375rem;
}
.width-432 {
  width: 27rem;
}
.width-433 {
  width: 27.0625rem;
}
.width-434 {
  width: 27.125rem;
}
.width-435 {
  width: 27.1875rem;
}
.width-436 {
  width: 27.25rem;
}
.width-437 {
  width: 27.3125rem;
}
.width-438 {
  width: 27.375rem;
}
.width-439 {
  width: 27.4375rem;
}
.width-440 {
  width: 27.5rem;
}
.width-441 {
  width: 27.5625rem;
}
.width-442 {
  width: 27.625rem;
}
.width-443 {
  width: 27.6875rem;
}
.width-444 {
  width: 27.75rem;
}
.width-445 {
  width: 27.8125rem;
}
.width-446 {
  width: 27.875rem;
}
.width-447 {
  width: 27.9375rem;
}
.width-448 {
  width: 28rem;
}
.width-449 {
  width: 28.0625rem;
}
.width-450 {
  width: 28.125rem;
}
.width-451 {
  width: 28.1875rem;
}
.width-452 {
  width: 28.25rem;
}
.width-453 {
  width: 28.3125rem;
}
.width-454 {
  width: 28.375rem;
}
.width-455 {
  width: 28.4375rem;
}
.width-456 {
  width: 28.5rem;
}
.width-457 {
  width: 28.5625rem;
}
.width-458 {
  width: 28.625rem;
}
.width-459 {
  width: 28.6875rem;
}
.width-460 {
  width: 28.75rem;
}
.width-461 {
  width: 28.8125rem;
}
.width-462 {
  width: 28.875rem;
}
.width-463 {
  width: 28.9375rem;
}
.width-464 {
  width: 29rem;
}
.width-465 {
  width: 29.0625rem;
}
.width-466 {
  width: 29.125rem;
}
.width-467 {
  width: 29.1875rem;
}
.width-468 {
  width: 29.25rem;
}
.width-469 {
  width: 29.3125rem;
}
.width-470 {
  width: 29.375rem;
}
.width-471 {
  width: 29.4375rem;
}
.width-472 {
  width: 29.5rem;
}
.width-473 {
  width: 29.5625rem;
}
.width-474 {
  width: 29.625rem;
}
.width-475 {
  width: 29.6875rem;
}
.width-476 {
  width: 29.75rem;
}
.width-477 {
  width: 29.8125rem;
}
.width-478 {
  width: 29.875rem;
}
.width-479 {
  width: 29.9375rem;
}
.width-480 {
  width: 30rem;
}
.width-481 {
  width: 30.0625rem;
}
.width-482 {
  width: 30.125rem;
}
.width-483 {
  width: 30.1875rem;
}
.width-484 {
  width: 30.25rem;
}
.width-485 {
  width: 30.3125rem;
}
.width-486 {
  width: 30.375rem;
}
.width-487 {
  width: 30.4375rem;
}
.width-488 {
  width: 30.5rem;
}
.width-489 {
  width: 30.5625rem;
}
.width-490 {
  width: 30.625rem;
}
.width-491 {
  width: 30.6875rem;
}
.width-492 {
  width: 30.75rem;
}
.width-493 {
  width: 30.8125rem;
}
.width-494 {
  width: 30.875rem;
}
.width-495 {
  width: 30.9375rem;
}
.width-496 {
  width: 31rem;
}
.width-497 {
  width: 31.0625rem;
}
.width-498 {
  width: 31.125rem;
}
.width-499 {
  width: 31.1875rem;
}
.width-500 {
  width: 31.25rem;
}
.width-501 {
  width: 31.3125rem;
}
.width-502 {
  width: 31.375rem;
}
.width-503 {
  width: 31.4375rem;
}
.width-504 {
  width: 31.5rem;
}
.width-505 {
  width: 31.5625rem;
}
.width-506 {
  width: 31.625rem;
}
.width-507 {
  width: 31.6875rem;
}
.width-508 {
  width: 31.75rem;
}
.width-509 {
  width: 31.8125rem;
}
.width-510 {
  width: 31.875rem;
}
.width-511 {
  width: 31.9375rem;
}
.width-512 {
  width: 32rem;
}
.width-513 {
  width: 32.0625rem;
}
.width-514 {
  width: 32.125rem;
}
.width-515 {
  width: 32.1875rem;
}
.width-516 {
  width: 32.25rem;
}
.width-517 {
  width: 32.3125rem;
}
.width-518 {
  width: 32.375rem;
}
.width-519 {
  width: 32.4375rem;
}
.width-520 {
  width: 32.5rem;
}
.width-521 {
  width: 32.5625rem;
}
.width-522 {
  width: 32.625rem;
}
.width-523 {
  width: 32.6875rem;
}
.width-524 {
  width: 32.75rem;
}
.width-525 {
  width: 32.8125rem;
}
.width-526 {
  width: 32.875rem;
}
.width-527 {
  width: 32.9375rem;
}
.width-528 {
  width: 33rem;
}
.width-529 {
  width: 33.0625rem;
}
.width-530 {
  width: 33.125rem;
}
.width-531 {
  width: 33.1875rem;
}
.width-532 {
  width: 33.25rem;
}
.width-533 {
  width: 33.3125rem;
}
.width-534 {
  width: 33.375rem;
}
.width-535 {
  width: 33.4375rem;
}
.width-536 {
  width: 33.5rem;
}
.width-537 {
  width: 33.5625rem;
}
.width-538 {
  width: 33.625rem;
}
.width-539 {
  width: 33.6875rem;
}
.width-540 {
  width: 33.75rem;
}
.width-541 {
  width: 33.8125rem;
}
.width-542 {
  width: 33.875rem;
}
.width-543 {
  width: 33.9375rem;
}
.width-544 {
  width: 34rem;
}
.width-545 {
  width: 34.0625rem;
}
.width-546 {
  width: 34.125rem;
}
.width-547 {
  width: 34.1875rem;
}
.width-548 {
  width: 34.25rem;
}
.width-549 {
  width: 34.3125rem;
}
.width-550 {
  width: 34.375rem;
}
.width-551 {
  width: 34.4375rem;
}
.width-552 {
  width: 34.5rem;
}
.width-553 {
  width: 34.5625rem;
}
.width-554 {
  width: 34.625rem;
}
.width-555 {
  width: 34.6875rem;
}
.width-556 {
  width: 34.75rem;
}
.width-557 {
  width: 34.8125rem;
}
.width-558 {
  width: 34.875rem;
}
.width-559 {
  width: 34.9375rem;
}
.width-560 {
  width: 35rem;
}
.width-561 {
  width: 35.0625rem;
}
.width-562 {
  width: 35.125rem;
}
.width-563 {
  width: 35.1875rem;
}
.width-564 {
  width: 35.25rem;
}
.width-565 {
  width: 35.3125rem;
}
.width-566 {
  width: 35.375rem;
}
.width-567 {
  width: 35.4375rem;
}
.width-568 {
  width: 35.5rem;
}
.width-569 {
  width: 35.5625rem;
}
.width-570 {
  width: 35.625rem;
}
.width-571 {
  width: 35.6875rem;
}
.width-572 {
  width: 35.75rem;
}
.width-573 {
  width: 35.8125rem;
}
.width-574 {
  width: 35.875rem;
}
.width-575 {
  width: 35.9375rem;
}
.width-576 {
  width: 36rem;
}
.width-577 {
  width: 36.0625rem;
}
.width-578 {
  width: 36.125rem;
}
.width-579 {
  width: 36.1875rem;
}
.width-580 {
  width: 36.25rem;
}
.width-581 {
  width: 36.3125rem;
}
.width-582 {
  width: 36.375rem;
}
.width-583 {
  width: 36.4375rem;
}
.width-584 {
  width: 36.5rem;
}
.width-585 {
  width: 36.5625rem;
}
.width-586 {
  width: 36.625rem;
}
.width-587 {
  width: 36.6875rem;
}
.width-588 {
  width: 36.75rem;
}
.width-589 {
  width: 36.8125rem;
}
.width-590 {
  width: 36.875rem;
}
.width-591 {
  width: 36.9375rem;
}
.width-592 {
  width: 37rem;
}
.width-593 {
  width: 37.0625rem;
}
.width-594 {
  width: 37.125rem;
}
.width-595 {
  width: 37.1875rem;
}
.width-596 {
  width: 37.25rem;
}
.width-597 {
  width: 37.3125rem;
}
.width-598 {
  width: 37.375rem;
}
.width-599 {
  width: 37.4375rem;
}
.width-600 {
  width: 37.5rem;
}
.width-601 {
  width: 37.5625rem;
}
.width-602 {
  width: 37.625rem;
}
.width-603 {
  width: 37.6875rem;
}
.width-604 {
  width: 37.75rem;
}
.width-605 {
  width: 37.8125rem;
}
.width-606 {
  width: 37.875rem;
}
.width-607 {
  width: 37.9375rem;
}
.width-608 {
  width: 38rem;
}
.width-609 {
  width: 38.0625rem;
}
.width-610 {
  width: 38.125rem;
}
.width-611 {
  width: 38.1875rem;
}
.width-612 {
  width: 38.25rem;
}
.width-613 {
  width: 38.3125rem;
}
.width-614 {
  width: 38.375rem;
}
.width-615 {
  width: 38.4375rem;
}
.width-616 {
  width: 38.5rem;
}
.width-617 {
  width: 38.5625rem;
}
.width-618 {
  width: 38.625rem;
}
.width-619 {
  width: 38.6875rem;
}
.width-620 {
  width: 38.75rem;
}
.width-621 {
  width: 38.8125rem;
}
.width-622 {
  width: 38.875rem;
}
.width-623 {
  width: 38.9375rem;
}
.width-624 {
  width: 39rem;
}
.width-625 {
  width: 39.0625rem;
}
.width-626 {
  width: 39.125rem;
}
.width-627 {
  width: 39.1875rem;
}
.width-628 {
  width: 39.25rem;
}
.width-629 {
  width: 39.3125rem;
}
.width-630 {
  width: 39.375rem;
}
.width-631 {
  width: 39.4375rem;
}
.width-632 {
  width: 39.5rem;
}
.width-633 {
  width: 39.5625rem;
}
.width-634 {
  width: 39.625rem;
}
.width-635 {
  width: 39.6875rem;
}
.width-636 {
  width: 39.75rem;
}
.width-637 {
  width: 39.8125rem;
}
.width-638 {
  width: 39.875rem;
}
.width-639 {
  width: 39.9375rem;
}
.width-640 {
  width: 40rem;
}
.width-641 {
  width: 40.0625rem;
}
.width-642 {
  width: 40.125rem;
}
.width-643 {
  width: 40.1875rem;
}
.width-644 {
  width: 40.25rem;
}
.width-645 {
  width: 40.3125rem;
}
.width-646 {
  width: 40.375rem;
}
.width-647 {
  width: 40.4375rem;
}
.width-648 {
  width: 40.5rem;
}
.width-649 {
  width: 40.5625rem;
}
.width-650 {
  width: 40.625rem;
}
.width-651 {
  width: 40.6875rem;
}
.width-652 {
  width: 40.75rem;
}
.width-653 {
  width: 40.8125rem;
}
.width-654 {
  width: 40.875rem;
}
.width-655 {
  width: 40.9375rem;
}
.width-656 {
  width: 41rem;
}
.width-657 {
  width: 41.0625rem;
}
.width-658 {
  width: 41.125rem;
}
.width-659 {
  width: 41.1875rem;
}
.width-660 {
  width: 41.25rem;
}
.width-661 {
  width: 41.3125rem;
}
.width-662 {
  width: 41.375rem;
}
.width-663 {
  width: 41.4375rem;
}
.width-664 {
  width: 41.5rem;
}
.width-665 {
  width: 41.5625rem;
}
.width-666 {
  width: 41.625rem;
}
.width-667 {
  width: 41.6875rem;
}
.width-668 {
  width: 41.75rem;
}
.width-669 {
  width: 41.8125rem;
}
.width-670 {
  width: 41.875rem;
}
.width-671 {
  width: 41.9375rem;
}
.width-672 {
  width: 42rem;
}
.width-673 {
  width: 42.0625rem;
}
.width-674 {
  width: 42.125rem;
}
.width-675 {
  width: 42.1875rem;
}
.width-676 {
  width: 42.25rem;
}
.width-677 {
  width: 42.3125rem;
}
.width-678 {
  width: 42.375rem;
}
.width-679 {
  width: 42.4375rem;
}
.width-680 {
  width: 42.5rem;
}
.width-681 {
  width: 42.5625rem;
}
.width-682 {
  width: 42.625rem;
}
.width-683 {
  width: 42.6875rem;
}
.width-684 {
  width: 42.75rem;
}
.width-685 {
  width: 42.8125rem;
}
.width-686 {
  width: 42.875rem;
}
.width-687 {
  width: 42.9375rem;
}
.width-688 {
  width: 43rem;
}
.width-689 {
  width: 43.0625rem;
}
.width-690 {
  width: 43.125rem;
}
.width-691 {
  width: 43.1875rem;
}
.width-692 {
  width: 43.25rem;
}
.width-693 {
  width: 43.3125rem;
}
.width-694 {
  width: 43.375rem;
}
.width-695 {
  width: 43.4375rem;
}
.width-696 {
  width: 43.5rem;
}
.width-697 {
  width: 43.5625rem;
}
.width-698 {
  width: 43.625rem;
}
.width-699 {
  width: 43.6875rem;
}
.width-700 {
  width: 43.75rem;
}
.width-701 {
  width: 43.8125rem;
}
.width-702 {
  width: 43.875rem;
}
.width-703 {
  width: 43.9375rem;
}
.width-704 {
  width: 44rem;
}
.width-705 {
  width: 44.0625rem;
}
.width-706 {
  width: 44.125rem;
}
.width-707 {
  width: 44.1875rem;
}
.width-708 {
  width: 44.25rem;
}
.width-709 {
  width: 44.3125rem;
}
.width-710 {
  width: 44.375rem;
}
.width-711 {
  width: 44.4375rem;
}
.width-712 {
  width: 44.5rem;
}
.width-713 {
  width: 44.5625rem;
}
.width-714 {
  width: 44.625rem;
}
.width-715 {
  width: 44.6875rem;
}
.width-716 {
  width: 44.75rem;
}
.width-717 {
  width: 44.8125rem;
}
.width-718 {
  width: 44.875rem;
}
.width-719 {
  width: 44.9375rem;
}
.width-720 {
  width: 45rem;
}
.width-721 {
  width: 45.0625rem;
}
.width-722 {
  width: 45.125rem;
}
.width-723 {
  width: 45.1875rem;
}
.width-724 {
  width: 45.25rem;
}
.width-725 {
  width: 45.3125rem;
}
.width-726 {
  width: 45.375rem;
}
.width-727 {
  width: 45.4375rem;
}
.width-728 {
  width: 45.5rem;
}
.width-729 {
  width: 45.5625rem;
}
.width-730 {
  width: 45.625rem;
}
.width-731 {
  width: 45.6875rem;
}
.width-732 {
  width: 45.75rem;
}
.width-733 {
  width: 45.8125rem;
}
.width-734 {
  width: 45.875rem;
}
.width-735 {
  width: 45.9375rem;
}
.width-736 {
  width: 46rem;
}
.width-737 {
  width: 46.0625rem;
}
.width-738 {
  width: 46.125rem;
}
.width-739 {
  width: 46.1875rem;
}
.width-740 {
  width: 46.25rem;
}
.width-741 {
  width: 46.3125rem;
}
.width-742 {
  width: 46.375rem;
}
.width-743 {
  width: 46.4375rem;
}
.width-744 {
  width: 46.5rem;
}
.width-745 {
  width: 46.5625rem;
}
.width-746 {
  width: 46.625rem;
}
.width-747 {
  width: 46.6875rem;
}
.width-748 {
  width: 46.75rem;
}
.width-749 {
  width: 46.8125rem;
}
.width-750 {
  width: 46.875rem;
}
.width-751 {
  width: 46.9375rem;
}
.width-752 {
  width: 47rem;
}
.width-753 {
  width: 47.0625rem;
}
.width-754 {
  width: 47.125rem;
}
.width-755 {
  width: 47.1875rem;
}
.width-756 {
  width: 47.25rem;
}
.width-757 {
  width: 47.3125rem;
}
.width-758 {
  width: 47.375rem;
}
.width-759 {
  width: 47.4375rem;
}
.width-760 {
  width: 47.5rem;
}
.width-761 {
  width: 47.5625rem;
}
.width-762 {
  width: 47.625rem;
}
.width-763 {
  width: 47.6875rem;
}
.width-764 {
  width: 47.75rem;
}
.width-765 {
  width: 47.8125rem;
}
.width-766 {
  width: 47.875rem;
}
.width-767 {
  width: 47.9375rem;
}
.width-768 {
  width: 48rem;
}
.width-769 {
  width: 48.0625rem;
}
.width-770 {
  width: 48.125rem;
}
.width-771 {
  width: 48.1875rem;
}
.width-772 {
  width: 48.25rem;
}
.width-773 {
  width: 48.3125rem;
}
.width-774 {
  width: 48.375rem;
}
.width-775 {
  width: 48.4375rem;
}
.width-776 {
  width: 48.5rem;
}
.width-777 {
  width: 48.5625rem;
}
.width-778 {
  width: 48.625rem;
}
.width-779 {
  width: 48.6875rem;
}
.width-780 {
  width: 48.75rem;
}
.width-781 {
  width: 48.8125rem;
}
.width-782 {
  width: 48.875rem;
}
.width-783 {
  width: 48.9375rem;
}
.width-784 {
  width: 49rem;
}
.width-785 {
  width: 49.0625rem;
}
.width-786 {
  width: 49.125rem;
}
.width-787 {
  width: 49.1875rem;
}
.width-788 {
  width: 49.25rem;
}
.width-789 {
  width: 49.3125rem;
}
.width-790 {
  width: 49.375rem;
}
.width-791 {
  width: 49.4375rem;
}
.width-792 {
  width: 49.5rem;
}
.width-793 {
  width: 49.5625rem;
}
.width-794 {
  width: 49.625rem;
}
.width-795 {
  width: 49.6875rem;
}
.width-796 {
  width: 49.75rem;
}
.width-797 {
  width: 49.8125rem;
}
.width-798 {
  width: 49.875rem;
}
.width-799 {
  width: 49.9375rem;
}
.width-800 {
  width: 50rem;
}
.width-801 {
  width: 50.0625rem;
}
.width-802 {
  width: 50.125rem;
}
.width-803 {
  width: 50.1875rem;
}
.width-804 {
  width: 50.25rem;
}
.width-805 {
  width: 50.3125rem;
}
.width-806 {
  width: 50.375rem;
}
.width-807 {
  width: 50.4375rem;
}
.width-808 {
  width: 50.5rem;
}
.width-809 {
  width: 50.5625rem;
}
.width-810 {
  width: 50.625rem;
}
.width-811 {
  width: 50.6875rem;
}
.width-812 {
  width: 50.75rem;
}
.width-813 {
  width: 50.8125rem;
}
.width-814 {
  width: 50.875rem;
}
.width-815 {
  width: 50.9375rem;
}
.width-816 {
  width: 51rem;
}
.width-817 {
  width: 51.0625rem;
}
.width-818 {
  width: 51.125rem;
}
.width-819 {
  width: 51.1875rem;
}
.width-820 {
  width: 51.25rem;
}
.width-821 {
  width: 51.3125rem;
}
.width-822 {
  width: 51.375rem;
}
.width-823 {
  width: 51.4375rem;
}
.width-824 {
  width: 51.5rem;
}
.width-825 {
  width: 51.5625rem;
}
.width-826 {
  width: 51.625rem;
}
.width-827 {
  width: 51.6875rem;
}
.width-828 {
  width: 51.75rem;
}
.width-829 {
  width: 51.8125rem;
}
.width-830 {
  width: 51.875rem;
}
.width-831 {
  width: 51.9375rem;
}
.width-832 {
  width: 52rem;
}
.width-833 {
  width: 52.0625rem;
}
.width-834 {
  width: 52.125rem;
}
.width-835 {
  width: 52.1875rem;
}
.width-836 {
  width: 52.25rem;
}
.width-837 {
  width: 52.3125rem;
}
.width-838 {
  width: 52.375rem;
}
.width-839 {
  width: 52.4375rem;
}
.width-840 {
  width: 52.5rem;
}
.width-841 {
  width: 52.5625rem;
}
.width-842 {
  width: 52.625rem;
}
.width-843 {
  width: 52.6875rem;
}
.width-844 {
  width: 52.75rem;
}
.width-845 {
  width: 52.8125rem;
}
.width-846 {
  width: 52.875rem;
}
.width-847 {
  width: 52.9375rem;
}
.width-848 {
  width: 53rem;
}
.width-849 {
  width: 53.0625rem;
}
.width-850 {
  width: 53.125rem;
}
.width-851 {
  width: 53.1875rem;
}
.width-852 {
  width: 53.25rem;
}
.width-853 {
  width: 53.3125rem;
}
.width-854 {
  width: 53.375rem;
}
.width-855 {
  width: 53.4375rem;
}
.width-856 {
  width: 53.5rem;
}
.width-857 {
  width: 53.5625rem;
}
.width-858 {
  width: 53.625rem;
}
.width-859 {
  width: 53.6875rem;
}
.width-860 {
  width: 53.75rem;
}
.width-861 {
  width: 53.8125rem;
}
.width-862 {
  width: 53.875rem;
}
.width-863 {
  width: 53.9375rem;
}
.width-864 {
  width: 54rem;
}
.width-865 {
  width: 54.0625rem;
}
.width-866 {
  width: 54.125rem;
}
.width-867 {
  width: 54.1875rem;
}
.width-868 {
  width: 54.25rem;
}
.width-869 {
  width: 54.3125rem;
}
.width-870 {
  width: 54.375rem;
}
.width-871 {
  width: 54.4375rem;
}
.width-872 {
  width: 54.5rem;
}
.width-873 {
  width: 54.5625rem;
}
.width-874 {
  width: 54.625rem;
}
.width-875 {
  width: 54.6875rem;
}
.width-876 {
  width: 54.75rem;
}
.width-877 {
  width: 54.8125rem;
}
.width-878 {
  width: 54.875rem;
}
.width-879 {
  width: 54.9375rem;
}
.width-880 {
  width: 55rem;
}
.width-881 {
  width: 55.0625rem;
}
.width-882 {
  width: 55.125rem;
}
.width-883 {
  width: 55.1875rem;
}
.width-884 {
  width: 55.25rem;
}
.width-885 {
  width: 55.3125rem;
}
.width-886 {
  width: 55.375rem;
}
.width-887 {
  width: 55.4375rem;
}
.width-888 {
  width: 55.5rem;
}
.width-889 {
  width: 55.5625rem;
}
.width-890 {
  width: 55.625rem;
}
.width-891 {
  width: 55.6875rem;
}
.width-892 {
  width: 55.75rem;
}
.width-893 {
  width: 55.8125rem;
}
.width-894 {
  width: 55.875rem;
}
.width-895 {
  width: 55.9375rem;
}
.width-896 {
  width: 56rem;
}
.width-897 {
  width: 56.0625rem;
}
.width-898 {
  width: 56.125rem;
}
.width-899 {
  width: 56.1875rem;
}
.width-900 {
  width: 56.25rem;
}
.width-901 {
  width: 56.3125rem;
}
.width-902 {
  width: 56.375rem;
}
.width-903 {
  width: 56.4375rem;
}
.width-904 {
  width: 56.5rem;
}
.width-905 {
  width: 56.5625rem;
}
.width-906 {
  width: 56.625rem;
}
.width-907 {
  width: 56.6875rem;
}
.width-908 {
  width: 56.75rem;
}
.width-909 {
  width: 56.8125rem;
}
.width-910 {
  width: 56.875rem;
}
.width-911 {
  width: 56.9375rem;
}
.width-912 {
  width: 57rem;
}
.width-913 {
  width: 57.0625rem;
}
.width-914 {
  width: 57.125rem;
}
.width-915 {
  width: 57.1875rem;
}
.width-916 {
  width: 57.25rem;
}
.width-917 {
  width: 57.3125rem;
}
.width-918 {
  width: 57.375rem;
}
.width-919 {
  width: 57.4375rem;
}
.width-920 {
  width: 57.5rem;
}
.width-921 {
  width: 57.5625rem;
}
.width-922 {
  width: 57.625rem;
}
.width-923 {
  width: 57.6875rem;
}
.width-924 {
  width: 57.75rem;
}
.width-925 {
  width: 57.8125rem;
}
.width-926 {
  width: 57.875rem;
}
.width-927 {
  width: 57.9375rem;
}
.width-928 {
  width: 58rem;
}
.width-929 {
  width: 58.0625rem;
}
.width-930 {
  width: 58.125rem;
}
.width-931 {
  width: 58.1875rem;
}
.width-932 {
  width: 58.25rem;
}
.width-933 {
  width: 58.3125rem;
}
.width-934 {
  width: 58.375rem;
}
.width-935 {
  width: 58.4375rem;
}
.width-936 {
  width: 58.5rem;
}
.width-937 {
  width: 58.5625rem;
}
.width-938 {
  width: 58.625rem;
}
.width-939 {
  width: 58.6875rem;
}
.width-940 {
  width: 58.75rem;
}
.width-941 {
  width: 58.8125rem;
}
.width-942 {
  width: 58.875rem;
}
.width-943 {
  width: 58.9375rem;
}
.width-944 {
  width: 59rem;
}
.width-945 {
  width: 59.0625rem;
}
.width-946 {
  width: 59.125rem;
}
.width-947 {
  width: 59.1875rem;
}
.width-948 {
  width: 59.25rem;
}
.width-949 {
  width: 59.3125rem;
}
.width-950 {
  width: 59.375rem;
}
.width-951 {
  width: 59.4375rem;
}
.width-952 {
  width: 59.5rem;
}
.width-953 {
  width: 59.5625rem;
}
.width-954 {
  width: 59.625rem;
}
.width-955 {
  width: 59.6875rem;
}
.width-956 {
  width: 59.75rem;
}
.width-957 {
  width: 59.8125rem;
}
.width-958 {
  width: 59.875rem;
}
.width-959 {
  width: 59.9375rem;
}
.width-960 {
  width: 60rem;
}
.width-961 {
  width: 60.0625rem;
}
.width-962 {
  width: 60.125rem;
}
.width-963 {
  width: 60.1875rem;
}
.width-964 {
  width: 60.25rem;
}
.width-965 {
  width: 60.3125rem;
}
.width-966 {
  width: 60.375rem;
}
.width-967 {
  width: 60.4375rem;
}
.width-968 {
  width: 60.5rem;
}
.width-969 {
  width: 60.5625rem;
}
.width-970 {
  width: 60.625rem;
}
.width-971 {
  width: 60.6875rem;
}
.width-972 {
  width: 60.75rem;
}
.width-973 {
  width: 60.8125rem;
}
.width-974 {
  width: 60.875rem;
}
.width-975 {
  width: 60.9375rem;
}
.width-976 {
  width: 61rem;
}
.width-977 {
  width: 61.0625rem;
}
.width-978 {
  width: 61.125rem;
}
.width-979 {
  width: 61.1875rem;
}
.width-980 {
  width: 61.25rem;
}
.width-981 {
  width: 61.3125rem;
}
.width-982 {
  width: 61.375rem;
}
.width-983 {
  width: 61.4375rem;
}
.width-984 {
  width: 61.5rem;
}
.width-985 {
  width: 61.5625rem;
}
.width-986 {
  width: 61.625rem;
}
.width-987 {
  width: 61.6875rem;
}
.width-988 {
  width: 61.75rem;
}
.width-989 {
  width: 61.8125rem;
}
.width-990 {
  width: 61.875rem;
}
.width-991 {
  width: 61.9375rem;
}
.width-992 {
  width: 62rem;
}
.width-993 {
  width: 62.0625rem;
}
.width-994 {
  width: 62.125rem;
}
.width-995 {
  width: 62.1875rem;
}
.width-996 {
  width: 62.25rem;
}
.width-997 {
  width: 62.3125rem;
}
.width-998 {
  width: 62.375rem;
}
.width-999 {
  width: 62.4375rem;
}
.width-1000 {
  width: 62.5rem;
}

.height-1 {
  height: 0.0625rem;
}
.height-2 {
  height: 0.125rem;
}
.height-3 {
  height: 0.1875rem;
}
.height-4 {
  height: 0.25rem;
}
.height-5 {
  height: 0.3125rem;
}
.height-6 {
  height: 0.375rem;
}
.height-7 {
  height: 0.4375rem;
}
.height-8 {
  height: 0.5rem;
}
.height-9 {
  height: 0.5625rem;
}
.height-10 {
  height: 0.625rem;
}
.height-11 {
  height: 0.6875rem;
}
.height-12 {
  height: 0.75rem;
}
.height-13 {
  height: 0.8125rem;
}
.height-14 {
  height: 0.875rem;
}
.height-15 {
  height: 0.9375rem;
}
.height-16 {
  height: 1rem;
}
.height-17 {
  height: 1.0625rem;
}
.height-18 {
  height: 1.125rem;
}
.height-19 {
  height: 1.1875rem;
}
.height-20 {
  height: 1.25rem;
}
.height-21 {
  height: 1.3125rem;
}
.height-22 {
  height: 1.375rem;
}
.height-23 {
  height: 1.4375rem;
}
.height-24 {
  height: 1.5rem;
}
.height-25 {
  height: 1.5625rem;
}
.height-26 {
  height: 1.625rem;
}
.height-27 {
  height: 1.6875rem;
}
.height-28 {
  height: 1.75rem;
}
.height-29 {
  height: 1.8125rem;
}
.height-30 {
  height: 1.875rem;
}
.height-31 {
  height: 1.9375rem;
}
.height-32 {
  height: 2rem;
}
.height-33 {
  height: 2.0625rem;
}
.height-34 {
  height: 2.125rem;
}
.height-35 {
  height: 2.1875rem;
}
.height-36 {
  height: 2.25rem;
}
.height-37 {
  height: 2.3125rem;
}
.height-38 {
  height: 2.375rem;
}
.height-39 {
  height: 2.4375rem;
}
.height-40 {
  height: 2.5rem;
}
.height-41 {
  height: 2.5625rem;
}
.height-42 {
  height: 2.625rem;
}
.height-43 {
  height: 2.6875rem;
}
.height-44 {
  height: 2.75rem;
}
.height-45 {
  height: 2.8125rem;
}
.height-46 {
  height: 2.875rem;
}
.height-47 {
  height: 2.9375rem;
}
.height-48 {
  height: 3rem;
}
.height-49 {
  height: 3.0625rem;
}
.height-50 {
  height: 3.125rem;
}
.height-51 {
  height: 3.1875rem;
}
.height-52 {
  height: 3.25rem;
}
.height-53 {
  height: 3.3125rem;
}
.height-54 {
  height: 3.375rem;
}
.height-55 {
  height: 3.4375rem;
}
.height-56 {
  height: 3.5rem;
}
.height-57 {
  height: 3.5625rem;
}
.height-58 {
  height: 3.625rem;
}
.height-59 {
  height: 3.6875rem;
}
.height-60 {
  height: 3.75rem;
}
.height-61 {
  height: 3.8125rem;
}
.height-62 {
  height: 3.875rem;
}
.height-63 {
  height: 3.9375rem;
}
.height-64 {
  height: 4rem;
}
.height-65 {
  height: 4.0625rem;
}
.height-66 {
  height: 4.125rem;
}
.height-67 {
  height: 4.1875rem;
}
.height-68 {
  height: 4.25rem;
}
.height-69 {
  height: 4.3125rem;
}
.height-70 {
  height: 4.375rem;
}
.height-71 {
  height: 4.4375rem;
}
.height-72 {
  height: 4.5rem;
}
.height-73 {
  height: 4.5625rem;
}
.height-74 {
  height: 4.625rem;
}
.height-75 {
  height: 4.6875rem;
}
.height-76 {
  height: 4.75rem;
}
.height-77 {
  height: 4.8125rem;
}
.height-78 {
  height: 4.875rem;
}
.height-79 {
  height: 4.9375rem;
}
.height-80 {
  height: 5rem;
}
.height-81 {
  height: 5.0625rem;
}
.height-82 {
  height: 5.125rem;
}
.height-83 {
  height: 5.1875rem;
}
.height-84 {
  height: 5.25rem;
}
.height-85 {
  height: 5.3125rem;
}
.height-86 {
  height: 5.375rem;
}
.height-87 {
  height: 5.4375rem;
}
.height-88 {
  height: 5.5rem;
}
.height-89 {
  height: 5.5625rem;
}
.height-90 {
  height: 5.625rem;
}
.height-91 {
  height: 5.6875rem;
}
.height-92 {
  height: 5.75rem;
}
.height-93 {
  height: 5.8125rem;
}
.height-94 {
  height: 5.875rem;
}
.height-95 {
  height: 5.9375rem;
}
.height-96 {
  height: 6rem;
}
.height-97 {
  height: 6.0625rem;
}
.height-98 {
  height: 6.125rem;
}
.height-99 {
  height: 6.1875rem;
}
.height-100 {
  height: 6.25rem;
}
.height-101 {
  height: 6.3125rem;
}
.height-102 {
  height: 6.375rem;
}
.height-103 {
  height: 6.4375rem;
}
.height-104 {
  height: 6.5rem;
}
.height-105 {
  height: 6.5625rem;
}
.height-106 {
  height: 6.625rem;
}
.height-107 {
  height: 6.6875rem;
}
.height-108 {
  height: 6.75rem;
}
.height-109 {
  height: 6.8125rem;
}
.height-110 {
  height: 6.875rem;
}
.height-111 {
  height: 6.9375rem;
}
.height-112 {
  height: 7rem;
}
.height-113 {
  height: 7.0625rem;
}
.height-114 {
  height: 7.125rem;
}
.height-115 {
  height: 7.1875rem;
}
.height-116 {
  height: 7.25rem;
}
.height-117 {
  height: 7.3125rem;
}
.height-118 {
  height: 7.375rem;
}
.height-119 {
  height: 7.4375rem;
}
.height-120 {
  height: 7.5rem;
}
.height-121 {
  height: 7.5625rem;
}
.height-122 {
  height: 7.625rem;
}
.height-123 {
  height: 7.6875rem;
}
.height-124 {
  height: 7.75rem;
}
.height-125 {
  height: 7.8125rem;
}
.height-126 {
  height: 7.875rem;
}
.height-127 {
  height: 7.9375rem;
}
.height-128 {
  height: 8rem;
}
.height-129 {
  height: 8.0625rem;
}
.height-130 {
  height: 8.125rem;
}
.height-131 {
  height: 8.1875rem;
}
.height-132 {
  height: 8.25rem;
}
.height-133 {
  height: 8.3125rem;
}
.height-134 {
  height: 8.375rem;
}
.height-135 {
  height: 8.4375rem;
}
.height-136 {
  height: 8.5rem;
}
.height-137 {
  height: 8.5625rem;
}
.height-138 {
  height: 8.625rem;
}
.height-139 {
  height: 8.6875rem;
}
.height-140 {
  height: 8.75rem;
}
.height-141 {
  height: 8.8125rem;
}
.height-142 {
  height: 8.875rem;
}
.height-143 {
  height: 8.9375rem;
}
.height-144 {
  height: 9rem;
}
.height-145 {
  height: 9.0625rem;
}
.height-146 {
  height: 9.125rem;
}
.height-147 {
  height: 9.1875rem;
}
.height-148 {
  height: 9.25rem;
}
.height-149 {
  height: 9.3125rem;
}
.height-150 {
  height: 9.375rem;
}
.height-151 {
  height: 9.4375rem;
}
.height-152 {
  height: 9.5rem;
}
.height-153 {
  height: 9.5625rem;
}
.height-154 {
  height: 9.625rem;
}
.height-155 {
  height: 9.6875rem;
}
.height-156 {
  height: 9.75rem;
}
.height-157 {
  height: 9.8125rem;
}
.height-158 {
  height: 9.875rem;
}
.height-159 {
  height: 9.9375rem;
}
.height-160 {
  height: 10rem;
}
.height-161 {
  height: 10.0625rem;
}
.height-162 {
  height: 10.125rem;
}
.height-163 {
  height: 10.1875rem;
}
.height-164 {
  height: 10.25rem;
}
.height-165 {
  height: 10.3125rem;
}
.height-166 {
  height: 10.375rem;
}
.height-167 {
  height: 10.4375rem;
}
.height-168 {
  height: 10.5rem;
}
.height-169 {
  height: 10.5625rem;
}
.height-170 {
  height: 10.625rem;
}
.height-171 {
  height: 10.6875rem;
}
.height-172 {
  height: 10.75rem;
}
.height-173 {
  height: 10.8125rem;
}
.height-174 {
  height: 10.875rem;
}
.height-175 {
  height: 10.9375rem;
}
.height-176 {
  height: 11rem;
}
.height-177 {
  height: 11.0625rem;
}
.height-178 {
  height: 11.125rem;
}
.height-179 {
  height: 11.1875rem;
}
.height-180 {
  height: 11.25rem;
}
.height-181 {
  height: 11.3125rem;
}
.height-182 {
  height: 11.375rem;
}
.height-183 {
  height: 11.4375rem;
}
.height-184 {
  height: 11.5rem;
}
.height-185 {
  height: 11.5625rem;
}
.height-186 {
  height: 11.625rem;
}
.height-187 {
  height: 11.6875rem;
}
.height-188 {
  height: 11.75rem;
}
.height-189 {
  height: 11.8125rem;
}
.height-190 {
  height: 11.875rem;
}
.height-191 {
  height: 11.9375rem;
}
.height-192 {
  height: 12rem;
}
.height-193 {
  height: 12.0625rem;
}
.height-194 {
  height: 12.125rem;
}
.height-195 {
  height: 12.1875rem;
}
.height-196 {
  height: 12.25rem;
}
.height-197 {
  height: 12.3125rem;
}
.height-198 {
  height: 12.375rem;
}
.height-199 {
  height: 12.4375rem;
}
.height-200 {
  height: 12.5rem;
}
.height-201 {
  height: 12.5625rem;
}
.height-202 {
  height: 12.625rem;
}
.height-203 {
  height: 12.6875rem;
}
.height-204 {
  height: 12.75rem;
}
.height-205 {
  height: 12.8125rem;
}
.height-206 {
  height: 12.875rem;
}
.height-207 {
  height: 12.9375rem;
}
.height-208 {
  height: 13rem;
}
.height-209 {
  height: 13.0625rem;
}
.height-210 {
  height: 13.125rem;
}
.height-211 {
  height: 13.1875rem;
}
.height-212 {
  height: 13.25rem;
}
.height-213 {
  height: 13.3125rem;
}
.height-214 {
  height: 13.375rem;
}
.height-215 {
  height: 13.4375rem;
}
.height-216 {
  height: 13.5rem;
}
.height-217 {
  height: 13.5625rem;
}
.height-218 {
  height: 13.625rem;
}
.height-219 {
  height: 13.6875rem;
}
.height-220 {
  height: 13.75rem;
}
.height-221 {
  height: 13.8125rem;
}
.height-222 {
  height: 13.875rem;
}
.height-223 {
  height: 13.9375rem;
}
.height-224 {
  height: 14rem;
}
.height-225 {
  height: 14.0625rem;
}
.height-226 {
  height: 14.125rem;
}
.height-227 {
  height: 14.1875rem;
}
.height-228 {
  height: 14.25rem;
}
.height-229 {
  height: 14.3125rem;
}
.height-230 {
  height: 14.375rem;
}
.height-231 {
  height: 14.4375rem;
}
.height-232 {
  height: 14.5rem;
}
.height-233 {
  height: 14.5625rem;
}
.height-234 {
  height: 14.625rem;
}
.height-235 {
  height: 14.6875rem;
}
.height-236 {
  height: 14.75rem;
}
.height-237 {
  height: 14.8125rem;
}
.height-238 {
  height: 14.875rem;
}
.height-239 {
  height: 14.9375rem;
}
.height-240 {
  height: 15rem;
}
.height-241 {
  height: 15.0625rem;
}
.height-242 {
  height: 15.125rem;
}
.height-243 {
  height: 15.1875rem;
}
.height-244 {
  height: 15.25rem;
}
.height-245 {
  height: 15.3125rem;
}
.height-246 {
  height: 15.375rem;
}
.height-247 {
  height: 15.4375rem;
}
.height-248 {
  height: 15.5rem;
}
.height-249 {
  height: 15.5625rem;
}
.height-250 {
  height: 15.625rem;
}
.height-251 {
  height: 15.6875rem;
}
.height-252 {
  height: 15.75rem;
}
.height-253 {
  height: 15.8125rem;
}
.height-254 {
  height: 15.875rem;
}
.height-255 {
  height: 15.9375rem;
}
.height-256 {
  height: 16rem;
}
.height-257 {
  height: 16.0625rem;
}
.height-258 {
  height: 16.125rem;
}
.height-259 {
  height: 16.1875rem;
}
.height-260 {
  height: 16.25rem;
}
.height-261 {
  height: 16.3125rem;
}
.height-262 {
  height: 16.375rem;
}
.height-263 {
  height: 16.4375rem;
}
.height-264 {
  height: 16.5rem;
}
.height-265 {
  height: 16.5625rem;
}
.height-266 {
  height: 16.625rem;
}
.height-267 {
  height: 16.6875rem;
}
.height-268 {
  height: 16.75rem;
}
.height-269 {
  height: 16.8125rem;
}
.height-270 {
  height: 16.875rem;
}
.height-271 {
  height: 16.9375rem;
}
.height-272 {
  height: 17rem;
}
.height-273 {
  height: 17.0625rem;
}
.height-274 {
  height: 17.125rem;
}
.height-275 {
  height: 17.1875rem;
}
.height-276 {
  height: 17.25rem;
}
.height-277 {
  height: 17.3125rem;
}
.height-278 {
  height: 17.375rem;
}
.height-279 {
  height: 17.4375rem;
}
.height-280 {
  height: 17.5rem;
}
.height-281 {
  height: 17.5625rem;
}
.height-282 {
  height: 17.625rem;
}
.height-283 {
  height: 17.6875rem;
}
.height-284 {
  height: 17.75rem;
}
.height-285 {
  height: 17.8125rem;
}
.height-286 {
  height: 17.875rem;
}
.height-287 {
  height: 17.9375rem;
}
.height-288 {
  height: 18rem;
}
.height-289 {
  height: 18.0625rem;
}
.height-290 {
  height: 18.125rem;
}
.height-291 {
  height: 18.1875rem;
}
.height-292 {
  height: 18.25rem;
}
.height-293 {
  height: 18.3125rem;
}
.height-294 {
  height: 18.375rem;
}
.height-295 {
  height: 18.4375rem;
}
.height-296 {
  height: 18.5rem;
}
.height-297 {
  height: 18.5625rem;
}
.height-298 {
  height: 18.625rem;
}
.height-299 {
  height: 18.6875rem;
}
.height-300 {
  height: 18.75rem;
}
.height-301 {
  height: 18.8125rem;
}
.height-302 {
  height: 18.875rem;
}
.height-303 {
  height: 18.9375rem;
}
.height-304 {
  height: 19rem;
}
.height-305 {
  height: 19.0625rem;
}
.height-306 {
  height: 19.125rem;
}
.height-307 {
  height: 19.1875rem;
}
.height-308 {
  height: 19.25rem;
}
.height-309 {
  height: 19.3125rem;
}
.height-310 {
  height: 19.375rem;
}
.height-311 {
  height: 19.4375rem;
}
.height-312 {
  height: 19.5rem;
}
.height-313 {
  height: 19.5625rem;
}
.height-314 {
  height: 19.625rem;
}
.height-315 {
  height: 19.6875rem;
}
.height-316 {
  height: 19.75rem;
}
.height-317 {
  height: 19.8125rem;
}
.height-318 {
  height: 19.875rem;
}
.height-319 {
  height: 19.9375rem;
}
.height-320 {
  height: 20rem;
}
.height-321 {
  height: 20.0625rem;
}
.height-322 {
  height: 20.125rem;
}
.height-323 {
  height: 20.1875rem;
}
.height-324 {
  height: 20.25rem;
}
.height-325 {
  height: 20.3125rem;
}
.height-326 {
  height: 20.375rem;
}
.height-327 {
  height: 20.4375rem;
}
.height-328 {
  height: 20.5rem;
}
.height-329 {
  height: 20.5625rem;
}
.height-330 {
  height: 20.625rem;
}
.height-331 {
  height: 20.6875rem;
}
.height-332 {
  height: 20.75rem;
}
.height-333 {
  height: 20.8125rem;
}
.height-334 {
  height: 20.875rem;
}
.height-335 {
  height: 20.9375rem;
}
.height-336 {
  height: 21rem;
}
.height-337 {
  height: 21.0625rem;
}
.height-338 {
  height: 21.125rem;
}
.height-339 {
  height: 21.1875rem;
}
.height-340 {
  height: 21.25rem;
}
.height-341 {
  height: 21.3125rem;
}
.height-342 {
  height: 21.375rem;
}
.height-343 {
  height: 21.4375rem;
}
.height-344 {
  height: 21.5rem;
}
.height-345 {
  height: 21.5625rem;
}
.height-346 {
  height: 21.625rem;
}
.height-347 {
  height: 21.6875rem;
}
.height-348 {
  height: 21.75rem;
}
.height-349 {
  height: 21.8125rem;
}
.height-350 {
  height: 21.875rem;
}
.height-351 {
  height: 21.9375rem;
}
.height-352 {
  height: 22rem;
}
.height-353 {
  height: 22.0625rem;
}
.height-354 {
  height: 22.125rem;
}
.height-355 {
  height: 22.1875rem;
}
.height-356 {
  height: 22.25rem;
}
.height-357 {
  height: 22.3125rem;
}
.height-358 {
  height: 22.375rem;
}
.height-359 {
  height: 22.4375rem;
}
.height-360 {
  height: 22.5rem;
}
.height-361 {
  height: 22.5625rem;
}
.height-362 {
  height: 22.625rem;
}
.height-363 {
  height: 22.6875rem;
}
.height-364 {
  height: 22.75rem;
}
.height-365 {
  height: 22.8125rem;
}
.height-366 {
  height: 22.875rem;
}
.height-367 {
  height: 22.9375rem;
}
.height-368 {
  height: 23rem;
}
.height-369 {
  height: 23.0625rem;
}
.height-370 {
  height: 23.125rem;
}
.height-371 {
  height: 23.1875rem;
}
.height-372 {
  height: 23.25rem;
}
.height-373 {
  height: 23.3125rem;
}
.height-374 {
  height: 23.375rem;
}
.height-375 {
  height: 23.4375rem;
}
.height-376 {
  height: 23.5rem;
}
.height-377 {
  height: 23.5625rem;
}
.height-378 {
  height: 23.625rem;
}
.height-379 {
  height: 23.6875rem;
}
.height-380 {
  height: 23.75rem;
}
.height-381 {
  height: 23.8125rem;
}
.height-382 {
  height: 23.875rem;
}
.height-383 {
  height: 23.9375rem;
}
.height-384 {
  height: 24rem;
}
.height-385 {
  height: 24.0625rem;
}
.height-386 {
  height: 24.125rem;
}
.height-387 {
  height: 24.1875rem;
}
.height-388 {
  height: 24.25rem;
}
.height-389 {
  height: 24.3125rem;
}
.height-390 {
  height: 24.375rem;
}
.height-391 {
  height: 24.4375rem;
}
.height-392 {
  height: 24.5rem;
}
.height-393 {
  height: 24.5625rem;
}
.height-394 {
  height: 24.625rem;
}
.height-395 {
  height: 24.6875rem;
}
.height-396 {
  height: 24.75rem;
}
.height-397 {
  height: 24.8125rem;
}
.height-398 {
  height: 24.875rem;
}
.height-399 {
  height: 24.9375rem;
}
.height-400 {
  height: 25rem;
}
.height-401 {
  height: 25.0625rem;
}
.height-402 {
  height: 25.125rem;
}
.height-403 {
  height: 25.1875rem;
}
.height-404 {
  height: 25.25rem;
}
.height-405 {
  height: 25.3125rem;
}
.height-406 {
  height: 25.375rem;
}
.height-407 {
  height: 25.4375rem;
}
.height-408 {
  height: 25.5rem;
}
.height-409 {
  height: 25.5625rem;
}
.height-410 {
  height: 25.625rem;
}
.height-411 {
  height: 25.6875rem;
}
.height-412 {
  height: 25.75rem;
}
.height-413 {
  height: 25.8125rem;
}
.height-414 {
  height: 25.875rem;
}
.height-415 {
  height: 25.9375rem;
}
.height-416 {
  height: 26rem;
}
.height-417 {
  height: 26.0625rem;
}
.height-418 {
  height: 26.125rem;
}
.height-419 {
  height: 26.1875rem;
}
.height-420 {
  height: 26.25rem;
}
.height-421 {
  height: 26.3125rem;
}
.height-422 {
  height: 26.375rem;
}
.height-423 {
  height: 26.4375rem;
}
.height-424 {
  height: 26.5rem;
}
.height-425 {
  height: 26.5625rem;
}
.height-426 {
  height: 26.625rem;
}
.height-427 {
  height: 26.6875rem;
}
.height-428 {
  height: 26.75rem;
}
.height-429 {
  height: 26.8125rem;
}
.height-430 {
  height: 26.875rem;
}
.height-431 {
  height: 26.9375rem;
}
.height-432 {
  height: 27rem;
}
.height-433 {
  height: 27.0625rem;
}
.height-434 {
  height: 27.125rem;
}
.height-435 {
  height: 27.1875rem;
}
.height-436 {
  height: 27.25rem;
}
.height-437 {
  height: 27.3125rem;
}
.height-438 {
  height: 27.375rem;
}
.height-439 {
  height: 27.4375rem;
}
.height-440 {
  height: 27.5rem;
}
.height-441 {
  height: 27.5625rem;
}
.height-442 {
  height: 27.625rem;
}
.height-443 {
  height: 27.6875rem;
}
.height-444 {
  height: 27.75rem;
}
.height-445 {
  height: 27.8125rem;
}
.height-446 {
  height: 27.875rem;
}
.height-447 {
  height: 27.9375rem;
}
.height-448 {
  height: 28rem;
}
.height-449 {
  height: 28.0625rem;
}
.height-450 {
  height: 28.125rem;
}
.height-451 {
  height: 28.1875rem;
}
.height-452 {
  height: 28.25rem;
}
.height-453 {
  height: 28.3125rem;
}
.height-454 {
  height: 28.375rem;
}
.height-455 {
  height: 28.4375rem;
}
.height-456 {
  height: 28.5rem;
}
.height-457 {
  height: 28.5625rem;
}
.height-458 {
  height: 28.625rem;
}
.height-459 {
  height: 28.6875rem;
}
.height-460 {
  height: 28.75rem;
}
.height-461 {
  height: 28.8125rem;
}
.height-462 {
  height: 28.875rem;
}
.height-463 {
  height: 28.9375rem;
}
.height-464 {
  height: 29rem;
}
.height-465 {
  height: 29.0625rem;
}
.height-466 {
  height: 29.125rem;
}
.height-467 {
  height: 29.1875rem;
}
.height-468 {
  height: 29.25rem;
}
.height-469 {
  height: 29.3125rem;
}
.height-470 {
  height: 29.375rem;
}
.height-471 {
  height: 29.4375rem;
}
.height-472 {
  height: 29.5rem;
}
.height-473 {
  height: 29.5625rem;
}
.height-474 {
  height: 29.625rem;
}
.height-475 {
  height: 29.6875rem;
}
.height-476 {
  height: 29.75rem;
}
.height-477 {
  height: 29.8125rem;
}
.height-478 {
  height: 29.875rem;
}
.height-479 {
  height: 29.9375rem;
}
.height-480 {
  height: 30rem;
}
.height-481 {
  height: 30.0625rem;
}
.height-482 {
  height: 30.125rem;
}
.height-483 {
  height: 30.1875rem;
}
.height-484 {
  height: 30.25rem;
}
.height-485 {
  height: 30.3125rem;
}
.height-486 {
  height: 30.375rem;
}
.height-487 {
  height: 30.4375rem;
}
.height-488 {
  height: 30.5rem;
}
.height-489 {
  height: 30.5625rem;
}
.height-490 {
  height: 30.625rem;
}
.height-491 {
  height: 30.6875rem;
}
.height-492 {
  height: 30.75rem;
}
.height-493 {
  height: 30.8125rem;
}
.height-494 {
  height: 30.875rem;
}
.height-495 {
  height: 30.9375rem;
}
.height-496 {
  height: 31rem;
}
.height-497 {
  height: 31.0625rem;
}
.height-498 {
  height: 31.125rem;
}
.height-499 {
  height: 31.1875rem;
}
.height-500 {
  height: 31.25rem;
}
.height-501 {
  height: 31.3125rem;
}
.height-502 {
  height: 31.375rem;
}
.height-503 {
  height: 31.4375rem;
}
.height-504 {
  height: 31.5rem;
}
.height-505 {
  height: 31.5625rem;
}
.height-506 {
  height: 31.625rem;
}
.height-507 {
  height: 31.6875rem;
}
.height-508 {
  height: 31.75rem;
}
.height-509 {
  height: 31.8125rem;
}
.height-510 {
  height: 31.875rem;
}
.height-511 {
  height: 31.9375rem;
}
.height-512 {
  height: 32rem;
}
.height-513 {
  height: 32.0625rem;
}
.height-514 {
  height: 32.125rem;
}
.height-515 {
  height: 32.1875rem;
}
.height-516 {
  height: 32.25rem;
}
.height-517 {
  height: 32.3125rem;
}
.height-518 {
  height: 32.375rem;
}
.height-519 {
  height: 32.4375rem;
}
.height-520 {
  height: 32.5rem;
}
.height-521 {
  height: 32.5625rem;
}
.height-522 {
  height: 32.625rem;
}
.height-523 {
  height: 32.6875rem;
}
.height-524 {
  height: 32.75rem;
}
.height-525 {
  height: 32.8125rem;
}
.height-526 {
  height: 32.875rem;
}
.height-527 {
  height: 32.9375rem;
}
.height-528 {
  height: 33rem;
}
.height-529 {
  height: 33.0625rem;
}
.height-530 {
  height: 33.125rem;
}
.height-531 {
  height: 33.1875rem;
}
.height-532 {
  height: 33.25rem;
}
.height-533 {
  height: 33.3125rem;
}
.height-534 {
  height: 33.375rem;
}
.height-535 {
  height: 33.4375rem;
}
.height-536 {
  height: 33.5rem;
}
.height-537 {
  height: 33.5625rem;
}
.height-538 {
  height: 33.625rem;
}
.height-539 {
  height: 33.6875rem;
}
.height-540 {
  height: 33.75rem;
}
.height-541 {
  height: 33.8125rem;
}
.height-542 {
  height: 33.875rem;
}
.height-543 {
  height: 33.9375rem;
}
.height-544 {
  height: 34rem;
}
.height-545 {
  height: 34.0625rem;
}
.height-546 {
  height: 34.125rem;
}
.height-547 {
  height: 34.1875rem;
}
.height-548 {
  height: 34.25rem;
}
.height-549 {
  height: 34.3125rem;
}
.height-550 {
  height: 34.375rem;
}
.height-551 {
  height: 34.4375rem;
}
.height-552 {
  height: 34.5rem;
}
.height-553 {
  height: 34.5625rem;
}
.height-554 {
  height: 34.625rem;
}
.height-555 {
  height: 34.6875rem;
}
.height-556 {
  height: 34.75rem;
}
.height-557 {
  height: 34.8125rem;
}
.height-558 {
  height: 34.875rem;
}
.height-559 {
  height: 34.9375rem;
}
.height-560 {
  height: 35rem;
}
.height-561 {
  height: 35.0625rem;
}
.height-562 {
  height: 35.125rem;
}
.height-563 {
  height: 35.1875rem;
}
.height-564 {
  height: 35.25rem;
}
.height-565 {
  height: 35.3125rem;
}
.height-566 {
  height: 35.375rem;
}
.height-567 {
  height: 35.4375rem;
}
.height-568 {
  height: 35.5rem;
}
.height-569 {
  height: 35.5625rem;
}
.height-570 {
  height: 35.625rem;
}
.height-571 {
  height: 35.6875rem;
}
.height-572 {
  height: 35.75rem;
}
.height-573 {
  height: 35.8125rem;
}
.height-574 {
  height: 35.875rem;
}
.height-575 {
  height: 35.9375rem;
}
.height-576 {
  height: 36rem;
}
.height-577 {
  height: 36.0625rem;
}
.height-578 {
  height: 36.125rem;
}
.height-579 {
  height: 36.1875rem;
}
.height-580 {
  height: 36.25rem;
}
.height-581 {
  height: 36.3125rem;
}
.height-582 {
  height: 36.375rem;
}
.height-583 {
  height: 36.4375rem;
}
.height-584 {
  height: 36.5rem;
}
.height-585 {
  height: 36.5625rem;
}
.height-586 {
  height: 36.625rem;
}
.height-587 {
  height: 36.6875rem;
}
.height-588 {
  height: 36.75rem;
}
.height-589 {
  height: 36.8125rem;
}
.height-590 {
  height: 36.875rem;
}
.height-591 {
  height: 36.9375rem;
}
.height-592 {
  height: 37rem;
}
.height-593 {
  height: 37.0625rem;
}
.height-594 {
  height: 37.125rem;
}
.height-595 {
  height: 37.1875rem;
}
.height-596 {
  height: 37.25rem;
}
.height-597 {
  height: 37.3125rem;
}
.height-598 {
  height: 37.375rem;
}
.height-599 {
  height: 37.4375rem;
}
.height-600 {
  height: 37.5rem;
}
.height-601 {
  height: 37.5625rem;
}
.height-602 {
  height: 37.625rem;
}
.height-603 {
  height: 37.6875rem;
}
.height-604 {
  height: 37.75rem;
}
.height-605 {
  height: 37.8125rem;
}
.height-606 {
  height: 37.875rem;
}
.height-607 {
  height: 37.9375rem;
}
.height-608 {
  height: 38rem;
}
.height-609 {
  height: 38.0625rem;
}
.height-610 {
  height: 38.125rem;
}
.height-611 {
  height: 38.1875rem;
}
.height-612 {
  height: 38.25rem;
}
.height-613 {
  height: 38.3125rem;
}
.height-614 {
  height: 38.375rem;
}
.height-615 {
  height: 38.4375rem;
}
.height-616 {
  height: 38.5rem;
}
.height-617 {
  height: 38.5625rem;
}
.height-618 {
  height: 38.625rem;
}
.height-619 {
  height: 38.6875rem;
}
.height-620 {
  height: 38.75rem;
}
.height-621 {
  height: 38.8125rem;
}
.height-622 {
  height: 38.875rem;
}
.height-623 {
  height: 38.9375rem;
}
.height-624 {
  height: 39rem;
}
.height-625 {
  height: 39.0625rem;
}
.height-626 {
  height: 39.125rem;
}
.height-627 {
  height: 39.1875rem;
}
.height-628 {
  height: 39.25rem;
}
.height-629 {
  height: 39.3125rem;
}
.height-630 {
  height: 39.375rem;
}
.height-631 {
  height: 39.4375rem;
}
.height-632 {
  height: 39.5rem;
}
.height-633 {
  height: 39.5625rem;
}
.height-634 {
  height: 39.625rem;
}
.height-635 {
  height: 39.6875rem;
}
.height-636 {
  height: 39.75rem;
}
.height-637 {
  height: 39.8125rem;
}
.height-638 {
  height: 39.875rem;
}
.height-639 {
  height: 39.9375rem;
}
.height-640 {
  height: 40rem;
}
.height-641 {
  height: 40.0625rem;
}
.height-642 {
  height: 40.125rem;
}
.height-643 {
  height: 40.1875rem;
}
.height-644 {
  height: 40.25rem;
}
.height-645 {
  height: 40.3125rem;
}
.height-646 {
  height: 40.375rem;
}
.height-647 {
  height: 40.4375rem;
}
.height-648 {
  height: 40.5rem;
}
.height-649 {
  height: 40.5625rem;
}
.height-650 {
  height: 40.625rem;
}
.height-651 {
  height: 40.6875rem;
}
.height-652 {
  height: 40.75rem;
}
.height-653 {
  height: 40.8125rem;
}
.height-654 {
  height: 40.875rem;
}
.height-655 {
  height: 40.9375rem;
}
.height-656 {
  height: 41rem;
}
.height-657 {
  height: 41.0625rem;
}
.height-658 {
  height: 41.125rem;
}
.height-659 {
  height: 41.1875rem;
}
.height-660 {
  height: 41.25rem;
}
.height-661 {
  height: 41.3125rem;
}
.height-662 {
  height: 41.375rem;
}
.height-663 {
  height: 41.4375rem;
}
.height-664 {
  height: 41.5rem;
}
.height-665 {
  height: 41.5625rem;
}
.height-666 {
  height: 41.625rem;
}
.height-667 {
  height: 41.6875rem;
}
.height-668 {
  height: 41.75rem;
}
.height-669 {
  height: 41.8125rem;
}
.height-670 {
  height: 41.875rem;
}
.height-671 {
  height: 41.9375rem;
}
.height-672 {
  height: 42rem;
}
.height-673 {
  height: 42.0625rem;
}
.height-674 {
  height: 42.125rem;
}
.height-675 {
  height: 42.1875rem;
}
.height-676 {
  height: 42.25rem;
}
.height-677 {
  height: 42.3125rem;
}
.height-678 {
  height: 42.375rem;
}
.height-679 {
  height: 42.4375rem;
}
.height-680 {
  height: 42.5rem;
}
.height-681 {
  height: 42.5625rem;
}
.height-682 {
  height: 42.625rem;
}
.height-683 {
  height: 42.6875rem;
}
.height-684 {
  height: 42.75rem;
}
.height-685 {
  height: 42.8125rem;
}
.height-686 {
  height: 42.875rem;
}
.height-687 {
  height: 42.9375rem;
}
.height-688 {
  height: 43rem;
}
.height-689 {
  height: 43.0625rem;
}
.height-690 {
  height: 43.125rem;
}
.height-691 {
  height: 43.1875rem;
}
.height-692 {
  height: 43.25rem;
}
.height-693 {
  height: 43.3125rem;
}
.height-694 {
  height: 43.375rem;
}
.height-695 {
  height: 43.4375rem;
}
.height-696 {
  height: 43.5rem;
}
.height-697 {
  height: 43.5625rem;
}
.height-698 {
  height: 43.625rem;
}
.height-699 {
  height: 43.6875rem;
}
.height-700 {
  height: 43.75rem;
}
.height-701 {
  height: 43.8125rem;
}
.height-702 {
  height: 43.875rem;
}
.height-703 {
  height: 43.9375rem;
}
.height-704 {
  height: 44rem;
}
.height-705 {
  height: 44.0625rem;
}
.height-706 {
  height: 44.125rem;
}
.height-707 {
  height: 44.1875rem;
}
.height-708 {
  height: 44.25rem;
}
.height-709 {
  height: 44.3125rem;
}
.height-710 {
  height: 44.375rem;
}
.height-711 {
  height: 44.4375rem;
}
.height-712 {
  height: 44.5rem;
}
.height-713 {
  height: 44.5625rem;
}
.height-714 {
  height: 44.625rem;
}
.height-715 {
  height: 44.6875rem;
}
.height-716 {
  height: 44.75rem;
}
.height-717 {
  height: 44.8125rem;
}
.height-718 {
  height: 44.875rem;
}
.height-719 {
  height: 44.9375rem;
}
.height-720 {
  height: 45rem;
}
.height-721 {
  height: 45.0625rem;
}
.height-722 {
  height: 45.125rem;
}
.height-723 {
  height: 45.1875rem;
}
.height-724 {
  height: 45.25rem;
}
.height-725 {
  height: 45.3125rem;
}
.height-726 {
  height: 45.375rem;
}
.height-727 {
  height: 45.4375rem;
}
.height-728 {
  height: 45.5rem;
}
.height-729 {
  height: 45.5625rem;
}
.height-730 {
  height: 45.625rem;
}
.height-731 {
  height: 45.6875rem;
}
.height-732 {
  height: 45.75rem;
}
.height-733 {
  height: 45.8125rem;
}
.height-734 {
  height: 45.875rem;
}
.height-735 {
  height: 45.9375rem;
}
.height-736 {
  height: 46rem;
}
.height-737 {
  height: 46.0625rem;
}
.height-738 {
  height: 46.125rem;
}
.height-739 {
  height: 46.1875rem;
}
.height-740 {
  height: 46.25rem;
}
.height-741 {
  height: 46.3125rem;
}
.height-742 {
  height: 46.375rem;
}
.height-743 {
  height: 46.4375rem;
}
.height-744 {
  height: 46.5rem;
}
.height-745 {
  height: 46.5625rem;
}
.height-746 {
  height: 46.625rem;
}
.height-747 {
  height: 46.6875rem;
}
.height-748 {
  height: 46.75rem;
}
.height-749 {
  height: 46.8125rem;
}
.height-750 {
  height: 46.875rem;
}
.height-751 {
  height: 46.9375rem;
}
.height-752 {
  height: 47rem;
}
.height-753 {
  height: 47.0625rem;
}
.height-754 {
  height: 47.125rem;
}
.height-755 {
  height: 47.1875rem;
}
.height-756 {
  height: 47.25rem;
}
.height-757 {
  height: 47.3125rem;
}
.height-758 {
  height: 47.375rem;
}
.height-759 {
  height: 47.4375rem;
}
.height-760 {
  height: 47.5rem;
}
.height-761 {
  height: 47.5625rem;
}
.height-762 {
  height: 47.625rem;
}
.height-763 {
  height: 47.6875rem;
}
.height-764 {
  height: 47.75rem;
}
.height-765 {
  height: 47.8125rem;
}
.height-766 {
  height: 47.875rem;
}
.height-767 {
  height: 47.9375rem;
}
.height-768 {
  height: 48rem;
}
.height-769 {
  height: 48.0625rem;
}
.height-770 {
  height: 48.125rem;
}
.height-771 {
  height: 48.1875rem;
}
.height-772 {
  height: 48.25rem;
}
.height-773 {
  height: 48.3125rem;
}
.height-774 {
  height: 48.375rem;
}
.height-775 {
  height: 48.4375rem;
}
.height-776 {
  height: 48.5rem;
}
.height-777 {
  height: 48.5625rem;
}
.height-778 {
  height: 48.625rem;
}
.height-779 {
  height: 48.6875rem;
}
.height-780 {
  height: 48.75rem;
}
.height-781 {
  height: 48.8125rem;
}
.height-782 {
  height: 48.875rem;
}
.height-783 {
  height: 48.9375rem;
}
.height-784 {
  height: 49rem;
}
.height-785 {
  height: 49.0625rem;
}
.height-786 {
  height: 49.125rem;
}
.height-787 {
  height: 49.1875rem;
}
.height-788 {
  height: 49.25rem;
}
.height-789 {
  height: 49.3125rem;
}
.height-790 {
  height: 49.375rem;
}
.height-791 {
  height: 49.4375rem;
}
.height-792 {
  height: 49.5rem;
}
.height-793 {
  height: 49.5625rem;
}
.height-794 {
  height: 49.625rem;
}
.height-795 {
  height: 49.6875rem;
}
.height-796 {
  height: 49.75rem;
}
.height-797 {
  height: 49.8125rem;
}
.height-798 {
  height: 49.875rem;
}
.height-799 {
  height: 49.9375rem;
}
.height-800 {
  height: 50rem;
}
.height-801 {
  height: 50.0625rem;
}
.height-802 {
  height: 50.125rem;
}
.height-803 {
  height: 50.1875rem;
}
.height-804 {
  height: 50.25rem;
}
.height-805 {
  height: 50.3125rem;
}
.height-806 {
  height: 50.375rem;
}
.height-807 {
  height: 50.4375rem;
}
.height-808 {
  height: 50.5rem;
}
.height-809 {
  height: 50.5625rem;
}
.height-810 {
  height: 50.625rem;
}
.height-811 {
  height: 50.6875rem;
}
.height-812 {
  height: 50.75rem;
}
.height-813 {
  height: 50.8125rem;
}
.height-814 {
  height: 50.875rem;
}
.height-815 {
  height: 50.9375rem;
}
.height-816 {
  height: 51rem;
}
.height-817 {
  height: 51.0625rem;
}
.height-818 {
  height: 51.125rem;
}
.height-819 {
  height: 51.1875rem;
}
.height-820 {
  height: 51.25rem;
}
.height-821 {
  height: 51.3125rem;
}
.height-822 {
  height: 51.375rem;
}
.height-823 {
  height: 51.4375rem;
}
.height-824 {
  height: 51.5rem;
}
.height-825 {
  height: 51.5625rem;
}
.height-826 {
  height: 51.625rem;
}
.height-827 {
  height: 51.6875rem;
}
.height-828 {
  height: 51.75rem;
}
.height-829 {
  height: 51.8125rem;
}
.height-830 {
  height: 51.875rem;
}
.height-831 {
  height: 51.9375rem;
}
.height-832 {
  height: 52rem;
}
.height-833 {
  height: 52.0625rem;
}
.height-834 {
  height: 52.125rem;
}
.height-835 {
  height: 52.1875rem;
}
.height-836 {
  height: 52.25rem;
}
.height-837 {
  height: 52.3125rem;
}
.height-838 {
  height: 52.375rem;
}
.height-839 {
  height: 52.4375rem;
}
.height-840 {
  height: 52.5rem;
}
.height-841 {
  height: 52.5625rem;
}
.height-842 {
  height: 52.625rem;
}
.height-843 {
  height: 52.6875rem;
}
.height-844 {
  height: 52.75rem;
}
.height-845 {
  height: 52.8125rem;
}
.height-846 {
  height: 52.875rem;
}
.height-847 {
  height: 52.9375rem;
}
.height-848 {
  height: 53rem;
}
.height-849 {
  height: 53.0625rem;
}
.height-850 {
  height: 53.125rem;
}
.height-851 {
  height: 53.1875rem;
}
.height-852 {
  height: 53.25rem;
}
.height-853 {
  height: 53.3125rem;
}
.height-854 {
  height: 53.375rem;
}
.height-855 {
  height: 53.4375rem;
}
.height-856 {
  height: 53.5rem;
}
.height-857 {
  height: 53.5625rem;
}
.height-858 {
  height: 53.625rem;
}
.height-859 {
  height: 53.6875rem;
}
.height-860 {
  height: 53.75rem;
}
.height-861 {
  height: 53.8125rem;
}
.height-862 {
  height: 53.875rem;
}
.height-863 {
  height: 53.9375rem;
}
.height-864 {
  height: 54rem;
}
.height-865 {
  height: 54.0625rem;
}
.height-866 {
  height: 54.125rem;
}
.height-867 {
  height: 54.1875rem;
}
.height-868 {
  height: 54.25rem;
}
.height-869 {
  height: 54.3125rem;
}
.height-870 {
  height: 54.375rem;
}
.height-871 {
  height: 54.4375rem;
}
.height-872 {
  height: 54.5rem;
}
.height-873 {
  height: 54.5625rem;
}
.height-874 {
  height: 54.625rem;
}
.height-875 {
  height: 54.6875rem;
}
.height-876 {
  height: 54.75rem;
}
.height-877 {
  height: 54.8125rem;
}
.height-878 {
  height: 54.875rem;
}
.height-879 {
  height: 54.9375rem;
}
.height-880 {
  height: 55rem;
}
.height-881 {
  height: 55.0625rem;
}
.height-882 {
  height: 55.125rem;
}
.height-883 {
  height: 55.1875rem;
}
.height-884 {
  height: 55.25rem;
}
.height-885 {
  height: 55.3125rem;
}
.height-886 {
  height: 55.375rem;
}
.height-887 {
  height: 55.4375rem;
}
.height-888 {
  height: 55.5rem;
}
.height-889 {
  height: 55.5625rem;
}
.height-890 {
  height: 55.625rem;
}
.height-891 {
  height: 55.6875rem;
}
.height-892 {
  height: 55.75rem;
}
.height-893 {
  height: 55.8125rem;
}
.height-894 {
  height: 55.875rem;
}
.height-895 {
  height: 55.9375rem;
}
.height-896 {
  height: 56rem;
}
.height-897 {
  height: 56.0625rem;
}
.height-898 {
  height: 56.125rem;
}
.height-899 {
  height: 56.1875rem;
}
.height-900 {
  height: 56.25rem;
}
.height-901 {
  height: 56.3125rem;
}
.height-902 {
  height: 56.375rem;
}
.height-903 {
  height: 56.4375rem;
}
.height-904 {
  height: 56.5rem;
}
.height-905 {
  height: 56.5625rem;
}
.height-906 {
  height: 56.625rem;
}
.height-907 {
  height: 56.6875rem;
}
.height-908 {
  height: 56.75rem;
}
.height-909 {
  height: 56.8125rem;
}
.height-910 {
  height: 56.875rem;
}
.height-911 {
  height: 56.9375rem;
}
.height-912 {
  height: 57rem;
}
.height-913 {
  height: 57.0625rem;
}
.height-914 {
  height: 57.125rem;
}
.height-915 {
  height: 57.1875rem;
}
.height-916 {
  height: 57.25rem;
}
.height-917 {
  height: 57.3125rem;
}
.height-918 {
  height: 57.375rem;
}
.height-919 {
  height: 57.4375rem;
}
.height-920 {
  height: 57.5rem;
}
.height-921 {
  height: 57.5625rem;
}
.height-922 {
  height: 57.625rem;
}
.height-923 {
  height: 57.6875rem;
}
.height-924 {
  height: 57.75rem;
}
.height-925 {
  height: 57.8125rem;
}
.height-926 {
  height: 57.875rem;
}
.height-927 {
  height: 57.9375rem;
}
.height-928 {
  height: 58rem;
}
.height-929 {
  height: 58.0625rem;
}
.height-930 {
  height: 58.125rem;
}
.height-931 {
  height: 58.1875rem;
}
.height-932 {
  height: 58.25rem;
}
.height-933 {
  height: 58.3125rem;
}
.height-934 {
  height: 58.375rem;
}
.height-935 {
  height: 58.4375rem;
}
.height-936 {
  height: 58.5rem;
}
.height-937 {
  height: 58.5625rem;
}
.height-938 {
  height: 58.625rem;
}
.height-939 {
  height: 58.6875rem;
}
.height-940 {
  height: 58.75rem;
}
.height-941 {
  height: 58.8125rem;
}
.height-942 {
  height: 58.875rem;
}
.height-943 {
  height: 58.9375rem;
}
.height-944 {
  height: 59rem;
}
.height-945 {
  height: 59.0625rem;
}
.height-946 {
  height: 59.125rem;
}
.height-947 {
  height: 59.1875rem;
}
.height-948 {
  height: 59.25rem;
}
.height-949 {
  height: 59.3125rem;
}
.height-950 {
  height: 59.375rem;
}
.height-951 {
  height: 59.4375rem;
}
.height-952 {
  height: 59.5rem;
}
.height-953 {
  height: 59.5625rem;
}
.height-954 {
  height: 59.625rem;
}
.height-955 {
  height: 59.6875rem;
}
.height-956 {
  height: 59.75rem;
}
.height-957 {
  height: 59.8125rem;
}
.height-958 {
  height: 59.875rem;
}
.height-959 {
  height: 59.9375rem;
}
.height-960 {
  height: 60rem;
}
.height-961 {
  height: 60.0625rem;
}
.height-962 {
  height: 60.125rem;
}
.height-963 {
  height: 60.1875rem;
}
.height-964 {
  height: 60.25rem;
}
.height-965 {
  height: 60.3125rem;
}
.height-966 {
  height: 60.375rem;
}
.height-967 {
  height: 60.4375rem;
}
.height-968 {
  height: 60.5rem;
}
.height-969 {
  height: 60.5625rem;
}
.height-970 {
  height: 60.625rem;
}
.height-971 {
  height: 60.6875rem;
}
.height-972 {
  height: 60.75rem;
}
.height-973 {
  height: 60.8125rem;
}
.height-974 {
  height: 60.875rem;
}
.height-975 {
  height: 60.9375rem;
}
.height-976 {
  height: 61rem;
}
.height-977 {
  height: 61.0625rem;
}
.height-978 {
  height: 61.125rem;
}
.height-979 {
  height: 61.1875rem;
}
.height-980 {
  height: 61.25rem;
}
.height-981 {
  height: 61.3125rem;
}
.height-982 {
  height: 61.375rem;
}
.height-983 {
  height: 61.4375rem;
}
.height-984 {
  height: 61.5rem;
}
.height-985 {
  height: 61.5625rem;
}
.height-986 {
  height: 61.625rem;
}
.height-987 {
  height: 61.6875rem;
}
.height-988 {
  height: 61.75rem;
}
.height-989 {
  height: 61.8125rem;
}
.height-990 {
  height: 61.875rem;
}
.height-991 {
  height: 61.9375rem;
}
.height-992 {
  height: 62rem;
}
.height-993 {
  height: 62.0625rem;
}
.height-994 {
  height: 62.125rem;
}
.height-995 {
  height: 62.1875rem;
}
.height-996 {
  height: 62.25rem;
}
.height-997 {
  height: 62.3125rem;
}
.height-998 {
  height: 62.375rem;
}
.height-999 {
  height: 62.4375rem;
}
.height-1000 {
  height: 62.5rem;
}

.position-top-1 {
  top: 1%;
}
.position-right-1 {
  right: 1%;
}
.position-bottom-1 {
  bottom: 1%;
}
.position-left-1 {
  left: 1%;
}
.position-top-2 {
  top: 2%;
}
.position-right-2 {
  right: 2%;
}
.position-bottom-2 {
  bottom: 2%;
}
.position-left-2 {
  left: 2%;
}
.position-top-3 {
  top: 3%;
}
.position-right-3 {
  right: 3%;
}
.position-bottom-3 {
  bottom: 3%;
}
.position-left-3 {
  left: 3%;
}
.position-top-4 {
  top: 4%;
}
.position-right-4 {
  right: 4%;
}
.position-bottom-4 {
  bottom: 4%;
}
.position-left-4 {
  left: 4%;
}
.position-top-5 {
  top: 5%;
}
.position-right-5 {
  right: 5%;
}
.position-bottom-5 {
  bottom: 5%;
}
.position-left-5 {
  left: 5%;
}
.position-top-6 {
  top: 6%;
}
.position-right-6 {
  right: 6%;
}
.position-bottom-6 {
  bottom: 6%;
}
.position-left-6 {
  left: 6%;
}
.position-top-7 {
  top: 7%;
}
.position-right-7 {
  right: 7%;
}
.position-bottom-7 {
  bottom: 7%;
}
.position-left-7 {
  left: 7%;
}
.position-top-8 {
  top: 8%;
}
.position-right-8 {
  right: 8%;
}
.position-bottom-8 {
  bottom: 8%;
}
.position-left-8 {
  left: 8%;
}
.position-top-9 {
  top: 9%;
}
.position-right-9 {
  right: 9%;
}
.position-bottom-9 {
  bottom: 9%;
}
.position-left-9 {
  left: 9%;
}
.position-top-10 {
  top: 10%;
}
.position-right-10 {
  right: 10%;
}
.position-bottom-10 {
  bottom: 10%;
}
.position-left-10 {
  left: 10%;
}
.position-top-11 {
  top: 11%;
}
.position-right-11 {
  right: 11%;
}
.position-bottom-11 {
  bottom: 11%;
}
.position-left-11 {
  left: 11%;
}
.position-top-12 {
  top: 12%;
}
.position-right-12 {
  right: 12%;
}
.position-bottom-12 {
  bottom: 12%;
}
.position-left-12 {
  left: 12%;
}
.position-top-13 {
  top: 13%;
}
.position-right-13 {
  right: 13%;
}
.position-bottom-13 {
  bottom: 13%;
}
.position-left-13 {
  left: 13%;
}
.position-top-14 {
  top: 14%;
}
.position-right-14 {
  right: 14%;
}
.position-bottom-14 {
  bottom: 14%;
}
.position-left-14 {
  left: 14%;
}
.position-top-15 {
  top: 15%;
}
.position-right-15 {
  right: 15%;
}
.position-bottom-15 {
  bottom: 15%;
}
.position-left-15 {
  left: 15%;
}
.position-top-16 {
  top: 16%;
}
.position-right-16 {
  right: 16%;
}
.position-bottom-16 {
  bottom: 16%;
}
.position-left-16 {
  left: 16%;
}
.position-top-17 {
  top: 17%;
}
.position-right-17 {
  right: 17%;
}
.position-bottom-17 {
  bottom: 17%;
}
.position-left-17 {
  left: 17%;
}
.position-top-18 {
  top: 18%;
}
.position-right-18 {
  right: 18%;
}
.position-bottom-18 {
  bottom: 18%;
}
.position-left-18 {
  left: 18%;
}
.position-top-19 {
  top: 19%;
}
.position-right-19 {
  right: 19%;
}
.position-bottom-19 {
  bottom: 19%;
}
.position-left-19 {
  left: 19%;
}
.position-top-20 {
  top: 20%;
}
.position-right-20 {
  right: 20%;
}
.position-bottom-20 {
  bottom: 20%;
}
.position-left-20 {
  left: 20%;
}
.position-top-21 {
  top: 21%;
}
.position-right-21 {
  right: 21%;
}
.position-bottom-21 {
  bottom: 21%;
}
.position-left-21 {
  left: 21%;
}
.position-top-22 {
  top: 22%;
}
.position-right-22 {
  right: 22%;
}
.position-bottom-22 {
  bottom: 22%;
}
.position-left-22 {
  left: 22%;
}
.position-top-23 {
  top: 23%;
}
.position-right-23 {
  right: 23%;
}
.position-bottom-23 {
  bottom: 23%;
}
.position-left-23 {
  left: 23%;
}
.position-top-24 {
  top: 24%;
}
.position-right-24 {
  right: 24%;
}
.position-bottom-24 {
  bottom: 24%;
}
.position-left-24 {
  left: 24%;
}
.position-top-25 {
  top: 25%;
}
.position-right-25 {
  right: 25%;
}
.position-bottom-25 {
  bottom: 25%;
}
.position-left-25 {
  left: 25%;
}
.position-top-26 {
  top: 26%;
}
.position-right-26 {
  right: 26%;
}
.position-bottom-26 {
  bottom: 26%;
}
.position-left-26 {
  left: 26%;
}
.position-top-27 {
  top: 27%;
}
.position-right-27 {
  right: 27%;
}
.position-bottom-27 {
  bottom: 27%;
}
.position-left-27 {
  left: 27%;
}
.position-top-28 {
  top: 28%;
}
.position-right-28 {
  right: 28%;
}
.position-bottom-28 {
  bottom: 28%;
}
.position-left-28 {
  left: 28%;
}
.position-top-29 {
  top: 29%;
}
.position-right-29 {
  right: 29%;
}
.position-bottom-29 {
  bottom: 29%;
}
.position-left-29 {
  left: 29%;
}
.position-top-30 {
  top: 30%;
}
.position-right-30 {
  right: 30%;
}
.position-bottom-30 {
  bottom: 30%;
}
.position-left-30 {
  left: 30%;
}
.position-top-31 {
  top: 31%;
}
.position-right-31 {
  right: 31%;
}
.position-bottom-31 {
  bottom: 31%;
}
.position-left-31 {
  left: 31%;
}
.position-top-32 {
  top: 32%;
}
.position-right-32 {
  right: 32%;
}
.position-bottom-32 {
  bottom: 32%;
}
.position-left-32 {
  left: 32%;
}
.position-top-33 {
  top: 33%;
}
.position-right-33 {
  right: 33%;
}
.position-bottom-33 {
  bottom: 33%;
}
.position-left-33 {
  left: 33%;
}
.position-top-34 {
  top: 34%;
}
.position-right-34 {
  right: 34%;
}
.position-bottom-34 {
  bottom: 34%;
}
.position-left-34 {
  left: 34%;
}
.position-top-35 {
  top: 35%;
}
.position-right-35 {
  right: 35%;
}
.position-bottom-35 {
  bottom: 35%;
}
.position-left-35 {
  left: 35%;
}
.position-top-36 {
  top: 36%;
}
.position-right-36 {
  right: 36%;
}
.position-bottom-36 {
  bottom: 36%;
}
.position-left-36 {
  left: 36%;
}
.position-top-37 {
  top: 37%;
}
.position-right-37 {
  right: 37%;
}
.position-bottom-37 {
  bottom: 37%;
}
.position-left-37 {
  left: 37%;
}
.position-top-38 {
  top: 38%;
}
.position-right-38 {
  right: 38%;
}
.position-bottom-38 {
  bottom: 38%;
}
.position-left-38 {
  left: 38%;
}
.position-top-39 {
  top: 39%;
}
.position-right-39 {
  right: 39%;
}
.position-bottom-39 {
  bottom: 39%;
}
.position-left-39 {
  left: 39%;
}
.position-top-40 {
  top: 40%;
}
.position-right-40 {
  right: 40%;
}
.position-bottom-40 {
  bottom: 40%;
}
.position-left-40 {
  left: 40%;
}
.position-top-41 {
  top: 41%;
}
.position-right-41 {
  right: 41%;
}
.position-bottom-41 {
  bottom: 41%;
}
.position-left-41 {
  left: 41%;
}
.position-top-42 {
  top: 42%;
}
.position-right-42 {
  right: 42%;
}
.position-bottom-42 {
  bottom: 42%;
}
.position-left-42 {
  left: 42%;
}
.position-top-43 {
  top: 43%;
}
.position-right-43 {
  right: 43%;
}
.position-bottom-43 {
  bottom: 43%;
}
.position-left-43 {
  left: 43%;
}
.position-top-44 {
  top: 44%;
}
.position-right-44 {
  right: 44%;
}
.position-bottom-44 {
  bottom: 44%;
}
.position-left-44 {
  left: 44%;
}
.position-top-45 {
  top: 45%;
}
.position-right-45 {
  right: 45%;
}
.position-bottom-45 {
  bottom: 45%;
}
.position-left-45 {
  left: 45%;
}
.position-top-46 {
  top: 46%;
}
.position-right-46 {
  right: 46%;
}
.position-bottom-46 {
  bottom: 46%;
}
.position-left-46 {
  left: 46%;
}
.position-top-47 {
  top: 47%;
}
.position-right-47 {
  right: 47%;
}
.position-bottom-47 {
  bottom: 47%;
}
.position-left-47 {
  left: 47%;
}
.position-top-48 {
  top: 48%;
}
.position-right-48 {
  right: 48%;
}
.position-bottom-48 {
  bottom: 48%;
}
.position-left-48 {
  left: 48%;
}
.position-top-49 {
  top: 49%;
}
.position-right-49 {
  right: 49%;
}
.position-bottom-49 {
  bottom: 49%;
}
.position-left-49 {
  left: 49%;
}
.position-top-50 {
  top: 50%;
}
.position-right-50 {
  right: 50%;
}
.position-bottom-50 {
  bottom: 50%;
}
.position-left-50 {
  left: 50%;
}
.position-top-51 {
  top: 51%;
}
.position-right-51 {
  right: 51%;
}
.position-bottom-51 {
  bottom: 51%;
}
.position-left-51 {
  left: 51%;
}
.position-top-52 {
  top: 52%;
}
.position-right-52 {
  right: 52%;
}
.position-bottom-52 {
  bottom: 52%;
}
.position-left-52 {
  left: 52%;
}
.position-top-53 {
  top: 53%;
}
.position-right-53 {
  right: 53%;
}
.position-bottom-53 {
  bottom: 53%;
}
.position-left-53 {
  left: 53%;
}
.position-top-54 {
  top: 54%;
}
.position-right-54 {
  right: 54%;
}
.position-bottom-54 {
  bottom: 54%;
}
.position-left-54 {
  left: 54%;
}
.position-top-55 {
  top: 55%;
}
.position-right-55 {
  right: 55%;
}
.position-bottom-55 {
  bottom: 55%;
}
.position-left-55 {
  left: 55%;
}
.position-top-56 {
  top: 56%;
}
.position-right-56 {
  right: 56%;
}
.position-bottom-56 {
  bottom: 56%;
}
.position-left-56 {
  left: 56%;
}
.position-top-57 {
  top: 57%;
}
.position-right-57 {
  right: 57%;
}
.position-bottom-57 {
  bottom: 57%;
}
.position-left-57 {
  left: 57%;
}
.position-top-58 {
  top: 58%;
}
.position-right-58 {
  right: 58%;
}
.position-bottom-58 {
  bottom: 58%;
}
.position-left-58 {
  left: 58%;
}
.position-top-59 {
  top: 59%;
}
.position-right-59 {
  right: 59%;
}
.position-bottom-59 {
  bottom: 59%;
}
.position-left-59 {
  left: 59%;
}
.position-top-60 {
  top: 60%;
}
.position-right-60 {
  right: 60%;
}
.position-bottom-60 {
  bottom: 60%;
}
.position-left-60 {
  left: 60%;
}
.position-top-61 {
  top: 61%;
}
.position-right-61 {
  right: 61%;
}
.position-bottom-61 {
  bottom: 61%;
}
.position-left-61 {
  left: 61%;
}
.position-top-62 {
  top: 62%;
}
.position-right-62 {
  right: 62%;
}
.position-bottom-62 {
  bottom: 62%;
}
.position-left-62 {
  left: 62%;
}
.position-top-63 {
  top: 63%;
}
.position-right-63 {
  right: 63%;
}
.position-bottom-63 {
  bottom: 63%;
}
.position-left-63 {
  left: 63%;
}
.position-top-64 {
  top: 64%;
}
.position-right-64 {
  right: 64%;
}
.position-bottom-64 {
  bottom: 64%;
}
.position-left-64 {
  left: 64%;
}
.position-top-65 {
  top: 65%;
}
.position-right-65 {
  right: 65%;
}
.position-bottom-65 {
  bottom: 65%;
}
.position-left-65 {
  left: 65%;
}
.position-top-66 {
  top: 66%;
}
.position-right-66 {
  right: 66%;
}
.position-bottom-66 {
  bottom: 66%;
}
.position-left-66 {
  left: 66%;
}
.position-top-67 {
  top: 67%;
}
.position-right-67 {
  right: 67%;
}
.position-bottom-67 {
  bottom: 67%;
}
.position-left-67 {
  left: 67%;
}
.position-top-68 {
  top: 68%;
}
.position-right-68 {
  right: 68%;
}
.position-bottom-68 {
  bottom: 68%;
}
.position-left-68 {
  left: 68%;
}
.position-top-69 {
  top: 69%;
}
.position-right-69 {
  right: 69%;
}
.position-bottom-69 {
  bottom: 69%;
}
.position-left-69 {
  left: 69%;
}
.position-top-70 {
  top: 70%;
}
.position-right-70 {
  right: 70%;
}
.position-bottom-70 {
  bottom: 70%;
}
.position-left-70 {
  left: 70%;
}
.position-top-71 {
  top: 71%;
}
.position-right-71 {
  right: 71%;
}
.position-bottom-71 {
  bottom: 71%;
}
.position-left-71 {
  left: 71%;
}
.position-top-72 {
  top: 72%;
}
.position-right-72 {
  right: 72%;
}
.position-bottom-72 {
  bottom: 72%;
}
.position-left-72 {
  left: 72%;
}
.position-top-73 {
  top: 73%;
}
.position-right-73 {
  right: 73%;
}
.position-bottom-73 {
  bottom: 73%;
}
.position-left-73 {
  left: 73%;
}
.position-top-74 {
  top: 74%;
}
.position-right-74 {
  right: 74%;
}
.position-bottom-74 {
  bottom: 74%;
}
.position-left-74 {
  left: 74%;
}
.position-top-75 {
  top: 75%;
}
.position-right-75 {
  right: 75%;
}
.position-bottom-75 {
  bottom: 75%;
}
.position-left-75 {
  left: 75%;
}
.position-top-76 {
  top: 76%;
}
.position-right-76 {
  right: 76%;
}
.position-bottom-76 {
  bottom: 76%;
}
.position-left-76 {
  left: 76%;
}
.position-top-77 {
  top: 77%;
}
.position-right-77 {
  right: 77%;
}
.position-bottom-77 {
  bottom: 77%;
}
.position-left-77 {
  left: 77%;
}
.position-top-78 {
  top: 78%;
}
.position-right-78 {
  right: 78%;
}
.position-bottom-78 {
  bottom: 78%;
}
.position-left-78 {
  left: 78%;
}
.position-top-79 {
  top: 79%;
}
.position-right-79 {
  right: 79%;
}
.position-bottom-79 {
  bottom: 79%;
}
.position-left-79 {
  left: 79%;
}
.position-top-80 {
  top: 80%;
}
.position-right-80 {
  right: 80%;
}
.position-bottom-80 {
  bottom: 80%;
}
.position-left-80 {
  left: 80%;
}
.position-top-81 {
  top: 81%;
}
.position-right-81 {
  right: 81%;
}
.position-bottom-81 {
  bottom: 81%;
}
.position-left-81 {
  left: 81%;
}
.position-top-82 {
  top: 82%;
}
.position-right-82 {
  right: 82%;
}
.position-bottom-82 {
  bottom: 82%;
}
.position-left-82 {
  left: 82%;
}
.position-top-83 {
  top: 83%;
}
.position-right-83 {
  right: 83%;
}
.position-bottom-83 {
  bottom: 83%;
}
.position-left-83 {
  left: 83%;
}
.position-top-84 {
  top: 84%;
}
.position-right-84 {
  right: 84%;
}
.position-bottom-84 {
  bottom: 84%;
}
.position-left-84 {
  left: 84%;
}
.position-top-85 {
  top: 85%;
}
.position-right-85 {
  right: 85%;
}
.position-bottom-85 {
  bottom: 85%;
}
.position-left-85 {
  left: 85%;
}
.position-top-86 {
  top: 86%;
}
.position-right-86 {
  right: 86%;
}
.position-bottom-86 {
  bottom: 86%;
}
.position-left-86 {
  left: 86%;
}
.position-top-87 {
  top: 87%;
}
.position-right-87 {
  right: 87%;
}
.position-bottom-87 {
  bottom: 87%;
}
.position-left-87 {
  left: 87%;
}
.position-top-88 {
  top: 88%;
}
.position-right-88 {
  right: 88%;
}
.position-bottom-88 {
  bottom: 88%;
}
.position-left-88 {
  left: 88%;
}
.position-top-89 {
  top: 89%;
}
.position-right-89 {
  right: 89%;
}
.position-bottom-89 {
  bottom: 89%;
}
.position-left-89 {
  left: 89%;
}
.position-top-90 {
  top: 90%;
}
.position-right-90 {
  right: 90%;
}
.position-bottom-90 {
  bottom: 90%;
}
.position-left-90 {
  left: 90%;
}
.position-top-91 {
  top: 91%;
}
.position-right-91 {
  right: 91%;
}
.position-bottom-91 {
  bottom: 91%;
}
.position-left-91 {
  left: 91%;
}
.position-top-92 {
  top: 92%;
}
.position-right-92 {
  right: 92%;
}
.position-bottom-92 {
  bottom: 92%;
}
.position-left-92 {
  left: 92%;
}
.position-top-93 {
  top: 93%;
}
.position-right-93 {
  right: 93%;
}
.position-bottom-93 {
  bottom: 93%;
}
.position-left-93 {
  left: 93%;
}
.position-top-94 {
  top: 94%;
}
.position-right-94 {
  right: 94%;
}
.position-bottom-94 {
  bottom: 94%;
}
.position-left-94 {
  left: 94%;
}
.position-top-95 {
  top: 95%;
}
.position-right-95 {
  right: 95%;
}
.position-bottom-95 {
  bottom: 95%;
}
.position-left-95 {
  left: 95%;
}
.position-top-96 {
  top: 96%;
}
.position-right-96 {
  right: 96%;
}
.position-bottom-96 {
  bottom: 96%;
}
.position-left-96 {
  left: 96%;
}
.position-top-97 {
  top: 97%;
}
.position-right-97 {
  right: 97%;
}
.position-bottom-97 {
  bottom: 97%;
}
.position-left-97 {
  left: 97%;
}
.position-top-98 {
  top: 98%;
}
.position-right-98 {
  right: 98%;
}
.position-bottom-98 {
  bottom: 98%;
}
.position-left-98 {
  left: 98%;
}
.position-top-99 {
  top: 99%;
}
.position-right-99 {
  right: 99%;
}
.position-bottom-99 {
  bottom: 99%;
}
.position-left-99 {
  left: 99%;
}
.position-top-100 {
  top: 100%;
}
.position-right-100 {
  right: 100%;
}
.position-bottom-100 {
  bottom: 100%;
}
.position-left-100 {
  left: 100%;
}

html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  font-family: "Calibri";
  font-size: 15px;
  scroll-behavior: smooth;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow-x: hidden;
}

.big-title {
  position: relative;
  color: #000000;
  text-transform: capitalize;
  margin-top: 0;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
}
.big-title::before {
  content: "";
  position: absolute;
  width: 4rem;
  height: 4px;
  background: #f3662b;
  bottom: 0;
  left: 0;
}
.big-title--center {
  text-align: center;
}
.big-title--center::before {
  left: 50%;
  transform: translateX(-50%);
}
.big-title--right {
  text-align: right;
}
.big-title--right::before {
  right: 0;
  left: unset;
}
.big-title span {
  display: block;
}
@media (max-width: 575.98px) {
  .big-title {
    font-size: 1.5625rem;
  }
}

.link-btn {
  display: inline-block;
  width: fit-content;
  padding: 0.3rem 2rem;
  border: 1px solid #f3662b;
  color: #f3662b;
  background: transparent;
  text-transform: capitalize;
  text-align: center;
  border-radius: 30px;
  text-decoration: none;
  transition: 0.5s background;
}
@media (prefers-reduced-motion: reduce) {
  .link-btn {
    transition: none;
  }
}
.link-btn:hover, .link-btn:active, .link-btn:focus {
  text-decoration: none;
  color: #ffffff;
  border-color: #f3662b;
  background: #f3662b;
}
.link-btn--white {
  color: #ffffff;
  border-color: #ffffff;
}
.link-btn--radius {
  border-radius: 30px !important;
}

.cms-btn {
  display: inline-block;
  position: relative;
  width: fit-content;
  padding: 0.5rem 1.5rem;
  padding-left: 3rem;
  color: #f3662b;
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 30px;
  text-decoration: none;
  transition: 0.5s background;
  vertical-align: text-bottom;
}
@media (prefers-reduced-motion: reduce) {
  .cms-btn {
    transition: none;
  }
}
.cms-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 30px;
  height: 30px;
  color: #ffffff;
  background: #f3662b;
  text-align: center;
  border-radius: 30px;
  line-height: 30px;
  transition: 0.5s transform;
}
@media (prefers-reduced-motion: reduce) {
  .cms-btn::before {
    transition: none;
  }
}
.cms-btn:hover, .cms-btn:active, .cms-btn:focus {
  text-decoration: none;
  color: #ffffff;
  border-color: #f3662b;
  background: #f3662b;
}
.cms-btn:hover::before, .cms-btn:active::before, .cms-btn:focus::before {
  transform: translate(0.5rem, -50%);
}
.cms-btn--back::before {
  content: "";
}

#header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1030;
  background: #ffffff;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
}
#header .header-bar {
  border-top: 5px solid #f3662b;
}
#header .header-search-btn {
  padding: 0.5rem 0.6rem;
  color: #838383;
}
#header .header-mobile-btn {
  display: block;
  color: #838383;
}

#logo a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  min-height: 80px;
}
#logo a img {
  display: block;
  margin: 0 auto;
  max-height: 50px;
}

#mobile-menu {
  z-index: 1120;
}
#mobile-menu:not(.mm) {
  display: none;
}
#mobile-menu a {
  text-decoration: none;
}
#mobile-menu a:hover, #mobile-menu a:active, #mobile-menu a:focus {
  text-decoration: none;
  color: #000000;
}

#cms-menu {
  text-align: center;
  transition: 0.25s all;
}
@media (prefers-reduced-motion: reduce) {
  #cms-menu {
    transition: none;
  }
}
#cms-menu .cms-menu-sub {
  position: relative;
}
#cms-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
#cms-menu ul li {
  display: inline-block;
  position: relative;
  margin: 0 0.5rem;
  line-height: 2;
  padding: 0.5rem 0;
  transition: 0.25s all;
}
@media (prefers-reduced-motion: reduce) {
  #cms-menu ul li {
    transition: none;
  }
}
#cms-menu ul li.active > a {
  color: #000000;
}
#cms-menu ul li:hover > a {
  color: #000000;
}
#cms-menu ul li:hover > .cms-menu-sub {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 0);
  transition: 0.25s all;
}
@media (prefers-reduced-motion: reduce) {
  #cms-menu ul li:hover > .cms-menu-sub {
    transition: none;
  }
}
#cms-menu ul li a {
  color: #838383;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#cms-menu ul li .cms-menu-sub {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1060;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, 0.5rem);
  transition: 0.25s all;
}
@media (prefers-reduced-motion: reduce) {
  #cms-menu ul li .cms-menu-sub {
    transition: none;
  }
}
#cms-menu ul li .cms-menu-sub.sub {
  top: 0;
  left: 150%;
  margin-left: 2px;
}
#cms-menu ul ul {
  display: block;
  min-width: 200px;
  background: #f3662b;
  padding: 1rem 0;
  text-align: left;
}
#cms-menu ul ul li {
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  display: block;
  position: unset;
}
#cms-menu ul ul li:hover > a {
  color: #000000;
  text-decoration: none;
  transform: translateX(0.5rem);
}
#cms-menu ul ul li a {
  display: block;
  position: relative;
  color: #ffffff;
  padding: 0.3rem 1.5rem;
  text-transform: capitalize;
  transition: 0.25s transform;
}
@media (prefers-reduced-motion: reduce) {
  #cms-menu ul ul li a {
    transition: none;
  }
}

#search-modal .modal-dialog {
  margin: 0px;
  width: 100%;
  max-width: unset;
}
#search-modal .modal-content {
  border: 0px;
  border-radius: 0px;
}

#search {
  height: 85px;
}
#search .form-group input[type=text] {
  padding-left: 0;
  border: 0;
  border-radius: 0;
  color: #000000;
  text-transform: unset;
  background: transparent;
}
#search .form-group input[type=text]::placeholder {
  color: #838383;
  font-family: inherit;
  font-size: inherit;
  text-transform: uppercase;
}
#search .form-group button[type=submit],
#search .form-group .search-btn {
  color: #838383;
  border-radius: 0;
}

#content {
  width: 100%;
  min-height: calc(100vh - 198px + 1px);
  padding-bottom: 1px;
  margin-top: 85px;
}

#banner {
  position: relative;
  background: #ffffff;
}
#banner .banner-slide {
  display: flex;
  align-items: center;
  height: calc(50vh + 85px);
  min-height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 991.98px) {
  #banner .banner-slide {
    min-height: 400px;
  }
}
@media (max-width: 575.98px) {
  #banner .banner-slide {
    min-height: 300px;
  }
}
#banner .banner-content {
  padding-right: 3rem;
  max-width: 40%;
}
#banner .banner-content p {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  text-shadow: -1px 1px 5px rgba(0, 0, 0, 0.5);
}
#banner .banner-content h3 {
  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.2;
  text-shadow: -1px 1px 5px rgba(0, 0, 0, 0.5);
}
#banner .banner-content a,
#banner .banner-content .link-btn {
  margin-top: 2rem;
  text-shadow: -1px 1px 5px rgba(0, 0, 0, 0.5);
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.5);
}
@media (max-width: 1199.98px) {
  #banner .banner-content {
    max-width: 60%;
  }
}
@media (max-width: 991.98px) {
  #banner .banner-content {
    max-width: 80%;
  }
}
@media (max-width: 767.98px) {
  #banner .banner-content {
    max-width: 90%;
  }
  #banner .banner-content h3 {
    font-size: 1.5625rem;
  }
}
@media (max-width: 575.98px) {
  #banner .banner-content {
    max-width: 100%;
  }
}
#banner .banner-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
#banner .banner-nav .swiper-button-prev,
#banner .banner-nav .swiper-button-next {
  right: 1rem;
  left: auto;
  background-image: none;
  color: #ffffff;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  border: 1px solid #ffffff;
  text-align: center;
  margin-top: 0px;
  transform: translateY(-50%);
  line-height: 33px;
}
#banner .banner-nav .swiper-button-prev:hover,
#banner .banner-nav .swiper-button-next:hover {
  background: #f3662b;
  border-color: #f3662b;
}
#banner .banner-nav .swiper-button-next {
  top: calc(50% - 25px);
}
#banner .banner-nav .swiper-button-prev {
  top: calc(50% + 25px);
}
@media (max-width: 575.98px) {
  #banner .banner-nav {
    display: none;
  }
}

#inner-banner {
  position: relative;
}
#inner-banner .inner-banner-wrapper {
  display: flex;
  align-items: center;
  height: calc(20vh + 85px);
  min-height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 0px 1000px rgba(0, 0, 0, 0.5);
}
@media (max-width: 991.98px) {
  #inner-banner .inner-banner-wrapper {
    min-height: 200px;
  }
}
#inner-banner .inner-banner-content {
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
}
#inner-banner .inner-banner-content p {
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 0px;
}

#breadcrumb {
  text-align: center;
}
#breadcrumb .breadcrumb {
  margin: 0;
  background: none;
  padding: 1.5rem 0;
  color: #838383;
  font-size: 0.875rem;
  text-transform: capitalize;
  letter-spacing: 1px;
  border-radius: 0;
  align-items: center;
  justify-content: center;
}
#breadcrumb .breadcrumb > li {
  position: relative;
  padding: 0.5rem 0;
  line-height: 1;
}
#breadcrumb .breadcrumb > li + li {
  padding-left: 2.1rem;
}
#breadcrumb .breadcrumb > li + li:before {
  position: absolute;
  left: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  color: inherit;
  font-size: 0.625rem;
  margin: 0;
  padding: 0;
  margin-top: -3px;
}
@media (max-width: 575.98px) {
  #breadcrumb .breadcrumb {
    padding: 1rem 0;
  }
}
#breadcrumb span {
  display: none;
}
#breadcrumb a {
  color: inherit;
  font-family: inherit;
  text-decoration: none;
  text-transform: capitalize;
}
#breadcrumb a:hover {
  color: #000000;
  text-decoration: none;
}

#home-cms {
  background: #ffffff;
  margin-bottom: 250px;
}
#home-cms .home-cms-1 > .container {
  border-right: 1px solid #e2e2e2;
}
#home-cms .home-cms-1-left {
  padding: 4rem 0;
}
#home-cms .home-cms-1-left h5 {
  color: #838383;
  letter-spacing: 1px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
}
@media (max-width: 575.98px) {
  #home-cms .home-cms-1-left {
    padding: 2rem 0;
  }
}
#home-cms .home-cms-1-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
#home-cms .home-cms-1-right h5 {
  display: inline-block;
  color: #838383;
  width: 42%;
  padding-right: 1rem;
  margin-left: auto;
  margin-bottom: 0px;
}
#home-cms .home-cms-1-right a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #ffffff;
  background: #f3662b;
  text-align: center;
  border-radius: 30px;
  margin-right: -2rem;
  text-decoration: none;
}
@media (max-width: 991.98px) {
  #home-cms .home-cms-1-right {
    display: none;
  }
}
#home-cms .home-cms-1-title {
  position: relative;
  color: #838383;
}
#home-cms .home-cms-1-title h1 {
  color: #838383;
  margin-bottom: 0;
}
#home-cms .home-cms-1-title ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-height: 90px;
}
#home-cms .home-cms-1-title ul li {
  color: #f3662b;
  padding: 0.5rem 0;
  line-height: 1;
  position: absolute;
  opacity: 0;
  overflow: hidden;
  animation: topToBottom 12.5s linear infinite 0s;
}
#home-cms .home-cms-1-title ul li:nth-child(2) {
  animation-delay: 2.5s;
}
#home-cms .home-cms-1-title ul li:nth-child(3) {
  animation-delay: 5s;
}
#home-cms .home-cms-1-title ul li:nth-child(4) {
  animation-delay: 7.5s;
}
#home-cms .home-cms-1-title ul li:nth-child(5) {
  animation-delay: 10s;
}
#home-cms .home-cms-1-title ul li:nth-child(n+6) {
  display: none;
}
@media (max-width: 575.98px) {
  #home-cms .home-cms-1-title h1 {
    font-size: 3.125rem;
  }
  #home-cms .home-cms-1-title ul {
    min-height: 60px;
  }
  #home-cms .home-cms-1-title ul li {
    font-size: 3.125rem;
  }
}
@media (max-width: 480px) {
  #home-cms .home-cms-1-title ul {
    width: calc(100vh - 2rem);
  }
}
#home-cms .home-cms-1-content {
  text-align: right;
  text-align-last: right;
  width: 60%;
  margin-top: 1rem;
  margin-left: auto;
}
#home-cms .home-cms-1-content p {
  color: #838383;
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 1rem;
}
@media (max-width: 767.98px) {
  #home-cms .home-cms-1-content {
    width: 80%;
  }
}
@media (max-width: 575.98px) {
  #home-cms .home-cms-1-content {
    width: 100%;
  }
}
#home-cms .home-cms-2, #home-cms .home-cms-4 {
  position: relative;
  min-height: 500px;
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
#home-cms .home-cms-2::before, #home-cms .home-cms-4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 100%);
}
#home-cms .home-cms-2-content, #home-cms .home-cms-4-content {
  color: #ffffff;
  position: relative;
  z-index: 1;
  padding: 2rem 0;
}
#home-cms .home-cms-2-content h2, #home-cms .home-cms-4-content h2 {
  font-size: inherit;
  margin-bottom: 0.5rem;
}
@media (max-width: 1199.98px) {
  #home-cms .home-cms-2, #home-cms .home-cms-4 {
    min-height: 400px;
  }
}
@media (max-width: 767.98px) {
  #home-cms .home-cms-2, #home-cms .home-cms-4 {
    min-height: 300px;
  }
  #home-cms .home-cms-2-content, #home-cms .home-cms-4-content {
    font-size: 2.1875rem;
  }
}
@media (max-width: 575.98px) {
  #home-cms .home-cms-2-content, #home-cms .home-cms-4-content {
    font-size: 1.875rem;
  }
}
#home-cms .home-cms-3, #home-cms .home-cms-5 {
  overflow: hidden;
}
#home-cms .home-cms-3-main, #home-cms .home-cms-5-main {
  min-height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  #home-cms .home-cms-3-main, #home-cms .home-cms-5-main {
    min-height: 300px;
  }
}
@media (max-width: 575.98px) {
  #home-cms .home-cms-3-main, #home-cms .home-cms-5-main {
    min-height: 250px;
  }
}
#home-cms .home-cms-3-content, #home-cms .home-cms-5-content {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#home-cms .home-cms-3-content-item, #home-cms .home-cms-5-content-item {
  position: relative;
  z-index: 3;
  width: 50%;
  height: 50%;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
#home-cms .home-cms-3-content-item:nth-child(2), #home-cms .home-cms-3-content-item:nth-child(3), #home-cms .home-cms-5-content-item:nth-child(2), #home-cms .home-cms-5-content-item:nth-child(3) {
  z-index: 1;
}
#home-cms .home-cms-3-content-item:nth-child(2)::before, #home-cms .home-cms-3-content-item:nth-child(3)::before, #home-cms .home-cms-5-content-item:nth-child(2)::before, #home-cms .home-cms-5-content-item:nth-child(3)::before {
  content: "";
  position: absolute;
  z-index: 2;
  height: 50px;
  width: 50px;
  background: #ffffff;
  border-radius: 100%;
}
#home-cms .home-cms-3-content-item:nth-child(2)::after, #home-cms .home-cms-3-content-item:nth-child(3)::after, #home-cms .home-cms-5-content-item:nth-child(2)::after, #home-cms .home-cms-5-content-item:nth-child(3)::after {
  content: "";
  position: absolute;
  z-index: 2;
  height: 100px;
  width: 100px;
  overflow: hidden;
  background: transparent;
  border-radius: 100%;
  box-shadow: 0px -5px 0px 0px transparent;
  transition: transform 1s, box-shadow 1s ease;
}
#home-cms .home-cms-3-content-item:nth-child(2)::before, #home-cms .home-cms-5-content-item:nth-child(2)::before {
  left: -25px;
}
#home-cms .home-cms-3-content-item:nth-child(2)::after, #home-cms .home-cms-5-content-item:nth-child(2)::after {
  left: -50px;
  transform: rotate(-90deg);
}
#home-cms .home-cms-3-content-item:nth-child(3)::before, #home-cms .home-cms-5-content-item:nth-child(3)::before {
  right: -25px;
}
#home-cms .home-cms-3-content-item:nth-child(3)::after, #home-cms .home-cms-5-content-item:nth-child(3)::after {
  right: -50px;
  transform: rotate(90deg);
}
#home-cms .home-cms-3-content-item:hover::after, #home-cms .home-cms-5-content-item:hover::after {
  transform: scale(1) rotate(0deg);
  box-shadow: 0px 0px 0px 3px #ffffff;
}
#home-cms .home-cms-3-content-item h4, #home-cms .home-cms-5-content-item h4 {
  display: block;
  color: #000000;
  width: 100%;
  margin-bottom: 1rem;
}
#home-cms .home-cms-3-content-item img, #home-cms .home-cms-5-content-item img {
  margin: 0 auto;
  height: auto;
  max-width: 100%;
}
@media (max-width: 991.98px) {
  #home-cms .home-cms-3-content-item, #home-cms .home-cms-5-content-item {
    padding: 1.5rem;
  }
  #home-cms .home-cms-3-content-item h4, #home-cms .home-cms-5-content-item h4 {
    font-size: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  #home-cms .home-cms-3-content-item, #home-cms .home-cms-5-content-item {
    min-height: 300px;
  }
}
@media (max-width: 575.98px) {
  #home-cms .home-cms-3-content-item, #home-cms .home-cms-5-content-item {
    min-height: 250px;
  }
}
#home-cms .home-cms-6 {
  padding-top: 5rem;
}
#home-cms .home-cms-6-content {
  padding-bottom: 4rem;
}
#home-cms .home-cms-6-content h1 {
  color: #f3662b;
  width: 100%;
  margin-bottom: 1rem;
}
#home-cms .home-cms-6-content p {
  color: #838383;
  margin-bottom: 1rem;
}
#home-cms .home-cms-6-content a,
#home-cms .home-cms-6-content .cms-btn {
  display: block;
  margin-top: 1rem;
}
#home-cms .home-cms-6-map {
  display: block;
  width: 100%;
  height: 250px;
  margin-bottom: -250px;
}
@media (max-width: 991.98px) {
  #home-cms .home-cms-6 {
    padding-top: 3rem;
  }
  #home-cms .home-cms-6-content {
    padding-bottom: 2rem;
  }
}
@media (max-width: 575.98px) {
  #home-cms .home-cms-6 {
    padding-top: 2rem;
  }
  #home-cms .home-cms-6-content h1 {
    font-size: 3.75rem;
  }
}
#home-cms .home-cms-7 {
  position: relative;
  min-height: 500px;
  background-position: top right;
  display: flex;
  align-items: center;
}
#home-cms .home-cms-7-content {
  color: #ffffff;
  position: relative;
  z-index: 1;
  padding: 2rem 0;
}
#home-cms .home-cms-7-content h2 {
  font-size: inherit;
  margin-bottom: 0.5rem;
}
@media (max-width: 1199.98px) {
  #home-cms .home-cms-7 {
    min-height: 400px;
  }
}
@media (max-width: 767.98px) {
  #home-cms .home-cms-7 {
    min-height: 300px;
  }
  #home-cms .home-cms-7-content {
    font-size: 2.1875rem;
  }
}
@media (max-width: 575.98px) {
  #home-cms .home-cms-7::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 100%);
  }
  #home-cms .home-cms-7-content {
    font-size: 1.875rem;
  }
}

#home-enquiry {
  position: relative;
  z-index: 1;
  background: #ffffff;
  padding: 2rem;
  border-radius: 5px;
  margin-bottom: -250px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
#home-enquiry h4 {
  color: #000000;
  margin-bottom: 2rem;
}
#home-enquiry h4 i,
#home-enquiry h4 svg,
#home-enquiry h4 span {
  display: inline-block;
  vertical-align: bottom;
}
@media (max-width: 991.98px) {
  #home-enquiry {
    margin-bottom: -3rem;
  }
}
@media (max-width: 575.98px) {
  #home-enquiry {
    padding: 1rem;
  }
}
@media (max-width: 375px) {
  #home-enquiry .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}

#map {
  position: fixed;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 363px;
  overflow: hidden;
  background: #f2f2f2;
}
#map .map-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 1;
}
#map .map-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -60vw;
}
#map iframe {
  width: 200vw;
  height: 100%;
}
@media (max-width: 991.98px) {
  #map .map-wrapper {
    left: -50vw;
  }
}

#inner-page {
  padding-bottom: 4rem;
}

.pagination {
  margin-top: 2rem;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination:empty {
  display: none;
}
.pagination .page-item .page-link {
  color: #838383;
  background: #ffffff;
  border-color: #838383;
}
.pagination .page-item .page-link:hover {
  color: #ffffff;
  background: #f3662b;
  border-color: #f3662b;
}
.pagination .page-item.active .page-link {
  color: #ffffff;
  background: #000000;
  border-color: #000000;
}

.news-list .news-masonry .grid-sizer,
.news-list .news-masonry .grid-item {
  width: calc((100% / 3) - 2rem);
}
.news-list .news-masonry .gutter-sizer {
  width: 2rem;
}
.news-list .news-masonry .grid-item {
  margin-bottom: 2rem;
}
.news-list .news-masonry a {
  text-decoration: none;
  display: block;
  background: #f6f6f6;
  transition: 0.5s background;
}
@media (prefers-reduced-motion: reduce) {
  .news-list .news-masonry a {
    transition: none;
  }
}
.news-list .news-masonry a .img {
  position: relative;
}
.news-list .news-masonry a .img img {
  display: block;
  margin: 0 auto;
}
.news-list .news-masonry a:hover {
  background: #e2e2e2;
}
.news-list .news-masonry a:hover .news-content {
  color: #000000;
}
.news-list .news-masonry .news-content {
  padding: 1.5rem;
  color: #838383;
}
.news-list .news-masonry .news-content .news-title {
  color: #f3662b;
  text-transform: uppercase;
}
.news-list .news-masonry .news-content .news-date {
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: inherit;
  text-transform: uppercase;
}
.news-list .news-masonry .news-content .news-desc {
  color: inherit;
  border-top: 1px solid #000000;
  padding-top: 1rem;
  padding-bottom: 0;
  margin-top: 1rem;
}
@media (max-width: 991.98px) {
  .news-list .news-masonry .grid-sizer,
.news-list .news-masonry .grid-item {
    width: calc((100% / 2) - 2rem);
  }
}
@media (max-width: 767.98px) {
  .news-list .news-masonry .grid-sizer,
.news-list .news-masonry .grid-item {
    width: calc((100% / 2) - 1rem);
  }
  .news-list .news-masonry .gutter-sizer {
    width: 1rem;
  }
  .news-list .news-masonry .grid-item {
    margin-bottom: 1rem;
  }
}

.news-view .news-banner {
  margin-bottom: 2rem;
}
.news-view .news-banner img {
  display: block;
  margin: 0 auto;
}
.news-view .news-date {
  color: #000000;
  text-transform: uppercase;
}
.news-view .news-desc {
  color: #838383;
  line-height: 1.5;
  margin-top: 2rem;
}
.news-view .news-desc a {
  color: #000000;
  text-decoration: none;
}
.news-view .news-desc a:hover {
  color: #f3662b;
  text-decoration: underline;
}

.event-list .event-masonry .grid-sizer,
.event-list .event-masonry .grid-item {
  width: calc((100% / 3) - 2rem);
}
.event-list .event-masonry .gutter-sizer {
  width: 2rem;
}
.event-list .event-masonry .grid-item {
  margin-bottom: 2rem;
}
.event-list .event-masonry a {
  text-decoration: none;
  display: block;
  background: #f6f6f6;
  transition: 0.5s background;
}
@media (prefers-reduced-motion: reduce) {
  .event-list .event-masonry a {
    transition: none;
  }
}
.event-list .event-masonry a .img {
  position: relative;
}
.event-list .event-masonry a .img img {
  display: block;
  margin: 0 auto;
}
.event-list .event-masonry a:hover {
  background: #e2e2e2;
}
.event-list .event-masonry a:hover .event-content {
  color: #000000;
}
.event-list .event-masonry .event-content {
  padding: 1.5rem;
  color: #838383;
}
.event-list .event-masonry .event-content .event-title {
  color: #f3662b;
  text-transform: uppercase;
}
.event-list .event-masonry .event-content .event-date {
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: inherit;
  text-transform: uppercase;
}
.event-list .event-masonry .event-content .event-desc {
  color: inherit;
  border-top: 1px solid #000000;
  padding-top: 1rem;
  padding-bottom: 0;
  margin-top: 1rem;
}
@media (max-width: 991.98px) {
  .event-list .event-masonry .grid-sizer,
.event-list .event-masonry .grid-item {
    width: calc((100% / 2) - 2rem);
  }
}
@media (max-width: 767.98px) {
  .event-list .event-masonry .grid-sizer,
.event-list .event-masonry .grid-item {
    width: calc((100% / 2) - 1rem);
  }
  .event-list .event-masonry .gutter-sizer {
    width: 1rem;
  }
  .event-list .event-masonry .grid-item {
    margin-bottom: 1rem;
  }
}

.event-view .event-banner {
  margin-bottom: 2rem;
}
.event-view .event-banner img {
  display: block;
  margin: 0 auto;
}
.event-view .event-date {
  color: #000000;
  text-transform: uppercase;
}
.event-view .event-desc {
  color: #838383;
  line-height: 1.5;
  margin-top: 2rem;
}
.event-view .event-desc a {
  color: #000000;
  text-decoration: none;
}
.event-view .event-desc a:hover {
  color: #f3662b;
  text-decoration: underline;
}

.photo-list .photo-masonry .grid-sizer,
.photo-list .photo-masonry .grid-item {
  width: calc((100% / 3) - 2rem);
}
.photo-list .photo-masonry .gutter-sizer {
  width: 2rem;
}
.photo-list .photo-masonry .grid-item {
  margin-bottom: 2rem;
}
.photo-list .photo-masonry a {
  text-decoration: none;
  display: block;
  background: #f6f6f6;
  transition: 0.5s background;
}
@media (prefers-reduced-motion: reduce) {
  .photo-list .photo-masonry a {
    transition: none;
  }
}
.photo-list .photo-masonry a .img {
  position: relative;
}
.photo-list .photo-masonry a .img img {
  display: block;
  margin: 0 auto;
}
.photo-list .photo-masonry a:hover {
  background: #e2e2e2;
}
.photo-list .photo-masonry a:hover .photo-content {
  color: #000000;
}
.photo-list .photo-masonry .photo-content {
  padding: 1.5rem;
  color: #838383;
}
.photo-list .photo-masonry .photo-content .photo-title {
  color: #f3662b;
  text-transform: uppercase;
}
.photo-list .photo-masonry .photo-content .photo-date {
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: inherit;
  text-transform: uppercase;
}
.photo-list .photo-masonry .photo-content .photo-desc {
  color: inherit;
  border-top: 1px solid #000000;
  padding-top: 1rem;
  padding-bottom: 0;
  margin-top: 1rem;
}
@media (max-width: 991.98px) {
  .photo-list .photo-masonry .grid-sizer,
.photo-list .photo-masonry .grid-item {
    width: calc((100% / 2) - 2rem);
  }
}
@media (max-width: 767.98px) {
  .photo-list .photo-masonry .grid-sizer,
.photo-list .photo-masonry .grid-item {
    width: calc((100% / 2) - 1rem);
  }
  .photo-list .photo-masonry .gutter-sizer {
    width: 1rem;
  }
  .photo-list .photo-masonry .grid-item {
    margin-bottom: 1rem;
  }
}

.photo-view {
  color: #838383;
  line-height: 1.5;
  margin-top: 2rem;
}
.photo-view ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.photo-view ul li {
  margin-bottom: 2rem;
}
.photo-view ul li .photo-view-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  color: #838383;
  border: 1px solid #838383;
}
.photo-view ul li .photo-view-box img {
  padding: 0.2rem;
}
.photo-view ul li .photo-view-box-overlay {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.5s ease;
}
@media (prefers-reduced-motion: reduce) {
  .photo-view ul li .photo-view-box-overlay {
    transition: none;
  }
}
.photo-view ul li .photo-view-box-overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background: #f3662b;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
}
.photo-view ul li .photo-view-box:hover {
  border-color: #000000;
}
.photo-view ul li .photo-view-box:hover .photo-view-box-overlay {
  opacity: 1;
}

.video-list .video-masonry .grid-sizer,
.video-list .video-masonry .grid-item {
  width: calc((100% / 3) - 2rem);
}
.video-list .video-masonry .gutter-sizer {
  width: 2rem;
}
.video-list .video-masonry .grid-item {
  margin-bottom: 2rem;
}
.video-list .video-masonry a {
  text-decoration: none;
  display: block;
  background: #f6f6f6;
  transition: 0.5s background;
}
@media (prefers-reduced-motion: reduce) {
  .video-list .video-masonry a {
    transition: none;
  }
}
.video-list .video-masonry a .img {
  position: relative;
}
.video-list .video-masonry a .img img {
  display: block;
  margin: 0 auto;
}
.video-list .video-masonry a:hover {
  background: #e2e2e2;
}
.video-list .video-masonry a:hover .video-content {
  color: #000000;
}
.video-list .video-masonry .video-content {
  padding: 1.5rem;
  color: #838383;
}
.video-list .video-masonry .video-content .video-title {
  color: #f3662b;
  text-transform: uppercase;
}
.video-list .video-masonry .video-content .video-date {
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: inherit;
  text-transform: uppercase;
}
.video-list .video-masonry .video-content .video-desc {
  color: inherit;
  border-top: 1px solid #000000;
  padding-top: 1rem;
  padding-bottom: 0;
  margin-top: 1rem;
}
@media (max-width: 991.98px) {
  .video-list .video-masonry .grid-sizer,
.video-list .video-masonry .grid-item {
    width: calc((100% / 2) - 2rem);
  }
}
@media (max-width: 767.98px) {
  .video-list .video-masonry .grid-sizer,
.video-list .video-masonry .grid-item {
    width: calc((100% / 2) - 1rem);
  }
  .video-list .video-masonry .gutter-sizer {
    width: 1rem;
  }
  .video-list .video-masonry .grid-item {
    margin-bottom: 1rem;
  }
}

.video-view {
  color: #838383;
  line-height: 1.5;
  margin-top: 2rem;
}
.video-view ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.video-view ul li {
  margin-bottom: 2rem;
}
.video-view ul li .video-view-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  color: #838383;
  border: 1px solid #838383;
}
.video-view ul li .video-view-box img {
  padding: 0.2rem;
}
.video-view ul li .video-view-box-overlay {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.5s ease;
}
@media (prefers-reduced-motion: reduce) {
  .video-view ul li .video-view-box-overlay {
    transition: none;
  }
}
.video-view ul li .video-view-box-overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background: #f3662b;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
}
.video-view ul li .video-view-box:hover {
  border-color: #000000;
}
.video-view ul li .video-view-box:hover .video-view-box-overlay {
  opacity: 1;
}

#contactform,
#newsletterform {
  margin-top: 2rem;
}
#contactform i,
#contactform svg,
#newsletterform i,
#newsletterform svg {
  display: block;
  position: absolute;
  left: 1.5rem;
  top: 0.75rem;
  color: #f3662b;
}
#contactform .form-control,
#newsletterform .form-control {
  padding: 0.5rem;
  height: auto;
  padding-left: 3rem;
  border-radius: 6px;
  border-color: #e2e2e2;
  color: #838383;
}
#contactform .form-control::placeholder,
#newsletterform .form-control::placeholder {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
#contactform .custom-control-input:checked ~ .custom-control-label::before,
#newsletterform .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #f3662b;
  background-color: #f3662b;
}
@media (max-width: 575.98px) {
  #contactform .g-recaptcha > div,
#newsletterform .g-recaptcha > div {
    margin: 0 auto;
  }
}

#g-recaptcha-response > div {
  margin: 0 auto;
}

#search-page {
  color: #838383;
  line-height: 1.5;
}
#search-page h3 {
  color: #f3662b;
  text-transform: uppercase;
}
#search-page h4 {
  color: #000000;
}
#search-page h4 a {
  display: block;
  text-decoration: none;
  color: inherit;
}
#search-page h4 a:hover {
  color: #f3662b;
}
#search-page h5 {
  color: #000000;
  text-transform: uppercase;
}
#search-page .cms-btn {
  display: block;
  margin-left: auto;
}

#footer {
  margin-top: -1px;
  min-height: 113px;
  border-top: 5px solid #f3662b;
  background: #000000;
  position: relative;
  z-index: 1;
}
#footer .footer-wrapper {
  margin: 0 auto;
  padding: 0.5rem 0;
  max-width: 80%;
  color: #ffffff;
  font-size: 0.625rem;
  letter-spacing: 1px;
  text-align: center;
}
#footer .footer-wrapper a {
  color: inherit;
}
@media (max-width: 575.98px) {
  #footer .footer-wrapper {
    max-width: 100%;
  }
}

#sitemap {
  overflow: hidden;
}
#sitemap .big-title {
  margin-top: 2rem;
}
#sitemap ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#sitemap ul li {
  display: inline-block;
  vertical-align: top;
  margin: 0.4rem;
}
#sitemap ul li a {
  display: block;
  color: #ffffff;
  background: #f3662b;
  text-transform: uppercase;
  padding: 0.8rem 2.5rem;
  text-align: center;
  border: 1px solid #f3662b;
  text-decoration: none;
  transition: 0.5s background;
}
@media (prefers-reduced-motion: reduce) {
  #sitemap ul li a {
    transition: none;
  }
}
#sitemap ul li a:hover {
  text-decoration: none;
  color: #f3662b;
  background: transparent;
}
#sitemap ul ul {
  text-align: left;
  display: block;
}
#sitemap ul ul li {
  display: block;
  margin-left: 0;
  margin-right: 0;
}
#sitemap ul ul li a {
  padding: 0.3rem 0.5rem;
  display: inline-block;
  background: transparent;
  border-color: #666867;
  color: #838383;
}
#sitemap ul ul ul {
  margin-left: 1rem;
}